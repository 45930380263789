<template>
    <table class="ridestyler-showcase-table">
        <thead class="ridestyler-showcase-table-head">
            <tr>
                <th v-for="(name, index) in fieldNames" :key="index">{{name}}</th>
            </tr>
        </thead>
        <tbody class="ridestyler-showcase-table-body">
            <tr v-for="(row, i) in rows" :key="i" :class="{ highlight: (row._rowSettings && row._rowSettings.highlight), disabled: (row._rowSettings && row._rowSettings.disabled) }">
                <td v-for="(field, x) in fieldKeys" :key="x">{{row[field]}}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>

import { praseCamelCase } from '../Parsers';

export default {
    props: {
        fields: {
            type: Array,
            required: false
        },
        rows: {
            type: Array,
            required: true
        }
    },
    computed: {
        fieldKeys: function() {
            if (this.fields) return this.fields;
            if (!this.rows || this.rows.length == 0) return [];

            var fieldKeys = [];
            for(var f in this.rows[0]) {
                if (f[0] === '_') continue;
                fieldKeys.push(f);
            }

            return fieldKeys;
        },
        fieldNames: function() {
            return this.fieldKeys.map(praseCamelCase);
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-table {
        width: 100%;
        border-radius: 0.6em;
        overflow: hidden;

        tr {
            th {
                padding: 1em; 
                background-color: $secondary-button-color;
                color: white;
            }

            td, th {
                padding: 1.2em 0.5em;
                text-align: center;
                border: none;
            }

            &:nth-child(even) {
                @include var(background-color, secondary-background-color);
            }

            &.highlight {
                td, th {
                    font-weight: bold;
                }
            }

            &.disabled {
                td, th {
                    color: #6b6b6b;
                    text-decoration: line-through;
                }
            }
        }
    }
}
</style>
