import Vue from 'vue';

/** @typedef {import("../state").RideStylerShowcaseState} RideStylerShowcaseState */

/**
 * @typedef OrderDetailsState
 * @prop {boolean} isVisible
 * @prop {object} data
 * @prop {function} whenFinished
 */

/** @type {OrderDetailsState} */
const state = {
    isVisible: false,
    modalTitle: undefined,
    modalButton: undefined,
    data: {},
    whenFinished: undefined
};

/**
 * @type {import("vuex").MutationTree<any>}
 */
const mutations = {
    SET_MODAL(state, payload){
        state.isVisible = payload.isVisible;
        state.data = payload.data;
        state.whenFinished = payload.whenFinished;
        state.modalTitle = payload.modalTitle;
        state.modalButton = payload.modalButton;
    },
    UPDATE_QUANTITY(state, payload){ state.data[payload.key].Quantity = payload.Quantity },
    DELETE_PRODUCT(state, payload){ Vue.delete(state.data, payload); },
};

const actions = {
    deleteProduct(context, payload){ context.commit("DELETE_PRODUCT", payload); },
    updateQuantity(context, payload){ context.commit("UPDATE_QUANTITY", payload); },
    showModal(context, payload) {
        return new Promise((resolve) => {
            context.commit('SET_MODAL', {
                isVisible: true,
                data: payload.data,
                whenFinished: resolve,
                modalButton: payload.modalButton,
                modalTitle: payload.modalTitle
            });
        });
    },
    hideModal(context){ 
        context.commit('SET_MODAL', {
            isVisible: false,
            data: undefined
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
