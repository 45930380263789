<template>
    <div class="ridestyler-showcase-product-details">
        <div class="ridestyler-showcase-product-details-header">
            <h1 id="ridestyler-showcase-product-details-title">
                <div class="secondary-title">{{tireDetails.TireBrandName}}</div>
                {{tireDetails.TireModelName}}
                <div class="third-title">{{typeSummary}}</div>
                <div class="third-title">{{tireSize}}</div>
            </h1>

            <ToggleButtonList v-bind:selectedIndex.sync="selectedTab" class="ridestyler-showcase-product-details-toggle-buttons" :options="[ 'Summary', 'Specifications' ]" />
        </div>
        <div class="ridestyler-showcase-product-details-body">
            <div class="ridestyler-showcase-product-details-tab" v-if="selectedTab === 0">
                <ProductImage :product="tireDetails"  />
                <div class="ridestyler-showcase-product-details-summary-table-container ridestyler-showcase-scrollable-y">
                    <p class="bold">{{description}}</p>
                    <ul>
                        <li v-for="(f, i) in features" :key="i">{{f}}</li>
                    </ul>
                </div>
            </div>
            <div class="ridestyler-showcase-product-details-tab" v-if="selectedTab === 1">
                <div class="ridestyler-showcase-product-details-full-table-container ridestyler-showcase-scrollable-y">
                    <TableView :rows="specificationRows"></TableView>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ToggleButtonList from './ToggleButtonList';
import TableView from './TableView';
import ProductImage from './ProductImage';
import settings from '../Settings';
import { formatPrice } from '../Formatters';

export default {
    props: [ "tireDetails" ],
    beforeMount: function() {
        var self = this;

        var modelFilters = {
            TireModel: this.tireDetails.TireModelID,
            IncludePricing: settings.showPricing
            
        }

        var combinedFilters =
            Object.assign({},
                this.tireFilters,
                modelFilters);

        var b = ridestyler.ajax.batch();
        b.done(function(r) {
            var lookup = {};

            var partialFitmentList = r['fitments-partial'].Fitments;
            var fullFitmentList = r['fitments-full'].Fitments;

            partialFitmentList.map(function(f) { lookup[f.TireFitmentID] = true; });

            fullFitmentList.map(function(f) {
                var isFitmentAvailable = (lookup[f.TireFitmentID] === true);

                f._rowSettings = {
                    disabled: !isFitmentAvailable
                };
            });

            fullFitmentList.sort(function(x, y) {
                if (!x._rowSettings.disabled && y._rowSettings.disabled) return -1;
                else if (x._rowSettings.disabled && !y._rowSettings.disabled) return 1;

                if (x.TireSize.Description < y.TireSize.Description) return -1;
                else if (x.TireSize.Description > y.TireSize.Description) return 1;

                if (x.TireSize.ServiceDescription < y.TireSize.ServiceDescription) return -1;
                else if (x.TireSize.ServiceDescription > y.TireSize.ServiceDescription) return 1;

                return 0;
            });

            self.tireFitmentDetails = fullFitmentList;
        });

        b.send('fitments-full', {
            action: 'Tire/GetFitmentDescriptions',
            data: modelFilters
        });

        b.send('fitments-partial', {
            action: 'Tire/GetFitments',
            data: combinedFilters
        });

        b.execute();
    },
    data: function () {
        return {
            selectedTab: 0,
            tireFitmentDetails: []
        };
    },
    components: {
        ToggleButtonList,
        TableView,
        ProductImage
    },
    computed: {
        typeSummary() {
            return this.tireDetails && this.tireDetails.Attributes && this.tireDetails.Attributes.join(', ') || '';
        },
        tireSize() {
            for(let i=0; i<this.tireFitmentDetails.length; i++){
                let f = this.tireFitmentDetails[i];
                if( !f._rowSettings.disabled ){
                    return f.TireSize.Description;
                }
            }
            return "";
        },
        description() {
            return this.tireDetails && this.tireDetails.TireModelDescription || '';
        },
        features() {
            const highlights = this.tireDetails && this.tireDetails.TireModelHighlights || '';

            return highlights ? highlights.split('|') : [];
        },
        specificationRows() {
            let _this = this;
            return this.tireFitmentDetails.map(function (f) {

                var sidewall = f.TireSidewall.TireSidewallAbbr;
                if (f.TireSidewallAlt !== null) {
                    sidewall += ' (' + f.TireSidewallAlt.TireSidewallAbbr + ')';
                }
                let rtn = {
                    '_rowSettings': f._rowSettings,
                    'Size': f.TireSize.Description,
                    'Service Desc.': f.TireSize.ServiceDescription,
                    'Sidewall': sidewall,
                    'Part #': f.TireFitmentPartNumber,
                    'Diameter': f.TireSize.OutsideDiameter + '"',
                };

                if( settings.showPricing ) {
                    rtn[_this.translate('priceEach')] = f.Price != null && settings.showPricing ? formatPrice(f.Price) : _this.translate("tirePriceNull");
                }

                if( settings.showInventory) {
                    if( !f.QuantityAvailable && f.QuantityAvailable != 0)
                        rtn["Stock"] = _this.translate("tireStockNullShort");
                    else if( f.QuantityAvailable < settings.noTireStockWarning)
                        rtn["Stock"] = _this.translate("tireStockOut");
                    else if( f.QuantityAvailable < settings.lowTireStockWarning)
                        rtn["Stock"] = _this.translate("tireStockLow");
                    else
                        rtn["Stock"] = _this.translate("tireStockIn");
                }
                if( settings.showTireInventoryValues && settings.showInventory)
                {
                    if( f.QuantityAvailable || f.QuantityAvailable == 0)
                        rtn["Stock"] = f.QuantityAvailable;
                    else
                         rtn["Stock"] = _this.translate("tireStockNullShort");
                }

                return rtn;
            });
        },
        ...mapGetters([
            'tireFilters'
        ])
    }
}
</script>

<style lang="scss">

</style>
