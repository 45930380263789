<template>
    <div class="ridestyler-showcase-button-list" :if="options.length > 0">
        <button type="button" class="ridestyler-showcase-button" :aria-pressed="selectedIndex == index" v-for="(label, index) in options" :key="index" @click="buttonSelected(index)">{{label}}</button>
    </div>
</template>

<script>

export default {
    props: {
        selectedIndex: {
            type: Number,
            default: 0
        },
        options: {
            type: Array,
            required: true
        }
    },
    methods: {
        buttonSelected: function(index) {
            this.$emit('update:selectedIndex', index);
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-button-list {
        .ridestyler-showcase-button {
            background-color: $secondary-background-color;
            border-color: $secondary-background-color;
            color: $secondary-text-color;

            border-radius: 0;

            padding: 1em 1em;
            width: 50%;


            &[aria-pressed="true"] {
                @include var(background-color, primary-color);
                @include var(border-color, primary-color);
                @include var(color, primary-color-overlaid-text);
            }
        }
    }
}
</style>
