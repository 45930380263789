function EventHandler() {
    this.handlers = {};
}

EventHandler.prototype.register = function(name, action){
    if(this.handlers[name]){
        this.handlers[name].push(action);
    } else {
        this.handlers[name] = [];
        this.handlers[name].push(action);
    }
}

EventHandler.prototype.deregister = function(name, action){
    if(this.handlers[name].length === undefined) return;

    this.handlers = this.handlers.filter((actions) => actions !== action)
}

EventHandler.prototype.fire = function(name, action){
    if(this.handlers[name] === undefined || this.handlers[name].length > 0) return;

    const fireFn = this.handlers.find((actions) => actions === action)

    fireFn();
}

/**
 * @param {Vue.VueConstructor} Vue
 * @returns {Events}
 */
export default function installEventHandler (Vue) {
    return Vue.prototype.$events = new EventHandler();
}
