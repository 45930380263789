import { formatWheelSize, formatOffset } from "./Formatters";

export function getProductSpecifications(product) {
    if (product.type === 'wheel') {

        return [
            { label: 'Size', value: formatWheelSize([product.DiameterMin, product.DiameterMax], [product.WidthMin, product.WidthMax]) },
            { label: 'Offset', value: formatOffset(product.OffsetMin, product.offsetMax) },
            { label: 'Bolt Pattern', value: product.BoltPatternDescription },
            { label: 'Centerbore', value: product.CenterboreMM },
            { label: 'Construction', value: '' },
            { label: 'Part Number', value: product.PartNumber },
        ];
    } else if (product.type === 'tire') {
        let sidewall = product.TireSidewall.TireSidewallAbbr;
        
        if (product.TireSidewallAlt !== null) {
            sidewall += ' (' + product.TireSidewallAlt.TireSidewallAbbr + ')';
        }

        return [
            { label: 'Size', value: product.TireSize.Description },
            { label: 'Service Desc.', value: product.TireSize.ServiceDescription },
            { label: 'Sidewall', value: sidewall },
            { label: 'Part #', value: product.TireFitmentPartNumber },
            { label: 'Diameter', value: product.TireSize.OutsideDiameter + '"' }
        ];
    }

    return [];
}

/**
 * @typedef WheelFitmentMatchesFilters
 * @prop {number} [diameter]
 */

export const Wheel = {
    /**
     * @param {ridestyler.Descriptions.WheelFitmentDescriptionModel} fitment
     * @param {WheelFitmentMatchesFilters} filters
     */
    fitmentMatches(fitment, filters) {
        const diameterMatches = !filters.diameter || (fitment.DiameterMin <= filters.diameter && fitment.DiameterMax >= filters.diameter);

        return {
            diameter: diameterMatches
        };
    },

    /**
     * @param {ridestyler.Descriptions.WheelFitmentDescriptionModel[]} fitments 
     */
    getUniqueDiameters(fitments) {
        /** @type {Set<number>} */
        const diameters = new Set();

        if (!fitments || fitments.length === 0) return [];

        for (const fitment of fitments) {
            const {DiameterMin: minDiameter, DiameterMax: maxDiameter} = fitment;

            for (let diameter = minDiameter; diameter <= maxDiameter; diameter++)
                diameters.add(diameter);
        }

        const sortedDiameters = Array.from(diameters);

        sortedDiameters.sort();

        return sortedDiameters;
    }
};