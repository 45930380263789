<template>

    <div class="ridestyler-showcase-product-gallery"
        role="listbox"
        aria-label="Wheels"
        v-on:mousedown="cancelEvent"
        v-on:touchstart="cancelEvent"

        v-bind:class="productGalleryClass"
        v-on:focus="onFocused"
        v-on:keydown="onKeyDown">
        
        <div class="ridestyler-showcase-product-gallery-no-results" v-if="!isLoading && products.length === 0">
            <slot name="no-results">
                No Results
            </slot>
        </div>

        <div class="ridestyler-showcase-product-gallery-wrapper" 
        v-on-wheel="onWheelScroll" v-on:scroll="onScroll" ref="wrapper" :class="{ 'fade-before' : !atScrollStart, 'fade-after' : !atScrollEnd || isLoading }">

            <ProductGalleryProduct

                v-bind:product="product"
                v-for="product in products"
                v-bind:selected="product === selectedProduct"
                v-bind:key="product.key"
                v-bind:data-key="product.key"
                v-on:click.native="selectProduct(product)"
                v-on:touchend.native="function(e) { selectProduct(product, e); }"
                v-on:focus.native="productFocused = true"
                v-on:blur.native="productFocused = false" />

        </div>

        <LoadingIndicator v-show="isLoading" />

        <button type="button" class="ridestyler-showcase-product-gallery-prev" 
            v-bind:disabled="atScrollStart && !isLoading" 
            v-on:click="previousPage" 
            v-on:touchstart="previousPage" 
            v-show="hasIndicators || loadingFirstResults" 
            :tabindex="-1">
            <SvgIcon focusable="false" name="caret-left" />
            <span class="sr">Previous Page</span>
        </button>
        <button type="button" class="ridestyler-showcase-product-gallery-next" 
            v-bind:disabled="atScrollEnd && !isLoading" 
            v-on:click="nextPage" 
            v-on:touchstart="nextPage" 
            v-show="hasIndicators || loadingFirstResults" 
            aria-label="Load More"
            :tabindex="(atScrollEnd && !isLoading)?'-1':'0'">
            <SvgIcon focusable="false" name="caret-right" />
            <span class="sr">Next Page</span>
        </button>
    </div>
</template>

<script>
import Impetus from 'impetus'
import TinyAnimate from 'TinyAnimate';
import SvgIcon from './SvgIcon'
import wheelDirective from '../directives/wheel.js'
import ProductGalleryProduct from './ProductGalleryProduct';
import LoadingIndicator from './LoadingIndicator'
import RideStylerAPIRequest from '../RideStylerAPIRequest';
import { mapState } from 'vuex';
import settings from '../Settings';

export default {
    async created() {
        this.resultsPerLoad = this.productType == 'paint' ? 50 : 11;
        this.touchStartData = null;

        await this.loadMoreResults();

        // Select the first wheel when available
        if(this.productType == 'wheel' && !this.selectedWheelModel) this.selectProduct(this.products[0]);
    },
    data () {
        return {
            products: [],
            selectedProduct: undefined,
            atScrollStart: true,
            atScrollEnd: false,
            isLoading: false,
            hasMoreResults: true,
            productFocused: false
        };
    },
    methods: {
        cancelEvent(e) {
            if (e.type === 'touchstart') {
                this.touchStartData = e.touches[0];
            }

            e.preventDefault();
        },
        extendProduct(product){
            
            if( settings.options.extraDetails && Array.isArray(settings.options.extraDetails) )
            {
                if( this.productType == "wheel" ){
                    let minP = Infinity;
                    let maxP = -Infinity;

                    let minQ = Infinity;
                    let maxQ = -Infinity;
                    
                    if(product.WheelFitments){
                        product.WheelFitments.forEach(fitment=>{
                            let extra;
                            
                            if(!settings.options.partNumbersAreInventoryNumbers)
                                extra = settings.options.extraDetails.find(detail => detail.partNumber == fitment.PartNumber);
                            else
                                extra = settings.options.extraDetails.find(detail => detail.partNumber == fitment.ItemNumber);

                            if( extra && extra.price ) {
                                fitment.price = extra.price;
                                if( extra.price < minP )
                                    minP = extra.price;
                                if( extra.price > maxP )
                                    maxP = extra.price;
                            }
                            if( extra && extra.qty){
                                fitment.QuantityAvailable = extra.qty;
                                if( extra.qty < minP )
                                    minQ = extra.qty;
                                if( extra.qty > maxP )
                                    maxQ = extra.qty;
                            }
                        });
                    }

                    if( minP != Infinity && maxP != -Infinity)
                    {
                        product.PriceMax = maxP;
                        product.PriceMin = minP;
                    }
                    
                    if( minQ != Infinity && maxQ != -Infinity)
                    {
                        product.InventoryMax = maxQ;
                        product.InventoryMin = minQ;
                    }
                }
            }
            
        },
        async loadMoreResults() {
            
            if (this.isLoading || !this.hasMoreResults) return;

            this.isLoading = true;

            /**
             * Makes a request to the RideStyler API, keeping track of the active request in this compon
             * @returns {Promise}
             **/
            const makeRequest = (action, data, responseKey) => {
                if (this.activeRequest) {
                    if (typeof this.activeRequest.abort === 'function') {
                        this.activeRequest.abort();
                        return;
                    }
                }

                const thisRequest = RideStylerAPIRequest(action, data, responseKey).then(
                    result => {
                        if (this.activeRequest !== thisRequest) return Promise.reject('cancelled');

                        return result;
                    },
                    error => {
                        return Promise.reject(this.activeRequest !== thisRequest ? 'cancelled' : error);
                    }
                );

                thisRequest.finally(() => {
                    this.activeRequest = undefined;

                    if (document.activeElement === this.$el) setTimeout(() => this.onFocused(), 0);
                });

                return this.activeRequest = thisRequest;
            };

            const productType = this.productType;

            const filters = Object.assign({
                Start: this.products.length + 1,
                Count: settings.options.showPartNumbers == undefined ? this.resultsPerLoad : settings.options.showPartNumbers.Count,
                NoCache: true,
                IncludeFitments: settings.options.selectPartNumber != undefined || settings.options.extraDetails != undefined
            }, this.baseRequest, this.currentFilters);

            const oldProductLength = this.products.length;
//console.log("makerequest", this.resultsAction, filters, settings.options.showPartNumbers, this.products);
            await makeRequest(this.resultsAction, filters, this.responseKey).then(
                /** @param {any[]} responseProducts */
                responseProducts => {

                    const initiallySelectedProduct = this.initiallySelectedProduct;
                    
                    const hasNoProductSelected = !this.selectedProduct;
                    
                    let uniqueNew = [];
                    //console.log("responseProducts", responseProducts);
                    responseProducts.forEach(product => {
                        const key = this.productKeyRetriever(product);
                        this.extendProduct(product);

                        product.key = key;
                        product.type = productType;
                        
                        if( !this.selectedProduct && settings.options.selectPartNumber ) {
                            if( productType == "wheel") {
                                product.WheelFitments.forEach(fitment => {
                                    if( settings.options.partNumbersAreInventoryNumbers && fitment.ItemNumber == settings.options.selectPartNumber) {
                                         this.selectedProduct = product;
                                        this.$store.commit('selectDiameter', fitment.DiameterMin);
                                    }
                                    else if( fitment.PartNumber == settings.options.selectPartNumber) {
                                        this.selectedProduct = product;
                                        this.$store.commit('selectDiameter', fitment.DiameterMin);
                                    }
                                });
                            }
                        }
                        else if (hasNoProductSelected && initiallySelectedProduct && initiallySelectedProduct === key) {
                            this.selectedProduct = product;
                        } 

                        if( !this.products.some( p => key == this.productKeyRetriever(p) ) )
                            uniqueNew.push(product);

                    });
                    
                    //console.log("loaded", this.products, uniqueNew);
                    //this.products = this.products.concat(responseProducts);
                    this.products = this.products.concat(uniqueNew);

                    if( productType == "wheel" && settings.options.showPartNumbers != null ) {
                        
                        this.products.sort((a,b)=>{
                            let ida = -1;
                            for(let i =0; i < a.WheelFitments.length; i ++)
                            {
                                let v = -1;
                                if( settings.options.partNumbersAreInventoryNumbers )
                                    v = settings.options.showPartNumbers.indexOf(a.WheelFitments[i].ItemNumber);
                                else 
                                    v = settings.options.showPartNumbers.indexOf(a.WheelFitments[i].PartNumber);

                                if( v != -1 && v < ida || ida == -1 )
                                    ida = v;
                            
                            }    
                            let idb = -1;
                            for(let i =0; i < b.WheelFitments.length; i ++)
                            {
                                let v = -1;
                                if( settings.options.partNumbersAreInventoryNumbers )
                                    v = settings.options.showPartNumbers.indexOf(b.WheelFitments[i].ItemNumber);
                                else 
                                    v = settings.options.showPartNumbers.indexOf(b.WheelFitments[i].PartNumber);

                                if( v != -1 && v < idb || idb == -1 )
                                    idb = v;
                            }  

                            return ida - idb;

                        });
                        
                    }

                    if (responseProducts.length < this.resultsPerLoad) {
                        this.hasMoreResults = false;
                    }
                }
            )
                .then(
                    () => {
                        this.isLoading = false;
                        settings.options.replacePartNumbers = false;
                        this.$nextTick(() => this.updateScrollStatus());
                        if(oldProductLength != 0)
                        {
                            this.$nextTick(function(){
                            // const productKey = this.productKeyRetriever(this.selectedProduct);
                                const wrapper = this.$refs.wrapper; 
                                const productElements = Array.from(wrapper.children);
                                const selectedProductElement = productElements[oldProductLength];
                                if (selectedProductElement) selectedProductElement.focus();

                            });
                        }
                        

                    },
                    error => {
                        if (error === 'cancelled') return;

                        this.isLoading = false;
                        this.$nextTick(() => this.updateScrollStatus());
                    }
                );
        },
        onWheelScroll(event) {
            /** @type {HTMLElement} */
            
            if( settings.options.scrollWheelEnabled ) {
                const wrapper = this.$refs.wrapper;

                const delta = event.deltaX || event.deltaY;
                const {scrollLeft, scrollWidth, offsetWidth} = wrapper;

                const newValue = Math.min(scrollLeft + delta, scrollWidth - offsetWidth);

                wrapper.scrollLeft = newValue;

                this.touchScrollHandler.setValues(newValue, 0);
                //this.onContainerScrolled();
            }

            
            
        },
        onScroll() {
           this.onContainerScrolled();
        },
        onFocused() {
            
            /** @type {HTMLElement} */
            const wrapper = this.$refs.wrapper;

            if (this.selectedProduct) {
                const productKey = this.productKeyRetriever(this.selectedProduct);
                const productElements = Array.from(wrapper.children);
                const selectedProductElement = productElements.find(el => el.getAttribute('data-key') == productKey);

                if (selectedProductElement) selectedProductElement.focus();
            }
        },
        onKeyDown(e) {
            const key = e.which || e.keyCode;
            const focused = document.activeElement;

            switch (key) {
                case 39: // right
                    if (focused.nextElementSibling) focused.nextElementSibling.focus();
                    break;
                case 37: // left
                    if (focused.previousElementSibling) focused.previousElementSibling.focus();
                    break;
                case 33: // page up
                    this.nextPage();
                    break;
                case 34: // page down
                    this.previousPage();
                    break;
                case 32: // space
                case 13: // enter

                  var event;
                   if (typeof(Event) === 'function') {
                       event = new Event("click");
                   } else {
                       event = document.createEvent('Event');
                       event.initEvent("click", true, true);
                   }

                    focused.dispatchEvent(event);
                    break;
            }
        },
        onContainerScrolled() {
            /**
             * The amount of pixels from the end of the container to consider as "reached the end"
             */
            const reachedEndTolerance = 10;

            /**
             * The scrollable wrapper
             * @type {HTMLElement}
             */
            const wrapper = this.$refs.wrapper;

            const { scrollLeft, scrollWidth, offsetWidth } = wrapper;

            const reachedEnd = scrollLeft >= scrollWidth - offsetWidth - reachedEndTolerance;

            this.$nextTick(() => this.updateScrollStatus());

            if (reachedEnd) this.onEndVisible();
        },
        updateScrollStatus() {

            /**
             * The scrollable wrapper
             * @type {HTMLElement}
             */
            const wrapper = this.$refs.wrapper;

            if (!wrapper) return;

            const { scrollLeft, scrollWidth, offsetWidth } = wrapper;

            this.atScrollStart = scrollLeft <= 0;
            this.atScrollEnd = scrollLeft >= scrollWidth - offsetWidth - 1 && scrollWidth - offsetWidth - 1 != -1;

            this.updateBounds();
        },
        onEndVisible() {
            if (!this.hasMoreResults) return;

            this.loadMoreResults();
        },
        updateBounds() {
            const {scrollWidth, offsetWidth} = this.$refs.wrapper;

            this.touchScrollHandler.setBoundX([0, scrollWidth - offsetWidth]);
        },
        scrollTo(newLeft) {
            /** @type {HTMLElement} */
            const wrapper = this.$refs.wrapper;

            const startLeft = wrapper.scrollLeft;
            const duration = Math.abs(startLeft - newLeft) / 2;

            // Cancel the current scroll animation if there is one
            if (this.currentScrollAnimation) {
                TinyAnimate.cancel(this.currentScrollAnimation);
            }

            this.currentScrollAnimation = TinyAnimate.animate(startLeft, newLeft, duration, currentLeft => {
                wrapper.scrollLeft = currentLeft;

                this.touchScrollHandler.setValues(newLeft, 0);
            }, 'easeOutCubic', () => {
                this.onContainerScrolled();
            });
        },
        previousPage() {
            /** @type {HTMLElement} */
            const wrapper = this.$refs.wrapper;
            const { scrollLeft, offsetWidth } = wrapper;

            this.scrollTo(scrollLeft - offsetWidth);
        },
        nextPage() {
            /** @type {HTMLElement} */
            const wrapper = this.$refs.wrapper;
            const { scrollLeft, offsetWidth } = wrapper;

            this.scrollTo(scrollLeft + offsetWidth);
        },
        currentProductIndex() {
            if (!this.selectedProduct) return -1;

            return this.products.indexOf(this.selectedProduct);
        },
        selectProduct(product, e) {
            if (e && this.touchStartData && e.type === 'touchend') {
                var startTouch = this.touchStartData;
                var endTouch = e.changedTouches[0];


                var xChange = endTouch.screenX - startTouch.screenX;
                var yChange = endTouch.screenY - startTouch.screenY;
                var distance = Math.sqrt(xChange * xChange + yChange * yChange);

                if (distance > 10) return;
            }
            
            
     
            this.selectedProduct = product;
        },
        reset() {
            this.products = [];
            this.selectedProduct = undefined;
            this.hasMoreResults = true;
            this.touchScrollHandler.setValues(0, 0);

            this.$nextTick(this.loadMoreResults);
        }
    },
    props: {
        productType: {
            type: String,
            required: true
        },
        resultsAction: {
            type: String,
            required: true
        },
        responseKey: {
            type: String,
            required: true
        },
        baseRequest: Object,
        currentFilters: Object,
        initiallySelectedProduct: String,
        productKeyRetriever: {
            required: true,
            type: Function
        },
        hasIndicators: Boolean
    },
    mounted() {
        this.touchScrollHandler = new Impetus({
            source: this.$refs.wrapper,
            bounce: false,
            multiplier: -1,
            friction: 0.9,
            update: (x, y) => {
                /** @type {HTMLElement} */
                const wrapper = this.$refs.wrapper;

                if (!wrapper) return;

                wrapper.scrollLeft = Math.floor(x);
                wrapper.scrollTop = y;


                this.onContainerScrolled();
            },
            boundY: [0, 0]
        })
    },
    destroyed() {
        if (this.touchScrollHandler) {
            this.touchScrollHandler.destroy();
        }
    },
    computed: {
        productGalleryClass() {
            const classes = [];

            if (this.loadingFirstResults) classes.push('ridestyler-showcase-product-gallery-initializing')

            return classes;
        },
        loadingFirstResults() { return this.products.length === 0; },
        showPartNumbersCount() {
            //console.log("showPartNumbersCount",settings.options.showPartNumbers.join(','));
            return settings.options.showPartNumbers?settings.options.showPartNumbers.join(','):0;
        },
        selectPartNumber() {
            return settings.options.selectPartNumber;
        },
        
        ...mapState([
            'selectedWheelModel',
            'selectedTireModel',
        ])
    },
    watch: {
        selectPartNumber() {
            this.products.forEach(product=> {
                if( this.productType == "wheel")
                {
                    product.WheelFitments.forEach(fitment => {
                        if(settings.options.partNumbersAreInventoryNumbers && settings.options.selectPartNumber == fitment.ItemNumber)
                        {
                            this.selectedProduct = product;
                            this.$store.commit('selectDiameter', fitment.DiameterMin);
                            this.onFocused();
                        }
                        else if( settings.options.selectPartNumber == fitment.PartNumber )
                        {
                            this.selectedProduct = product;
                            this.$store.commit('selectDiameter', fitment.DiameterMin);
                            this.onFocused();
                        }
                    });
                }
            });
        },
        showPartNumbersCount() {
           if( this.productType == "wheel")
           {
                //console.log( settings.options.replacePartNumbers, settings.options.showPartNumbers);
                if( settings.options.replacePartNumbers ){
                    this.reset();
                }
                   


                this.loadMoreResults();
           }
               
        },
        productType() {
            this.reset();
        },
        selectedProduct(newProduct) {
            this.$emit('product-selected', newProduct);
        },
        selectedTireModel(newTireModel){
            if(this.productType == 'tire' && newTireModel == undefined) this.selectedProduct = undefined;
        },
        currentFilters: {
            handler() {
                if( !settings.options.showPartNumbers )
                    this.reset();
            },
            deep: false
        }
    },
    components: {
        SvgIcon,
        ProductGalleryProduct,
        LoadingIndicator
    },
    directives: {
        'on-wheel': wheelDirective
    }
}

</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-product-gallery {
        color: black;
        height: 100%;
        padding: 0 2.6%;
        outline: none;
        position: absolute;
        left: 0; right: 0;
        .ridestyler-showcase-loading-indicator {
            opacity: 0.75;
        }
    }
    .ridestyler-showcase-product-gallery-wrapper {
        width: 100%; height: 100%;
        white-space: nowrap;
        overflow: hidden;
        &::before {
            opacity: 0;
            content: "";
            background: transparent;
            width: 7%; height: 100%;
            left: 2.6%; top: 0;
            display: block;
            position: absolute;
            z-index: 1;
            pointer-events: none;
            -webkit-transition: opacity 1s ease-out;
            -moz-transition: opacity 1s ease-out;
            -o-transition: opacity 1s ease-out;
            transition: opacity 1s ease-out;
        }
        &::after {
            opacity: 0;
            content: "";
            background: transparent;
            width: 7%; height: 100%;
            right: 2.6%; top: 0;
            display: block;
            position: absolute;
            z-index: 1;
            pointer-events: none;
            -webkit-transition: opacity 1s ease-out;
            -moz-transition: opacity 1s ease-out;
            -o-transition: opacity 1s ease-out;
            transition: opacity 1s ease-out;
        }
        &.fade-before {
            &::before {
                opacity: 1;
                content: "";
                background: linear-gradient(to left, transparent, $secondary-background-color);
                width: 7%; height: 100%;
                left: 2.6%; top: 0;
                display: block;
                position: absolute;
                z-index: 1;
                pointer-events: none;
            }
        }
        &.fade-after {
            &::after {
                opacity: 1;
                content: "";
                background: linear-gradient(to right, transparent, $secondary-background-color);
                width: 7%; height: 100%;
                right: 2.6%; top: 0;
                display: block;
                position: absolute;
                z-index: 1;
                pointer-events: none;
            }
        }
    }
    .ridestyler-showcase-product-gallery-no-results {
        font-size: 3em;
        font-weight: 600;
        opacity: 0.5;
        text-align: center;
        padding: 1em 0;
        p {
            font-size: 0.5em;
        }
    }
    %pagination-button {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        padding: 0.9em 0em;
        border: none;
        background: #566273;
        .ridestyler-showcase-icon {
            fill: currentColor;
            opacity: 1;
        }
        &:disabled .ridestyler-showcase-icon {
            opacity: 0.5;
        }
    }
    .ridestyler-showcase-product-gallery-next {
        @extend %pagination-button;
        right: 0;
        vertical-align: middle;
        color: white;
        display: flex;
        svg {
            height: 1em; width: 2em;
        }
    }
    .ridestyler-showcase-product-gallery-prev {
        @extend %pagination-button;
        left: 0;
        vertical-align: middle;
        color: white;
        display: flex;
        svg {
            height: 1em; width: 2em;
        }
    }
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-product-gallery {
            padding: 0;
        }
        .ridestyler-showcase-product-gallery-wrapper {
            &.fade-after::after {
                right: 0;
            }
            &.fade-before::before {
                left: 0;
            }
        }
        .ridestyler-showcase-product-gallery-next, .ridestyler-showcase-product-gallery-prev {
            display: none;
        }
    }
    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        .ridestyler-showcase-product-gallery-wrapper {
            &.fade-before {
                &::before {
                    opacity: 0;
                    content: "";
                    background: transparent;
                }
            }
            &.fade-after {
                &::after {
                    opacity: 0;
                    content: "";
                    background: transparent;
                }
            }
        }
    }
}
</style>
