import settings from "../Settings";
import store from "../store";
import state from "../store/state";

var language = {
    created: function() {
        
       
        if( !state.translations )
        {
            var ui = {
                featuresAndSpecs:"Features &amp; Specs",
                featuresAndSpecsSummary:"Summary",
                featuresAndSpecsSpecifications:"Specifications",

                wheelsTab:"Wheels",
                tiresTab:'Matching Tire Packages',
                suspensionTab:'Adjust Suspension',
                changeVehicle:"Change Vehicle",
    
                priceEach:"Price (ea.)",
                selectYourVehicle:"Select Your Vehicle",
                chooseYourPaintColor:"Choose Your Paint Color",

                shareButton:"Share",
                startMessage:"Let us help you find <strong>the perfect set of wheels</strong> for your vehicle!",
                startButton:"Get Started",

                colorHeading: "Is this your vehicle?",
                colorMessage: "Please adjust the paint color to match your vehicle and confirm your selection.",
                colorButton: "Confirm"
            };
    
            var pricing_avail = {
                tireStockNull: "Inquire for Availability",
                tireStockNullShort:"Inquire",
                tireStockOut:"Out of Stock",
                tireStockLow:"Limited Availability",
                tireStockIn:"In Stock",
    
                wheelStockNull: "Inquire for Availability",
                wheelStockNullShort: "Inquire",
                wheelStockOut:"Out of Stock",
                wheelStockLow:"Limited Availability",
                wheelStockIn:"In Stock",
    
                wheelPriceNull: "Inquire",
                tirePriceNull: "Inquire",
            }
            
            store.commit("updateTranslations", {
                ...ui, ...pricing_avail, ...settings.options.translations
            });
        }
        

    },
    methods: {
        translate(key) {
            let t = state.translations[key];
            if( t )
                return t;
            return key;
        }
    }
};

export default language;