import RideStylerAPIRequest from "../RideStylerAPIRequest";
import settings from "../Settings";
import Settings from '../Settings';

/** @typedef {import("./state").VehicleAngle} VehicleAngle */
/** @typedef {import("./state").VehicleSelection} VehicleSelection */
/** @typedef {import("./getters").RideStylerShowcaseGetters} RideStylerShowcaseGetters */
/** @typedef {import("./state").RideStylerShowcaseState} RideStylerShowcaseState */
/**
 * @typedef ActionContext
 * @prop {import("../../node_modules/vuex/types/index").Dispatch} dispatch
 * @prop {import("../../node_modules/vuex/types/index").Commit} commit
 * @prop {TState} state
 * @prop {TGetters} getters
 * @prop {RideStylerShowcaseState} rootState
 * @prop {RideStylerShowcaseGetters} rootGetters
 * @template TState
 * @template TGetters
 */

/** 
 * @callback ActionHandler 
 * @param {ActionContext<TState, TGetters>} context
 * @param {any} [payload]
 * @returns {Promise<any>}
 * @this {import('../../node_modules/vuex/types/index').Store<RideStylerShowcaseState>}
 * @template TState
 * @template TGetters
 **/

/**
 * @typedef ActionObject
 * @prop {boolean} [root]
 * @prop {ActionHandler<TState,TGetters>} handler
 * @template TState, TGetters
 */

/**
 * @typedef {(ActionHandler<TState,TGetters>|ActionObject<TState,TGetters>)} Action
 * @template TState,TGetters
 */

/** @type {{[key:string]:Action<RideStylerShowcaseState, RideStylerShowcaseGetters>}} */
const actions = {
    /**
     * @param {Object} settings
     * @param {import("./state").ProductType[]} settings.requiredRefinements
     */
    async getStateModel(context, settings){
        const { state, getters, dispatch } = context;

        settings = settings || {};

        /**
         * @type {import('../Plugins').ShowcaseStateModel}
         */
        const dataModel = {
            Vehicle: state.vehicleDescription,
            Wheel: { Front: null, Rear: null },
            Tire: { Front: null, Rear: null },
            WheelModel: state.selectedWheelModel,
            TireModel: state.selectedTireModel,
            ActiveTire: {
                Front: getters.presumedTireFitmentFront,
                Rear: getters.presumedTireFitmentRear
            },
            ActiveWheel: {
                Front: getters.presumedWheelFitmentFront,
                Rear: getters.presumedWheelFitmentRear
            },
            WorkingWheels: {
                Front: getters.workingWheelFitmentSetFront,
                Rear: getters.workingWheelFitmentSetRear
            },
            WorkingTires: {
                Front: getters.workingTireFitmentSetFront,
                Rear: getters.workingTireFitmentSetRear
            },
            Suspension: {Front: state.suspensionFront, Rear: state.suspensionRear}
        };

        // Ask the user for refinements if needed, then supply their selection
        {
            await dispatch('refineUserSelectedProducts', settings.requiredRefinements);

            dataModel.Wheel.Front = state.selectedWheelFitmentFront;
            dataModel.Wheel.Rear = state.selectedWheelFitmentRear;
            dataModel.Tire.Front = state.selectedTireFitmentFront;
            dataModel.Tire.Rear = state.selectedTireFitmentRear;
        }

        return dataModel;
    },

    /**
     * @param {VehicleSelection} selection
     */
    async selectVehicle(context, selection) {
        try {
            if (typeof selection.tireOption === 'string') {
                const tireOptions = await RideStylerAPIRequest('vehicle/gettireoptiondetails', {
                    VehicleConfigurations: [
                        selection.vehicleConfiguration
                    ]
                }, 'Details');

                selection.tireOption = tireOptions[0];
            }

            context.commit('setSelection', selection);

            /** @type {ridestyler.Descriptions.VehicleDescriptionModel[]} */
            const descriptions = await RideStylerAPIRequest('Vehicle/GetDescriptions', {
                VehicleConfiguration: context.state.vehicleConfiguration
            }, 'Descriptions');

            if (descriptions.length === 0) throw "No vehicle description found for selected vehicle";

            const vehicleDescription = descriptions[0];

            context.commit('setVehicleDescription', vehicleDescription);

            {
                /** @type {VehicleAngle} */
                let vehicleAngle;
                const targetVehicleAngle = Settings.defaultVehicleAngle;

                if (targetVehicleAngle === 'angle' && vehicleDescription.HasAngledImage) vehicleAngle = 'angle';
                else if (targetVehicleAngle === 'side' && vehicleDescription.HasSideImage) vehicleAngle = 'side';
                else if (vehicleDescription.HasSideImage) vehicleAngle = 'side';
                else vehicleAngle = 'angle';

                context.commit('rotateVehicle', vehicleAngle);
            }
        } catch(e) {
            context.commit('goToPage', 'error');
        }
    },

    /**
     * @param {object} payload
     * @param {ridestyler.Descriptions.WheelModelDescriptionModel} payload.wheelModel The wheel model
     */
    async selectWheelModel(context, payload) {
        payload = payload || {};

        const wheelModel = payload.wheelModel;

        if (!wheelModel || !wheelModel.WheelModelID) return;
        if (context.state.selectedWheelModel && wheelModel.WheelModelID === context.state.selectedWheelModel.WheelModelID) return;

        context.commit('setSelectedWheelModel', wheelModel);
        await context.dispatch('loadWheelModelFitments');

        if( context.state.lastSelectedDiameterFront == undefined )
        {
            context.commit('selectDiameter', context.getters.availableWheelDiametersForCurrentPosition[0]);
        }
        
        document.dispatchEvent(new CustomEvent("rs-wheel-model-selected", { detail: { model: wheelModel, selectedDiameter:context.state.lastSelectedDiameterFront }} ));
        
    },

    async loadWheelModelFitments(context) {
        if (!context.state.selectedWheelModel) {
            context.commit('setAvailableWheelFitmentsFront', []);
            context.commit('setAvailableWheelFitmentsRear', []);
            return;
        }

        /** @type {ridestyler.Descriptions.WheelFitmentDescriptionModel[]} */
        const fitments = await RideStylerAPIRequest("wheel/getfitmentdescriptions", Object.assign({
            WheelModel: context.state.selectedWheelModel.WheelModelID
        }, context.getters.wheelFilters), "Fitments");

        fitments.forEach(fitment => {
            fitment.type = 'wheel';
            
            if( settings.options.extraDetails ) {
                let extra;
                
                if( !settings.options.partNumbersAreInventoryNumbers )
                    extra = settings.options.extraDetails.find(detail => detail.partNumber == fitment.PartNumber);
                else
                    extra = settings.options.extraDetails.find(detail => detail.partNumber == fitment.ItemNumber);
                
                
                if( extra ) {
                    fitment.DetailsLink = extra.detailsLink;
                }
                if( extra && extra.price ) {
                    fitment.Price = extra.price;
                }
                if( extra && extra.qty ) {
                    fitment.QuantityAvailable = extra.qty;
                }
            }
            
        });

        // TODO: Staggered support

        context.commit('setAvailableWheelFitmentsFront', fitments);
        context.commit('setAvailableWheelFitmentsRear', fitments);
    },

    async loadAvailableTireCount(context, payload) {
        const filters = Object.assign({ NoCache: true }, payload.tireSettings.baseRequest, payload.tireSettings.currentFilters);
        const wheelTireOptionCount = await RideStylerAPIRequest('Tire/CountModels', filters, 'Count');

        context.commit('setAvailableTireCount', wheelTireOptionCount);
    },

    /**
     * @param {object} payload
     * @param {ridestyler.Descriptions.TireModelDescriptionModel} payload.tireModel
     */
    async selectTireModel(context, payload) {
        payload = payload || {};

        const tireModel = payload.tireModel;

        if (!tireModel || !tireModel.TireModelID) return;
        if (context.state.selectedTireModel && tireModel.TireModelID === context.state.selectedTireModel.TireModelID) return;

        context.commit('setSelectedTireModel', tireModel);
        document.dispatchEvent(new CustomEvent("rs-tire-model-selected", { detail: { model: tireModel, selectedDiameter:context.state.lastSelectedDiameterFront }} ));

        await context.dispatch('loadTireModelFitments');
    },

    async loadTireModelFitments(context) {
        if (!context.state.selectedTireModel) {
            context.commit('setAvailableTireFitmentsFront', []);
            context.commit('setAvailableTireFitmentsRear', []);
            return;
        }

        /** @type {ridestyler.Descriptions.TireFitmentDescriptionModel[]} */
        const fitments = await RideStylerAPIRequest("tire/getfitmentdescriptions", Object.assign({
            TireModel: context.state.selectedTireModel.TireModelID
        }, context.getters.tireFilters), "Fitments");

        fitments.forEach(fitment => fitment.type = 'tire');

        context.commit('setAvailableTireFitmentsFront', fitments);
        context.commit('setAvailableTireFitmentsRear', fitments);
    },

    notify(context, notification) {
        context.commit('appendNotification', notification);

        let duration = notification.time;

        if (duration <= 0) duration = 2000;

        setTimeout(() => {
            context.commit('clearNotification', notification);
        }, duration);
    },

    /**
     * checks if the selected tire will fit on a newly selected wheel
     * @param {ridestyler.Descriptions.WheelFitmentDescriptionModel} wheelFitment 
     * @param {ridestyler.Descriptions.TireFitmentDescriptionModel} tireFitment 
     */
    checkTireModelFitsWheelModel(context, wheelAndTireFitments){
        let batch = ridestyler.ajax.batch();

        batch.send(wheelAndTireFitments.Wheel.PartNumber, {
            action: 'tire/recommendsize',
            data: {
                BaseTireSizeDesc: wheelAndTireFitments.Tire.TireSize.Description,
                NewWheelDiameter: wheelAndTireFitments.Wheel.DiameterMin,
                NewWheelWidth: wheelAndTireFitments.Wheel.WidthMin
            }
        });
    
        batch.done(function (r) {
            Object.keys(r).forEach(function(key){
                if(wheelAndTireFitments.Tire && r[key].Recommendations[0].Size.Description !== wheelAndTireFitments.Tire.TireSize.Description) {
                    context.commit('setSelectedTireModel', undefined);
                }
            });
        });

        batch.execute();
    },

    /**
     * @param {import("./state").ProductType[]} requiredRefinements
     */
    async refineUserSelectedProducts(context, requiredRefinements){
        const fitmentPosition = context.state.fitmentPosition;
        const products = {};

        requiredRefinements = requiredRefinements || [];

        let refineWheels = false;
        let refineTires = false;

        requiredRefinements.forEach(type => {
            if (type === 'tire') refineTires = true;
            if (type === 'wheel') refineWheels = true;
        });

        const KEYS = {
            WHEEL_FRONT: 'Wheel (Front)',
            WHEEL_REAR: 'Wheel (Rear)',
            WHEEL: "Wheel",
            TIRE_FRONT: "Tire (Front)",
            TIRE_REAR: "Tire (Rear)",
            TIRE: "Tire"
        };
        
        // Build products
        {
            if(refineWheels) {
                if (fitmentPosition !== 'both') {
                    if(!context.state.selectedWheelFitmentFront) products[KEYS.WHEEL_FRONT] = context.getters.workingWheelFitmentSetFront;
                    else if(context.state.selectedWheelFitmentFront) products[KEYS.WHEEL_FRONT] = context.state.selectedWheelFitmentFront;
                    if(!context.state.selectedWheelFitmentRear) products[KEYS.WHEEL_REAR] = context.getters.workingWheelFitmentSetRear;
                    else if(context.state.selectedWheelFitmentRear) products[KEYS.WHEEL_REAR] = context.state.selectedWheelFitmentRear;
                } else {
                    if(!context.state.selectedWheelFitmentFront) products[KEYS.WHEEL] = context.getters.workingWheelFitmentSetFront;
                    else if(context.state.selectedWheelFitmentFront) products[KEYS.WHEEL] = context.state.selectedWheelFitmentFront;
                }
            }
    
            if (refineTires) {
                if (fitmentPosition !== 'both') {
                    if(!context.state.selectedTireFitmentFront) products[KEYS.TIRE_FRONT] = context.getters.workingTireFitmentSetFront;
                    else if(context.state.selectedTireFitmentFront) products[KEYS.TIRE_FRONT] = context.state.selectedTireFitmentFront;
                    if(!context.state.selectedTireFitmentRear) products[KEYS.TIRE_REAR] = context.getters.workingTireFitmentSetRear;
                    else if(context.state.selectedTireFitmentRear) products[KEYS.TIRE_REAR] = context.state.selectedTireFitmentRear;
                } else {
                    if(!context.state.selectedTireFitmentFront) products[KEYS.TIRE] = context.getters.workingTireFitmentSetFront;
                    else if(context.state.selectedTireFitmentFront) products[KEYS.TIRE] = context.state.selectedTireFitmentFront;
                }
            }
        }

        const refinedProducts = await context.dispatch('refineproducts/refine', products);

        // Update user selections in state
        if (refinedProducts) {
            if (KEYS.TIRE_FRONT in refinedProducts) context.commit('setSelectedTireFitmentFront', refinedProducts[KEYS.TIRE_FRONT]);
            if (KEYS.TIRE_REAR in refinedProducts) context.commit('setSelectedTireFitmentRear', refinedProducts[KEYS.TIRE_REAR]);
            if (KEYS.WHEEL_FRONT in refinedProducts) context.commit('setSelectedWheelFitmentFront', refinedProducts[KEYS.WHEEL_FRONT]);
            if (KEYS.WHEEL_REAR in refinedProducts) context.commit('setSelectedWheelFitmentRear', refinedProducts[KEYS.WHEEL_REAR]);
            if (KEYS.WHEEL in refinedProducts) {
                context.commit('setSelectedWheelFitmentFront', refinedProducts[KEYS.WHEEL]);
                context.commit('setSelectedWheelFitmentRear', refinedProducts[KEYS.WHEEL]);
            }
            if (KEYS.TIRE in refinedProducts) {
                context.commit('setSelectedTireFitmentFront', refinedProducts[KEYS.TIRE]);
                context.commit('setSelectedTireFitmentRear', refinedProducts[KEYS.TIRE]);
            }
        }

        return refinedProducts;
    },

    /**
     * Rotate the vehicle if needed to match a list of supported angles
     * @param {VehicleAngle[]} requestedAngles
     */
    async rotateIfNeeded(context, requestedAngles) {
        if (!requestedAngles || requestedAngles.length === 0) return;

        const {
            vehicleDescription,
            vehicleRotation
        } = context.state;

        const supportedAngles = [];
        for (const angle of requestedAngles) {
            if (angle === vehicleRotation) return; // Vehicle is already at a requested angle

            const angleIsSupported = angle === 'angle' && vehicleDescription.HasAngledImage || angle === 'side' && vehicleDescription.HasSideImage;

            if (angleIsSupported) supportedAngles.push(angle);
        }

        if (supportedAngles.length > 0)
            context.commit('rotateVehicle', supportedAngles[0]);
    },

    async selectWheelFilters(context, filters) {
        context.commit('setSelectedWheelFilters', filters);
        await context.dispatch('loadWheelModelFitments');
    },

    async selectTireFilters(context, filters) {
        context.commit('setSelectedTireFilters', filters);
        await context.dispatch('loadTireModelFitments');
    },
};

export default actions;