<script>
    import BaseVehicleRenderer from './BaseVehicleRenderer';
        
    export default {
        extends: BaseVehicleRenderer,
        computed: {
            renderInstructions() {
                const instructions = {
                    VehicleConfiguration: this.vehicleConfiguration,
                    PositionX: 1,
                    PositionY: 2
                };

                if (this.paintColor) instructions.PaintColor = this.paintColor

                return instructions;
            }
        },
        props: {
            vehicleConfiguration: {
                type: String,
                required: true
            },
            paintColor: String
        }
    }
</script>