import store from './index';

/** @typedef {import('./state').RideStylerShowcaseState} RideStylerShowcaseState */
/** @typedef {import('./getters').RideStylerShowcaseGetters} RideStylerShowcaseGetters */
/** @typedef {import('./state').VehicleAngle} VehicleAngle */

/**
 * @param {(state:RideStylerShowcaseState, getters:RideStylerShowcaseGetters)=>T} watchedPropertyGetter A function that returns the property to watch
 * @param {(value:T, oldValue:T)=>void} callback A function to call when the watched proeperty changes, called with the new value and the old value as parameters
 * @template T
 */
function watch (watchedPropertyGetter, callback) {
    store.watch(watchedPropertyGetter, callback);
}

export function initialize() {
    // When the user hits the 'home' page, clear the selection
    watch(state => state.page, function (page) {
        if (page === 'home') store.commit('setSelection', undefined);
    });
    
    // Watch for changed tire and wheel fitments and request that the vehicle be rotated if needed and it can be 
    {
        /**
         * Watches a changed tire fitment and requests the vehicle be rotated to the side if it can be
         * @param {ridestyler.Descriptions.TireFitmentDescriptionModel} tireFitment 
         */
        const updateVehicleAngleForTireFitment = (tireFitment) => {
            if (!tireFitment || !tireFitment.HasRenderSideImage) return;
        
            /** @type {VehicleAngle[]} */
            const angles = ['side'];
        
            store.dispatch('rotateIfNeeded', angles);
        };

        /**
         * Requests the vehicle be rotated to the side if it can be
         * @param {ridestyler.Descriptions.WheelFitmentDescriptionModel} wheelFitment 
         */
        const updateVehicleAngleForWheelFitment = (wheelFitment) => {
            if (!wheelFitment) return;

            /** @type {VehicleAngle[]} */
            const angles = [];
        
            if (wheelFitment.HasAngleImage) angles.push('angle');
            if (wheelFitment.HasSideImage) angles.push('side');
        
            if (angles.length === 0) return;
        
            store.dispatch('rotateIfNeeded', angles);
        }; 

        /**
         * All events that will be triggered when the presumedWheelFitments are updated
         * @param {ridestyler.Descriptions.WheelFitmentDescriptionModel} wheelFitment 
         */
        const wheelWatcherEvents = (wheelFitment) => {
            updateVehicleAngleForWheelFitment(wheelFitment);

            if(store.getters.hasTireModelSelected){
                if(wheelFitment === store.getters.presumedWheelFitmentFront) store.dispatch('checkTireModelFitsWheelModel',{Wheel: wheelFitment, Tire: store.getters.presumedTireFitmentFront});
                else store.dispatch('checkTireModelFitsWheelModel',{Wheel: wheelFitment, Tire: store.getters.presumedTireFitmentRear});
            } 
        }

        watch((_, getters) => getters.presumedTireFitmentFront, updateVehicleAngleForTireFitment);
        watch((_, getters) => getters.presumedTireFitmentRear, updateVehicleAngleForTireFitment);
        watch((_, getters) => getters.presumedWheelFitmentFront, wheelWatcherEvents);
        watch((_, getters) => getters.presumedWheelFitmentRear, wheelWatcherEvents);
    }
}