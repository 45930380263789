/**
 * @type {Vue.PluginObject}
 */
const plugin = {
    install: function (Vue) {
        let key = 0;
        Vue.prototype.$notify = function (text, type, time) {
            this.$store.dispatch('notify', {
                text,
                type,
                time,
                key: key++
            });
        }
    }
};

export default plugin;