<script>
    import BaseVehicleRenderer from './BaseVehicleRenderer';
    import { mapGetters } from 'vuex';
        
    export default {
        extends: BaseVehicleRenderer,
        computed: mapGetters({
            canRotateVehicle: 'canRotateVehicle',
            renderInstructions: 'currentVehicleRenderInstructions',
            messages: 'currentVehicleRenderNotices'
        })
    }
</script>