/**
 * @typedef {Vue.default} Showcase
 * @typedef {Vue.default | {$showcasePlugins: ShowcasePluginProvider}} ComponentWithPlugins
 */

/**
 * @template T
 * @typedef {object} FrontRearModel
 * @prop {T} Front
 * @prop {T} Rear
 */

/** 
 * @typedef {object} ShowcaseStateModel
 * @prop {ridestyler.Descriptions.VehicleDescriptionModel} Vehicle The vehicle selected by the user
 * @prop {FrontRearModel<ridestyler.Descriptions.WheelFitmentDescriptionModel>} Wheel The wheel selected by the user
 * @prop {FrontRearModel<ridestyler.Descriptions.TireFitmentDescriptionModel>} Tire The tire selected by the user
 * @prop {FrontRearModel<ridestyler.Descriptions.WheelModelDescriptionModel>} WheelModel The wheel model selected by the user
 * @prop {FrontRearModel<ridestyler.Descriptions.TireModelDescriptionModel>} TireModel The tire model selected by the user
 * @prop {FrontRearModel<ridestyler.Descriptions.WheelFitmentDescriptionModel>} ActiveWheel The active wheel in the showcase, will match the selected wheel if there is one
 * @prop {FrontRearModel<ridestyler.Descriptions.TireFitmentDescriptionModel>} ActiveTire The active tire in the showcase, will match the selected tire if there is one
 * @prop {FrontRearModel<ridestyler.Descriptions.WheelFitmentDescriptionModel[]>} WorkingWheels The working set of wheel fitments in the showcase
 * @prop {FrontRearModel<ridestyler.Descriptions.TireFitmentDescriptionModel[]>} WorkingTires The working set of tire fitments in the showcase
 * @prop {FrontRearModel<number>} Suspension The selected suspension values
 */

import Action from './Action';
import store from './store';

class ShowcasePluginProvider {
    /** @type {Vue.VueConstructor} */
    _vue;

    /** @type {{[zone:string]:Vue.ComponentOptions<Vue.ComponentOptions>[]}} */
    widgets;

    /** @type {Array} */
    actions;

    /**
     * Creates a new ShowcasePluginProvider instance
     * @param {Vue.VueConstructor} vue
     */
    constructor (vue) {
        this._vue = vue;

        this._vue.component('widget-zone', widgetZoneComponent);

        this.widgets = {};
        this.actions = this._vue.observable({});

    }

    /**
     * @param {string} type
     * @param {string} label
     * @param {function} label
     * @param {object} settings
     */
    registerShowcaseAction(type, label, callback, settings) {
        settings = settings || {};

        let actionKey = settings.key || label;

        const action = this._vue.observable(new Action({
            type: type,
            label: label,
            callback: callback,
            isVisible: settings.isVisible,
            isDisabled: settings.isDisabled
        }));

        this.actions[actionKey] = action;

        return action;
    }

    /**
     * @param {string} zone
     * @param {Vue.ComponentOptions} component
     */
    registerWidget(zone, component) {
        const widgets = this.widgets;

        if (zone in widgets === false) widgets[zone] = [component];
        else if (!widgets[zone].includes(component)) widgets[zone].push(component);
    }

    /**
     * @returns {Promise<ShowcaseStateModel>}
     */
    getStateModel(settings) {
        return store.dispatch('getStateModel', settings);
    }
}

var widgetZoneComponent = {
    computed: {
        computedComponents() {
            let {max, components} = this;

            if (typeof max !== "number" || max < 0 || max > components.length) max = components.length;

            return components.slice(0, max - 1);
        }
    },

    /**
     * @param {Vue.CreateElement} createElement
     * @returns {Vue.VNode}
     */
    render(createElement) {
        const widgets = this.$showcasePlugins.widgets;
        const components = widgets[this.zone];

        const container = createElement('div');

        if (!components || !components.length) return container;

        const maxCount =
            typeof this.max === "number" && this.max > 0 ?
                Math.min(components.length, this.max) :
                components.length;

        container.children = [];

        for (let i = 0; i < maxCount; i++) {
            const component = components[i];

            container.children.push(createElement(component));
        }

        return container;
    },

    props: {
        zone: {
            type: String,
            required: true
        },
        params: Object,
        max: Number
    }
};

/**
 * @param {Vue.VueConstructor} Vue
 * @returns {ShowcasePluginProvider}
 */
export default function installPluginProvider (Vue) {
    return Vue.prototype.$showcasePlugins = new ShowcasePluginProvider(Vue);
}
