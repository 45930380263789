import store from './store/index'
import settings from './Settings';
import Color from 'color';

/** @typedef {import('./store').ShowcaseBreakpoint} ShowcaseBreakpoint */

function updateBreakpoint(height, width) {
    const portrait = height > width;
    const viewportShort = (width > 992 && height < 721);

    /** @type {ShowcaseBreakpoint} */
    let breakpoint = 'xs';

    if (width > 1200) breakpoint = 'lg';
    else if (width > 992) breakpoint = 'md';
    else if (width > 667) breakpoint = 'sm';

    store.commit('setViewportIsShort', viewportShort);
    store.commit('setBreakpoint', breakpoint);
    store.commit('setIsPortrait', portrait);
}

/**
 * @param {HTMLElement} el
 */
function setupResponsiveClasses(el) {
    if (typeof el.rsResizeWatcher === 'function') window.removeEventListener('resize', el.rsResizeWatcher);

    let { clientWidth: width, clientHeight: height } = el;

    el.rsResizeWatcher = function () {
        const { clientWidth: newWidth, clientHeight: newHeight } = el;

        if (width !== newWidth || height !== newHeight) {
            width = newWidth;
            height = newHeight;

            updateBreakpoint(height, width);
        }
    };

    window.addEventListener('resize', el.rsResizeWatcher);
}

/**
 * @param {HTMLElement} container
 */
function initializeTheme(container) {
    const theme = settings.theme;

    if (!theme) return;

    if (theme.PrimaryColor) {
        const primaryColor = new Color(theme.PrimaryColor);

        container.style.setProperty('--primary-color', theme.PrimaryColor);
        container.style.setProperty('--primary-color-active', primaryColor.darken(0.4).string());
        container.style.setProperty('--primary-color-disabled', primaryColor.saturate(0.0722).lighten(0.68627));
        container.style.setProperty('--primary-color-overlaid-text', primaryColor.isDark() ? 'white' : 'black');
        container.style.setProperty('--primary-color-disabled-overlaid-text', primaryColor.isDark() ? 'white' : 'black');
    }
}

export default {
    initialize: function () {
        /** @type {HTMLElement} */
        const container = this.$el;
        const computedContainerStyle = getComputedStyle(container);
        const options = settings.options;

        let {clientHeight: containerHeight, clientWidth: containerWidth} = container;

        if (options.disableAutoContainerSizing !== true) {
            if (containerWidth <= 0) container.style.width = '100%';
            if (containerHeight <= 0) {
                if(window.innerWidth > window.innerHeight) container.style.height = (containerHeight = Math.floor(containerWidth / 1.7)) + 'px';
                else container.style.height = (containerHeight = (containerWidth * 1.7)) + 'px';
            }
        }

        if (['absolute', 'relative'].indexOf(computedContainerStyle.position) < 0) {
            container.style.position = 'relative';
        }

        setupResponsiveClasses(container);
        updateBreakpoint(containerHeight, containerWidth);

        settings.onInitialized(() => initializeTheme(container));
    }
}
