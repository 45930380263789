<template>
    <div class="ridestyler-showcase-top-bar" role="toolbar" aria-labelledby="ridestyler-showcase-top-bar-title">
        <div class="ridestyler-showcase-top-bar-left" v-if="(page == 'visualize' || page == 'select') && !disableChangeVehicle">
            <button class="ridestyler-showcase-button" type="button" v-on:click="onChangeVehicleClick('select')" :aria-label="translate('changeVehicle')" :title="translate('changeVehicle')" v-if="page == 'visualize'">
               <SvgIcon name="caret-left"  focusable='false'  /> <template v-if="!isPortrait">{{translate('changeVehicle')}}</template>
            </button>
            <button class="ridestyler-showcase-button" type="button" v-on:click="onChangeVehicleClick('home')" aria-label="Select Vehicle" title="Select Vehicle" v-if="page == 'select'">
               <SvgIcon name="caret-left" focusable='false' />
            </button>
        </div>
        <div class="ridestyler-showcase-top-bar-middle">
            <slot name="title">
                <div id="ridestyler-showcase-top-bar-title">{{title}}</div>
            </slot>
        </div>
        <div class="ridestyler-showcase-top-bar-right">
            <slot name="right"/>
        </div>
    </div>
</template>

<script>
import SvgIcon from './SvgIcon';
import { mapState } from 'vuex';
import settings from '../Settings';

export default {
    components: {
        SvgIcon
    },
    methods: {
        onChangeVehicleClick(page) {
            this.$store.commit('goToPage', page);
        },
        
    },
    computed: {
        ...mapState([
            'page',
            'breakpoint',
            'isPortrait',
        ]),
        disableChangeVehicle() {
            return settings.options.disableChangeVehicle;
        }
    },
    props: {
        title: String
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-top-bar {
        position: relative;
        width: 100%; height: 6.4%;
        -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between;
        background-color: #F1F3F4;
        color: #262626;
        display: -webkit-box; display: -ms-flexbox; display: flex;
        z-index: 101;

        .ridestyler-showcase-top-bar-left {
            button {
                .ridestyler-showcase-icon {
                    fill: white;
                }
            }
        }

        %topBarCell {
            display: inline-flex;
            align-items: center;
        }

        &-left {
            @extend %topBarCell;

            button {
                position: relative;
                display: inline-block;
                height: 100%;
                padding: 0 1.5em;
                background: #566273;
                color: white;
                border: none;
                outline: none;
                cursor: pointer;

                svg {
                    height: 0.8em;
                    width: 0.8em;
                    margin-right: 5px;
                }
            }
        }

        &-middle {
            @extend %topBarCell;
            font-size: 1.1em;
            padding: 0 0 0 20px;
            align-items: center;
            margin: 0 auto 0 0;
            button {
              border:none;
              background:none;
              padding:0;
            }
            span, button {
                margin-left: 15px;
                cursor: pointer;
                width: 1.3em;
                height: 1.3em;
                svg {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                    fill: $secondary-button-color;
                }
            }
        }

        &-right {
            @extend %topBarCell;
            white-space: nowrap;
        }
    }
    // Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-top-bar {
            &-left {
                button {
                    background-color: transparent;
                    color: $secondary-button-color;
                    border-right: none;
                    padding: 0 0.7em 0 0.5em;
                }
                svg.ridestyler-showcase-icon {
                    margin-right: 0;
                    fill: $secondary-button-color;
                    height: 1.2em;
                    width: 1.6em;
                    vertical-align: bottom;
                }
            }
            &-middle {
                padding: 0;
                margin: 0 auto 0 0;
                width: 76%;
                div {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span {
                    height: 1.5em; width: 1.5em;
                    margin: 0;
                    padding: 0 0.7em;
                }
                svg {
                    height: 1.5em; width: 1.5em;
                }
            }
            &.ridestyler-showcase-breakpoint-md {
                .ridestyler-showcase-top-bar {
                    &-middle {
                        line-height: 5em;
                    }
                    h1 {
                        font-size: 1.6em;
                    }
                    .ridestyler-showcase-top-bar-left button {
                        font-size: 1.4em;
                    }
                    .ridestyler-showcase-share-button button {
                        font-size: 1.4em;
                    }
                }
                &.ridestyler-showcase-page-select {
                    .ridestyler-showcase-top-bar {
                        justify-content: flex-start;
                    }
                }
                .ridestyler-showcase-top-bar {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .ridestyler-showcase-top-bar-right {
                    display: inline;
                    height: 100%;
                }
                .ridestyler-showcase-share-button button {
                    svg {
                        width: 100%; height: 1em;
                    }
                }
            }
            &.ridestyler-showcase-breakpoint-sm {
                .ridestyler-showcase-top-bar {
                    &-middle {
                        line-height: 3em;
                        div {
                            width: auto;
                        }
                    }
                }
                &.ridestyler-showcase-page-select {
                    .ridestyler-showcase-top-bar {
                        justify-content: flex-start;
                    }
                }
                .ridestyler-showcase-top-bar {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .ridestyler-showcase-top-bar-right {
                    display: inline;
                    height: 100%;
                }
                .ridestyler-showcase-share-button button {
                    svg {
                        width: 100%; height: 1em;
                    }
                }
            }
            &.ridestyler-showcase-breakpoint-xs {
                &.ridestyler-showcase-page-select {
                    .ridestyler-showcase-top-bar {
                        justify-content: flex-start;
                    }
                }
                .ridestyler-showcase-top-bar {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                }
                .ridestyler-showcase-top-bar-right {
                    display: inline;
                    height: 100%;
                }
                .ridestyler-showcase-share-button button {
                    svg {
                        width: 100%; height: 1em;
                    }
                }
            }
        }
    }
    //end portrait
}
</style>
