import store from './index';
import RideStylerAPIRequest from '../RideStylerAPIRequest';
import Settings from '../Settings';
import { parseQueryString } from '../Parsers';
import { compareGUIDs } from '../Comparers';


/**
 * @typedef PersistedState
 * @prop {string} vc   VehicleConfigurationID
 * @prop {string} to   VehicleTireOptionID
 * @prop {string} wff  WheelFitmentID (front)
 * @prop {string} wfr  WheelFitmentID (rear)
 * @prop {string} tff  TireFitmentID (front)
 * @prop {string} tfr  TireFitmentID (rear)
 * @prop {string} pc   PaintColor
 * @prop {string} s    Suspension Front & Rear
 * @prop {string} sf   Suspension Front
 * @prop {string} sr   Suspension Rear
 * @prop {string} vang Vehicle Angle
 */

/**
 * @typedef {import('./index').RideStylerShowcaseStore} RideStylerShowcaseStore
 * @typedef {import('./state').RideStylerShowcaseState} RideStylerShowcaseState
 * @typedef {import('./getters').RideStylerShowcaseGetters} RideStylerShowcaseGetters
 */

/**
 * @typedef PersistanceRoutine
 * @prop {string[]} options
 * @prop {string[]} keys
 * @prop {(persistedState:PersistedState, store:RideStylerShowcaseStore) => Promise|void } action
 */

/**
 * @param {string} string
 */
function parseMetadata(string) {
    const index = string.lastIndexOf('~');

    return index >= 0 ? {
        value: string.substring(0,index),
        metadata: string.substring(index + 1)
    } : {
        value: string,
        metadata: ''
    };
}

/**
 * @param {string} string
 * @param {string} metadata
 */
function formatMetadata(string, metadata) {
    return string + '~' + metadata;
}

/**
 * @type {PersistanceRoutine[]}
 */
const routines = [
    {
        keys: ['vc', 'to'],
        options:['vehicleConfiguration', 'tireOption'],
        buildState: (options) => {
            return {vc: options.vehicleConfiguration, to: options.tireOption, pc: options.paintColor };
        },
        action: (persistedState, store) => store.dispatch('selectVehicle', {
            vehicleConfiguration: persistedState.vc,
            paintColor: persistedState.pc,
            tireOption: persistedState.to
        })
    },
    {
        keys: ['s'],
        options:['suspension'],
        buildState: (options) => {
            return {s: options.suspension};
        },
        action: (persistedState, store) => store.commit('setSuspension', persistedState.s)
    },
    {
        keys: ['sf', 'sr'],
        options:['suspensionFront','suspensionRear'],
        buildState: (options) => {
            return {sf: options.suspensionFront, sr: options.suspensionRear};
        },
        action: (persistedState, store) => store.commit('setSuspension', {
            front: persistedState.sf || 0,
            rear: persistedState.sr || 0
        })
    },
    {
        keys: ['wff','wfr'],
        options:['wheelFitmentIDFront','wheelFitmentIDRear'],
        buildState: (options) => {
            return {wff: options.wheelFitmentIDFront, wfr: options.wheelFitmentIDRear};
        },
        action: async (persistedState, store) => {
            /** @type {ridestyler.Descriptions.WheelFitmentDescriptionModel} */
            let wheelFitmentDescriptionFront;
            /** @type {boolean} */
            let wheelFitmentDescriptionFrontIsGuess;

            /** @type {ridestyler.Descriptions.WheelFitmentDescriptionModel} */
            let wheelFitmentDescriptionRear;
            /** @type {boolean} */
            let wheelFitmentDescriptionRearIsGuess;

            function useFrontFitment() {
                wheelFitmentDescriptionRear = wheelFitmentDescriptionFront;
                wheelFitmentDescriptionRearIsGuess = wheelFitmentDescriptionFrontIsGuess;
            }

            function useRearFitment() {
                wheelFitmentDescriptionFront = wheelFitmentDescriptionRear;
                wheelFitmentDescriptionFrontIsGuess = wheelFitmentDescriptionRearIsGuess;
            }

            // Try to build wheelFitmentDescriptionFront/wheelFitmentDescriptionRear from the API using wff/wfr
            {
                const wheelFitmentIDFront = parseMetadata(persistedState.wff);
                const wheelFitmentIDRear = parseMetadata(persistedState.wfr);
                const fitmentIDs = [];

                if (wheelFitmentIDFront.value) {
                    fitmentIDs.push(wheelFitmentIDFront.value);
                    wheelFitmentDescriptionFrontIsGuess = wheelFitmentIDFront.metadata.startsWith('g');
                }

                if (wheelFitmentIDRear.value) {
                    fitmentIDs.push(wheelFitmentIDRear.value);
                    wheelFitmentDescriptionRearIsGuess = wheelFitmentIDRear.metadata.startsWith('g');
                }

                /** @type {ridestyler.Descriptions.WheelFitmentDescriptionModel[]} */
                const wheelFitmentDescriptions = await RideStylerAPIRequest('Wheel/GetFitmentDescriptions', { WheelFitments: fitmentIDs }, 'Fitments');

                for ( const wheelFitmentDescription of wheelFitmentDescriptions) {
                    if (compareGUIDs(wheelFitmentDescription.WheelFitmentID, wheelFitmentIDFront.value)) wheelFitmentDescriptionFront = wheelFitmentDescription
                    if (compareGUIDs(wheelFitmentDescription.WheelFitmentID, wheelFitmentIDRear.value)) wheelFitmentDescriptionRear = wheelFitmentDescription
                }
            }

            // The API couldn't match the fitment IDs, do nothing
            if (!wheelFitmentDescriptionFront && !wheelFitmentDescriptionRear) return;

            // Fill in front or rear fitment from the other fitment position if missing
            if (wheelFitmentDescriptionFront && !wheelFitmentDescriptionRear) useFrontFitment();
            else if (wheelFitmentDescriptionRear && !wheelFitmentDescriptionFront) useRearFitment();

            // Grab a model ID from the front fitment, this should match the rear fitment
            const modelID = wheelFitmentDescriptionFront.WheelModelID;

            // If the model IDs don't match, we're going to ignore the rear fitment and use the front for both
            // After this point we will always have a front/rear fitment description
            if (wheelFitmentDescriptionRear.WheelModelID !== modelID) useFrontFitment();

            const state = store.state;

            if (!state.selectedWheelModel || compareGUIDs(state.selectedWheelModel.WheelModelID, modelID)) {
                /** @type {ridestyler.Descriptions.WheelModelDescriptionModel} */
                const options = { WheelModel: modelID };
                if( store.getters.settings.options.showPartNumbers != undefined ) {
                    options["IncludeFitments"] = true;
                    if( store.getters.settings.options.partNumbersAreInventoryNumbers )
                        options["ItemNumbers"] = store.getters.settings.options.showPartNumbers;
                    else
                        options["partNumbers"] = store.getters.settings.options.showPartNumbers;
                }
                const wheelModel = (await RideStylerAPIRequest('Wheel/GetModelDescriptions', options, 'Models'))[0];
                if( wheelModel != null )
                {
                    wheelModel.type = 'wheel';

                    await store.dispatch('selectWheelModel', {
                        wheelModel: wheelModel
                    });
                }
            }

            // Fill in the selected fitment, or state values based on whether or not the fitments were a guess
            {
                if (wheelFitmentDescriptionFrontIsGuess) {
                    store.commit('setLastSelectedDiameterFront', wheelFitmentDescriptionFront.DiameterMin);
                } else {
                    store.commit('setSelectedWheelFitmentFront', wheelFitmentDescriptionFront);
                }

                if (wheelFitmentDescriptionRearIsGuess) {
                    store.commit('setLastSelectedDiameterRear', wheelFitmentDescriptionRear.DiameterMin);
                } else {
                    store.commit('setSelectedWheelFitmentRear', wheelFitmentDescriptionRear);
                }
            }
        }
    },
    {
        keys: ['tff', 'tfr'],
        options:['tireFitmentIDFront','tireFitmentIDRear'],
        buildState: (options) => {
            return {tff: options.tireFitmentIDFront, tfr: options.tireFitmentIDRear};
        },
        action: async (persistedState, store) => {
            // Pretty much a copy/paste of tires above

            /** @type {ridestyler.Descriptions.TireFitmentDescriptionModel} */
            let tireFitmentDescriptionFront;
            /** @type {boolean} */
            let tireFitmentDescriptionFrontIsGuess;

            /** @type {ridestyler.Descriptions.TireFitmentDescriptionModel} */
            let tireFitmentDescriptionRear;
            /** @type {boolean} */
            let tireFitmentDescriptionRearIsGuess;

            function useFrontFitment() {
                tireFitmentDescriptionRear = tireFitmentDescriptionFront;
                tireFitmentDescriptionRearIsGuess = tireFitmentDescriptionFrontIsGuess;
            }

            function useRearFitment() {
                tireFitmentDescriptionFront = tireFitmentDescriptionRear;
                tireFitmentDescriptionFrontIsGuess = tireFitmentDescriptionRearIsGuess;
            }

            // Try to build tireFitmentDescriptionFront/tireFitmentDescriptionRear from the API using tff/tfr
            {
                const tireFitmentIDFront = parseMetadata(persistedState.tff);
                const tireFitmentIDRear = parseMetadata(persistedState.tfr);
                const fitmentIDs = [];

                if (tireFitmentIDFront.value) {
                    fitmentIDs.push(tireFitmentIDFront.value);
                    tireFitmentDescriptionFrontIsGuess = tireFitmentIDFront.metadata.startsWith('g');
                }

                if (tireFitmentIDRear.value) {
                    fitmentIDs.push(tireFitmentIDRear.value);
                    tireFitmentDescriptionRearIsGuess = tireFitmentIDRear.metadata.startsWith('g');
                }

                /** @type {ridestyler.Descriptions.TireFitmentDescriptionModel[]} */
                const tireFitmentDescriptions = await RideStylerAPIRequest('Tire/GetFitmentDescriptions', { TireFitments: fitmentIDs }, 'Fitments');

                for ( const tireFitmentDescription of tireFitmentDescriptions) {
                    if (compareGUIDs(tireFitmentDescription.TireFitmentID, tireFitmentIDFront.value)) tireFitmentDescriptionFront = tireFitmentDescription
                    if (compareGUIDs(tireFitmentDescription.TireFitmentID, tireFitmentIDRear.value)) tireFitmentDescriptionRear = tireFitmentDescription
                }
            }

            // The API couldn't match the fitment IDs, do nothing
            if (!tireFitmentDescriptionFront && !tireFitmentDescriptionRear) return;

            // Fill in front or rear fitment from the other fitment position if missing
            if (tireFitmentDescriptionFront && !tireFitmentDescriptionRear) useFrontFitment();
            else if (tireFitmentDescriptionRear && !tireFitmentDescriptionFront) useRearFitment();

            // Grab a model ID from the front fitment, this should match the rear fitment
            const modelID = tireFitmentDescriptionFront.TireFitment_TireModelID;

            // If the model IDs don't match, we're going to ignore the rear fitment and use the front for both
            // After this point we will always have a front/rear fitment description
            if (tireFitmentDescriptionRear.TireFitment_TireModelID !== modelID) useFrontFitment();

            const state = store.state;

            if (!state.selectedTireModel || compareGUIDs(state.selectedTireModel.TireFitment_TireModelID, modelID)) {
                /** @type {ridestyler.Descriptions.TireModelDescriptionModel} */
                const tireModel = (await RideStylerAPIRequest('Tire/GetModelDescriptions', { TireModel: modelID, IncludeFitments: true }, 'Models'))[0];
                tireModel.type = 'tire';

                await store.dispatch('selectTireModel', {
                    tireModel: tireModel
                });
            }

            // Fill in the selected fitment, or state values based on whether or not the fitments were a guess
            {
                if (tireFitmentDescriptionFrontIsGuess) {
                    // TODO: We should be setting our best guess parameters for tires here instead of always setting the user's selection
                    store.commit('setSelectedTireFitmentFront', tireFitmentDescriptionFront);
                } else {
                    store.commit('setSelectedTireFitmentFront', tireFitmentDescriptionFront);
                }

                if (tireFitmentDescriptionRearIsGuess) {
                    // TODO: We should be setting our best guess parameters for tires here instead of always setting the user's selection
                    store.commit('setSelectedTireFitmentRear', tireFitmentDescriptionRear);
                } else {
                    store.commit('setSelectedTireFitmentRear', tireFitmentDescriptionRear);
                }
            }
        }
    },
    {
        keys: ['vang'],
        options:['rotateVehicle'],
        buildState: (options) => {
            return {vang: options.rotateVehicle};
        },
        action: async (persistedState, store) => {
            /** @type {import('./state').VehicleAngle} */
            const vehicleRotation = persistedState.vang.toLowerCase()[0] === 'a' ? 'angle' : 'side';

            if (store.state.vehicleRotation !== vehicleRotation) {
                store.commit('rotateVehicle', vehicleRotation);
            }
        }
    }
];

/**
 * @returns {PersistedState}
 */
function getSavedState() {
    let model;

    switch (Settings.options.persistenceMode) {
        case "localStorage":
            model = window.localStorage.getItem('RsPersistedState') || "";
            break;
        case "none":
            model = ""
            break;
        default:
        case "hash":
            model = window.location.hash.substr(1) || "";
            break;
    }

    return parseQueryString(model);
}

/**
 * @param {RideStylerShowcaseState} state
 * @param {RideStylerShowcaseGetters} getters
 */
function generateState(state, getters) {
    /** @type {PersistedState} */
    const publishedState = {};

    if (state.vehicleConfiguration) publishedState.vc = state.vehicleConfiguration;
    if (state.tireOption) publishedState.to = state.tireOption;

    // Suspension
    {
        const suspensionFront = state.suspensionFront;
        const suspensionRear = state.suspensionRear;

        if (suspensionFront === suspensionRear) {
            if (suspensionFront) publishedState.s = suspensionFront;
        } else {
            if (suspensionFront) publishedState.sf = suspensionFront;
            if (suspensionRear)  publishedState.sr = suspensionRear;
        }
    }

    if (state.selectedWheelFitmentFront) publishedState.wff = state.selectedWheelFitmentFront.WheelFitmentID;
    else if (getters.presumedWheelFitmentFront) publishedState.wff = formatMetadata(getters.presumedWheelFitmentFront.WheelFitmentID, 'g');

    if (state.selectedWheelFitmentRear) publishedState.wfr = state.selectedWheelFitmentRear.WheelFitmentID;
    else if (getters.presumedWheelFitmentRear) publishedState.wfr = formatMetadata(getters.presumedWheelFitmentRear.WheelFitmentID, 'g');

    if (state.selectedTireFitmentFront) publishedState.tff = state.selectedTireFitmentFront.TireFitmentID;
    else if (getters.presumedTireFitmentFront) publishedState.tff = formatMetadata(getters.presumedTireFitmentFront.TireFitmentID, 'g');

    if (state.selectedTireFitmentRear) publishedState.tfr = state.selectedTireFitmentRear.TireFitmentID;
    else if (getters.presumedTireFitmentRear) publishedState.tfr = formatMetadata(getters.presumedTireFitmentRear.TireFitmentID, 'g');

    if (state.paintColor) publishedState.pc = state.paintColor;

    if (state.vehicleRotation) publishedState.vang = state.vehicleRotation.substr(0, 1);

    return publishedState;
}

async function restoreSavedState() {
    const persistedState = getSavedState();

    var options = store.getters.settings.options;
    
    for (let i = 0; i < routines.length; i++) {
        const routine = routines[i];
        const matchesKeys = !routine.keys || routine.keys.length == 0 || routine.keys.some(key => key in persistedState);
        const matchesOptions = options && routine.options.some(key=> key in options && options[key]);

        if( matchesOptions) {
            await routine.action(routine.buildState(options), store);
        }
        else if (matchesKeys) {
            await routine.action(persistedState, store);
        }
    }

    if (persistedState.to && persistedState.vc || options.tireOption && options.vehicleConfiguration) {
        store.commit('goToPage', 'visualize');
        //store.commit('showDisclaimerModal', true);
    }
}

function watchState() {
    store.watch(generateState, function (persistedState) {
        if(Settings.options.persistenceMode === "localStorage"){
            window.localStorage.setItem('RsPersistedState', ridestyler.utils.toParamString(persistedState));
        } else {
            window.location.hash = ridestyler.utils.toParamString(persistedState);
        }
    });
}

export async function initialize(){
    await restoreSavedState();
    watchState();
}
