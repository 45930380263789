<template>
    <Modal class="ridestyler-showcase-order-details-modal" aria-label="Filters" v-bind:close-callback="onClose">
        <h2 class="ridestyler-showcase-order-details-title ridestyler-showcase-modal-title">{{ title || "The following products will be added to your cart:" }}</h2>
        <div class="ridestyler-showcase-order-details-order-wrapper">
            <OrderDetailsTable v-bind:order="order" />
        </div>
        
        <button type="button" name="button" class="ridestyler-showcase-order-details-confirm ridestyler-showcase-button ridestyler-showcase-button-large" v-on:click="confirmOrder">{{ buttonText || "Add to Cart" }}</button>
    
        <p v-if="hasPricing" class="ridestyler-showcase-order-details-disclaimer">*Actual prices may vary, please contact the store for final prices.</p>
    </Modal>
</template>

<script>
import Modal from './Modal';
import OrderDetailsTable from './OrderDetailsTable';
import { mapActions } from 'vuex';
import settings from '../Settings';

export default {
    data: function(){
        return {
            defaultQTY: 4,
            order: this.$store.state.orderdetails.data,
            whenFinished: this.$store.state.orderdetails.whenFinished,
            title: this.$store.state.orderdetails.modalTitle,
            buttonText: this.$store.state.orderdetails.modalButton
        }
    },
    methods: {
        onClose() {
            this.closeModal();
        },
        confirmOrder(){
            this.checkProductsHaveQuantities();
            this.whenFinished(this.order);
            this.closeModal();
        },
        checkProductsHaveQuantities(){
            let productKey;

            for(let product in this.order){
                productKey = product;
                product = this.order[productKey];
                if(this.order[productKey].Quantity == undefined){
                    this.order[productKey].Quantity = this.defaultQTY;
                }
            }
        },
        ...mapActions({
            closeModal: 'orderdetails/hideModal',
        })
    },
    computed: {
        hasPricing() {
            return settings.showPricing;
        }
    },
    components: {
        Modal,
        OrderDetailsTable
    }
}
</script>

<style lang="scss">
    .ridestyler-showcase {
        &.ridestyler-showcase-page-visualize {
            .ridestyler-showcase-order-details-modal {
                .ridestyler-showcase-order-details-disclaimer {
                    padding-top: 2em;
                    font-style: italic;
                }
               /* &.ridestyler-showcase-modal {
                    button {
                        //margin: 10px 0px 0px;
                    }
                }*/
            }
        }
    }
</style>
