<template>
    <Modal class="ridestyler-showcase-package-confirm-modal" aria-label="Filters" v-bind:close-callback="onClose" v-if="isVisible">
        <h2 class="ridestyler-showcase-package-confirm-title ridestyler-showcase-modal-title">Would you like to continue with your selected wheel and tire package?</h2>
        <button v-on:click="confirm(true)" type="button" name="button" class="ridestyler-showcase-button ridestyler-showcase-button-large">Yes</button>
        <button v-on:click="confirm(false)" type="button" name="button" class="ridestyler-showcase-button ridestyler-showcase-button-large">No, just the {{ productType.charAt(0).toLowerCase() + productType.substr(1, productType.length) }}</button>
    </Modal>
</template>

<script>
import Modal from './Modal';
import { mapActions } from 'vuex';

export default {
    data: function(){
        return {
            isVisible: this.$store.state.packageconfirmation.isVisible,
            defaultQTY: 4,
            product: this.$store.state.packageconfirmation.data,
            whenFinished: this.$store.state.packageconfirmation.whenFinished,
            visualizedWheel: this.$store.getters.presumedWheelFitmentFront,
            visualizedTire: this.$store.getters.presumedTireFitmentFront,
            order: {}
        }
    },
    mounted(){
        if(this.visualizedWheel !== undefined && this.visualizedTire !== undefined){
            this.isVisible = true;
        } else {
            this.order[this.productType] = this.product;
            this.whenFinished(this.order);
            this.closeModal();
        }
    },
    methods: {
        onClose() {
            this.closeModal();
        },
        confirm(answer){
            if(answer){
                if(this.productType === "Tire"){
                    this.order["Wheel"] = this.visualizedWheel;
                } else {
                    this.order["Tire"] = this.visualizedTire;
                }
            }

            this.order[this.productType] = this.product;
            this.whenFinished(this.order);
            this.closeModal();
        },
        ...mapActions({
            closeModal: 'packageconfirmation/hideModal',
        })
    },
    computed: {
        productType() {
            let type = null;

            if(this.product.BoltPattern){
                type = "Wheel";
            } else {
                type = "Tire";
            }

            return type;
        }
    },
    components: {
        Modal
    }
}
</script>

<style lang="scss">
    .ridestyler-showcase {
        &.ridestyler-showcase-page-visualize {
            .ridestyler-showcase-package-confirm-modal {
                &.ridestyler-showcase-modal {
                    h2 {
                        text-align: center;
                    }
                    button.ridestyler-showcase-button-large {
                        margin: 15px 20px 0px;
                    }
                }
            }

            //sm
            @media screen and (min-width: 668px) and (max-width: 992px){
                .ridestyler-showcase-modal {
                    &.ridestyler-showcase-package-confirm-modal {
                        .ridestyler-showcase-modal-wrapper {
                            .ridestyler-showcase-modal-content {
                                height: auto;
                            }
                        }
                    }
                }
            }

            //xs
            @media screen and (max-width: 667px){
                .ridestyler-showcase-modal {
                    &.ridestyler-showcase-package-confirm-modal {
                        .ridestyler-showcase-modal-wrapper {
                            .ridestyler-showcase-modal-content {
                                height: auto;
                            }
                        }
                    }
                }
            }

            //portrait md
            @media screen and (min-width: 993px) and (max-width: 1200px) and (orientation: portrait){
                .ridestyler-showcase-modal {
                    &.ridestyler-showcase-package-confirm-modal {
                        .ridestyler-showcase-modal-wrapper {
                            .ridestyler-showcase-modal-content {
                                height: auto;
                            }
                        }
                    }
                }
            }

            //portrait sm
            @media screen and (min-width: 668px) and (max-width: 992px) and (orientation: portrait){
                .ridestyler-showcase-modal {
                    &.ridestyler-showcase-package-confirm-modal {
                        .ridestyler-showcase-modal-wrapper {
                            .ridestyler-showcase-modal-content {
                                height: auto;
                            }
                        }
                    }
                }
            }

            //portrait xs
            @media screen and (max-width: 667px) and (orientation: portrait){
                .ridestyler-showcase-modal {
                    &.ridestyler-showcase-package-confirm-modal {
                        .ridestyler-showcase-modal-wrapper {
                            .ridestyler-showcase-modal-content {
                                height: auto;
                            }
                        }
                    }
                }
            }

            //short lg
            @media screen and (min-width: 1201px) and (orientation: landscape){
                .ridestyler-showcase-modal {
                    &.ridestyler-showcase-package-confirm-modal {
                        .ridestyler-showcase-modal-wrapper {
                            .ridestyler-showcase-modal-content {
                                text-align: center;
                                height: auto;
                            }
                        }
                    }
                }
            }

            //short md
            @media screen and (min-width: 993px) and (max-width: 1200px) and (orientation: landscape){
                .ridestyler-showcase-modal {
                    &.ridestyler-showcase-package-confirm-modal {
                        .ridestyler-showcase-modal-wrapper {
                            .ridestyler-showcase-modal-content {
                                text-align: center;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
