/**
 * 
 * @param {string} action 
 * @param {any} data 
 * @param {string} [responseKey] 
 */
function RideStylerAPIRequest(action, data, responseKey) {
    let xhr = {};

    const promise = new Promise((resolve, reject) => {
        xhr = ridestyler.ajax.send({
            action: action,
            data: data,
            callback: response => {
                if (!response.Success) return reject(response.Message);

                if (responseKey) {
                    if (responseKey in response === false) return reject("Missing '" + responseKey + "' key from response");
                    
                    return resolve(response[responseKey]);
                }

                return resolve(response);
            }
        })
    });

    promise.abort = function () {
        try {
            xhr.abort();
        } catch(e) {
            // Ignore errors aborting XHR
        }
    };

    return promise;
}



export default RideStylerAPIRequest;