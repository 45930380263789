<template>
<div class="ridestyler-showcase-page" role="region" aria-label="Home">
    <div v-if="customIntro" v-html="customIntro" class="ridestyler-showcase-custom-intro"></div>
    <template v-else>
        <div class="ridestyler-showcase-welcome-message" v-html="translate('startMessage')">
            
        </div>
        <div class="ridestyler-showcase-home-bullets">
            <div class="ridestyler-showcase-home-bullet active"><div>1</div><span>Choose your <br v-if="!isPortrait" /> vehicle</span></div>
            <div class="ridestyler-showcase-home-bullet"><div>2</div><span>Shop &amp; visualize <br v-if="!isPortrait" /> wheels</span></div>
            <div class="ridestyler-showcase-home-bullet"><div>3</div><span>Take action!</span></div>
        </div>
    </template>

    <div class="ridestyler-showcase-get-started">
        <button type="button" id="ridestyler-showcase-select-your-vehicle" class="ridestyler-showcase-button ridestyler-showcase-button-large" v-on:click="selectVehicle">
            {{translate("startButton")}}
        </button>
    </div>

    <div id="ridestyler-showcase-vehicle-selection-wheel-showcase" :class="wheelShowcaseClasses" v-if="featuredWheels" aria-hidden="true">
        <LoadingIndicator v-if="loadingFeaturedWheels" />
        <ProductImage class="ridestyler-showcase-vehicle-selection-featured-wheel" v-for="wheel in featuredWheels" v-bind:product="wheel" v-bind:key="wheel.WheelModelID" alt="" />
    </div>

    <PoweredByRideStyler v-if="$showcaseSettings.poweredByMessage === true" />

    <div class="ridestyler-showcase-back-panel"></div>
</div>
</template>

<script>
import LoadingIndicator from './LoadingIndicator';
import PoweredByRideStyler from './PoweredByRideStyler.vue';
import ProductImage from './ProductImage';

export default {
  data: function() {
    return {
      featuredWheels: [],
      loadingFeaturedWheels: true
    };
  },
  created: function() {
    this.loadFeaturedWheels();
  },
  components: {
    LoadingIndicator,
    ProductImage,
    PoweredByRideStyler
  },
  methods: {
    selectVehicle() {
      this.$store.commit('goToPage', 'select');
    },
    loadFeaturedWheels() {
      ridestyler.ajax.send({
        action: 'wheel/getmodels',
        data: {
          Count: 3,
          SortRandom: true,
          HasCatalogImage: true
        },
        callback: (response) => {
          this.loadingFeaturedWheels = false;

          if (!response.Success) {
            return;
          }

          this.featuredWheels = response.Models.map(model => {
            return {
              WheelModelID: model.WheelModelID,
              HasCatalogImage: true,
              type: 'wheel'
            };
          });
        }
      })
    }
  },
  computed: {
    wheelShowcaseClasses() {
        let classes = [];
        
        if(this.$showcaseSettings.poweredByMessage === false) classes.push('wheel-showcase-no-powered');

        return classes;
    },
    customIntro() {
      return this.$showcaseSettings.theme.Content ? this.$showcaseSettings.theme.Content['visualizer-custom-intro'] : undefined;
    },
    isPortrait() {
        return this.$store.state.isPortrait;
    }
  }
};
</script>

<style lang="scss">
@keyframes fadeInText {
    from {
        opacity: 0;
        top: 10px;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeInWheels {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fadeInButton {
    from {
        opacity: 0;
        top: 47%;
    }
    to {
        opacity: 1;
    }
}

.ridestyler-showcase {
    $featuredWheelImageSize: 100px;
    .ridestyler-showcase-custom-intro {
        font-size: 1.5em;
        display: block;
        margin: 3.5em 0 0;
        padding: 0 1em;
    }
    &.ridestyler-showcase-page-home {
        .ridestyler-showcase-page {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
        }
        .ridestyler-showcase-welcome-message {
            font-size: 1.5em;
            display: block;
            margin: 3.5em 0 0;
            padding: 0 1em;
        }
        .ridestyler-showcase-home-bullets {
            display: flex;
            flex-direction: row;
            justify-content: center;
            .ridestyler-showcase-home-bullet {
                margin: 0 2em;
                text-align: center;
                font-size: 1.5em;
                line-height: 1.2em;
                div {
                    border-radius: 80px;
                    height: 2.3em; width: 2.3em;
                    line-height: 2.3em;
                    font-size: 0.9em;
                    background-color: darken($secondary-background-color, 20%);
                    color: white;
                    margin: 0 auto 0.5em;
                }
                &.active {
                    font-weight: bold;
                    div {
                        background-color: $secondary-button-color;
                    }
                }
            }
        }
        #ridestyler-showcase-select-your-vehicle.ridestyler-showcase-button-large {
            font-size: 1.7em;
            padding: .3em 2.6em;
            opacity: 1;
            -webkit-transition: 500ms ease-in fadeInButton;
            transition: 500ms ease-in fadeInButton;
            -webkit-animation: 500ms ease-in fadeInButton;
            -moz-animation: 500ms ease-in fadeInButton;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            -moz-animation-fill-mode: forwards;
            -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
        }   
        #ridestyler-showcase-vehicle-selection-wheel-showcase {
            height: 25%;
            text-align: center;
            display: flex;
            flex-direction: row;
            transition: bottom 500ms;
            animation: 500ms ease-in forwards fadeInWheels;
            position: relative;
            align-items: center;
            justify-content: center;

            .ridestyler-showcase-vehicle-selection-featured-wheel {
                width: auto;
                height: 100%;

                + .ridestyler-showcase-vehicle-selection-featured-wheel {
                    margin-left: -15px;
                }
            }
        }
        .ridestyler-showcase-back-panel {
            background: $secondary-background-color;
            position: absolute;
            height: 29%;
            width: 100%;
            bottom: 0;
            z-index: -1;
            text-align: center;
        }
        .wheel-showcase-no-powered {
            margin: 0 auto 7em;
        }
        //portrait
        &.ridestyler-showcase-breakpoint-portrait {
            &.ridestyler-showcase-page-home {
                .ridestyler-showcase-welcome-message {
                    margin: 2em 0 0 0;
                }
                .ridestyler-showcase-home-bullets {
                    flex-direction: column;
                }
                .ridestyler-showcase-home-bullet {
                    align-items: flex-start;
                    margin: 0 auto 7%;
                    width: 14em;
                    display: inline-flex;
                    line-height: 2em;
                    &:last-child {
                        margin: 0 auto 0;
                    }
                    div {
                        display: inline-block;
                        margin: 0 0.8em;
                    }
                    span {
                        white-space: nowrap;
                    }
                }
                #ridestyler-showcase-vehicle-selection-wheel-showcase {
                    height: 15%;
                }
            }
            &.ridestyler-showcase-breakpoint-xs {
                &.ridestyler-showcase-page-home {
                    .ridestyler-showcase-welcome-message {
                        padding: 0 2em;
                    }
                }
            }
            &.ridestyler-showcase-breakpoint-sm {
                &.ridestyler-showcase-page-home {
                    .ridestyler-showcase-welcome-message {
                        padding: 0 4em;
                    }
                }
            }
            &.ridestyler-showcase-breakpoint-md {
                &.ridestyler-showcase-page-home {
                    .ridestyler-showcase-welcome-message {
                        padding: 0 4em;
                    }
                }
            }
        }
        //end portrait
    }
}
</style>
