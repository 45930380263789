import * as Products from "../Products";

import settings from "../Settings";

/**
 * @typedef RideStylerShowcaseGetters
 * @prop {boolean} hasAccessToVisualizer Whether or not the user should be able to view this visualizer
 * @prop {string} vehicleDescriptionText The full description of the selected vehicle
 * @prop {string} selectedWheelModelDisplayName The full name of the selected wheel model including brand name
 * @prop {string} selectedWheelBrandName The brand name of the selected wheel model 
 * @prop {string} selectedWheelModelName The name of the selected wheel model 
 * @prop {string} selectedTireModelDisplayName The full name of the selected tire model including brand name
 * @prop {string} selectedTireBrandName The brand name of the selected tire model 
 * @prop {string} selectedTireModelName The name of the selected tire model 
 * @prop {boolean} hasWheelModelSelected Whether or not a wheel is selected
 * @prop {boolean} hasTireModelSelected Whether or not a tire is selected
 * @prop {boolean} canRotateVehicle Whether or not the vehicle can be rotated
 * @prop {ridestyler.Requests.WheelFilterModel} computedWheelFilters Wheel filters computed off of the current state
 * @prop {ridestyler.Requests.WheelFilterModel} wheelFilters Wheel filters composited from the state and user selections
 * @prop {ridestyler.Requests.TireFilterModel} computedTireFilters Tire filters computed off of the current state
 * @prop {ridestyler.Requests.TireFilterModel} tireFilters Tire filters composited from the state and user selections
 * @prop {boolean} userHasProductSelections Whether or not the user has a product selected
 * @prop {ridestyler.Requests.VehicleRenderInstructions} currentVehicleRenderInstructions Render instructions for visualizing the current vehicle
 * @prop {string[]} currentVehicleRenderNotices Notices to display alongside the vehicle
 * @prop {number} activeWheelDiameterFront The active front wheel diameter
 * @prop {number} activeWheelDiameterRear The active rear wheel diameter
 * @prop {number} activeWheelDiameterForCurrentPosition The active wheel diameter for the active fitment position
 * @prop {number[]} availableWheelDiametersFront The available wheel diameters for the front fitment position
 * @prop {number[]} availableWheelDiametersRear The available wheel diameters for the rear fitment position
 * @prop {number[]} availableWheelDiametersForCurrentPosition The available wheel diameters for the active fitment position
 * @prop {ridestyler.Descriptions.WheelFitmentDescriptionModel} presumedWheelFitmentFront The best wheel fitment for the front of the vehicle (used for visualization)
 * @prop {ridestyler.Descriptions.WheelFitmentDescriptionModel} presumedWheelFitmentRear The best wheel fitment for the rear of the vehicle (used for visualization)
 * @prop {ridestyler.Descriptions.TireFitmentDescriptionModel} presumedTireFitmentFront The best tire fitment for the front of the vehicle (used for visualization)
 * @prop {ridestyler.Descriptions.TireFitmentDescriptionModel} presumedTireFitmentRear The best tire fitment for the rear of the vehicle (used for visualization)
 * @prop {ridestyler.Descriptions.WheelFitmentDescriptionModel[]} workingWheelFitmentSetFront The active set of wheel fitments matching the current state for the front of the vehicle
 * @prop {ridestyler.Descriptions.TireFitmentDescriptionModel[]} workingTireFitmentSetFront The active set of tire fitments matching the current state for the front of the vehicle
 * @prop {ridestyler.Descriptions.WheelFitmentDescriptionModel[]} workingWheelFitmentSetRear The active set of wheel fitments matching the current state for the rear of the vehicle
 * @prop {ridestyler.Descriptions.TireFitmentDescriptionModel[]} workingTireFitmentSetRear The active set of tire fitments matching the current state for the rear of the vehicle
 * @prop {boolean} frontTireCanBeVisualized Whether or not the front tire is able to be visualized on the current vehicle
 * @prop {boolean} rearTireCanBeVisualized Whether or not the rear tire is able to be visualized on the current vehicle
 */

/**
 * @typedef {import("./state").VehicleAngle} VehicleAngle
 */

/** @type {{[key:string]:(state: import("./state").RideStylerShowcaseState, getters: RideStylerShowcaseGetters)=>any}} */
const getters = {
    // #region Vehicle
        hasAccessToVisualizer (state) {
            return state.subscription && state.subscription.IncludedProducts && state.subscription.IncludedProducts.includes('visualizer');
        },
        vehicleDescriptionText (state) {
            if (!state.vehicleDescription) return undefined;
            return state.vehicleDescription.FullDescription;
        },

        canRotateVehicle (state, getters) {
            if (settings.disableAngledVehicles) return false;

            const vehicle = state.vehicleDescription;
            const {
                presumedWheelFitmentFront: wheelFront,
                presumedWheelFitmentRear: wheelRear
            } = getters;

            const canRotateVehicle = !!vehicle && vehicle.HasSideImage && vehicle.HasAngledImage; // Vehicle is rotatable
            const canRotateFrontWheel = !wheelFront || wheelFront.HasSideImage && wheelFront.HasAngleImage; // Front wheel is missing or rotatable
            const canRotateRearWheel = !wheelRear || wheelRear.HasSideImage && wheelRear.HasAngleImage; // Rear wheel is missing or rotatable

            return canRotateVehicle && canRotateFrontWheel && canRotateRearWheel;
        },
        
        currentVehicleRenderInstructions (state, getters) {
            /** @type {ridestyler.Requests.VehicleRenderInstructions} */
            const instructions = {
                VehicleConfiguration: state.vehicleConfiguration,
                SuspensionFront: state.suspensionFront,
                SuspensionRear: state.suspensionRear,
                PositionX: 1,
                PositionY: 2,
                Type: state.vehicleRotation === 'side' ? 'Side' : state.vehicleRotation === 'angle' ? 'Angle' : undefined,
                WheelFitmentFront: getters.presumedWheelFitmentFront && getters.presumedWheelFitmentFront.WheelFitmentID || undefined,
                WheelFitmentRear: getters.presumedWheelFitmentRear && getters.presumedWheelFitmentRear.WheelFitmentID || undefined,
                TireFitmentFront: getters.presumedTireFitmentFront && getters.presumedTireFitmentFront.TireFitmentID || undefined,
                TireFitmentRear: getters.presumedTireFitmentRear && getters.presumedTireFitmentRear.TireFitmentID || undefined,
                PaintColor: state.paintColor
                
            };
            
            if( getters.presumedWheelFitmentFront && getters.presumedWheelFitmentFront.DiameterMin != getters.presumedWheelFitmentFront.DiameterMax )
            {
                instructions.wheeldiameterfront = getters.activeWheelDiameterFront;
            }
            if( getters.presumedWheelFitmentRear && getters.presumedWheelFitmentRear.DiameterMin != getters.presumedWheelFitmentRear.DiameterMax )
            {
                instructions.wheeldiameterrear = getters.activeWheelDiameterRear;
            }
            
            return instructions;
        },
        currentVehicleRenderNotices (state, getters) {
            const notices = [];

            if (getters.presumedTireFitmentFront || getters.presumedTireFitmentRear) {
                if (!getters.frontTireCanBeVisualized && !getters.rearTireCanBeVisualized)
                    notices.push("The selected tire is unavailable for visualization.");
                else if (state.vehicleRotation !== 'side') 
                    notices.push("The selected tire is unavailable for visualization at this angle.")
                else if (state.selectedTireModel.HasSideImage && !state.selectedWheelModel.HasSideImage || !state.selectedTireModel.HasSideImage && state.selectedWheelModel.HasSideImage)
                    notices.push("The selected wheel is unavailable for visualization with the selected tire.")
            }
    
            return notices;
        },

    // #endregion

    // #region Tires
    
        selectedTireModelDisplayName (state) {
            if (!state.selectedTireModel) return undefined;

            return state.selectedTireModel.TireBrandName + ' ' + state.selectedTireModel.TireModelName;
        },
        selectedTireBrandName (state) {
            if (!state.selectedTireModel) return undefined;

            return state.selectedTireModel.TireBrandName;
        },
        selectedTireModelName (state) {
            if (!state.selectedTireModel) return undefined;

            return state.selectedTireModel.TireModelName;
        },
        hasTireModelSelected (state) { return !!state.selectedTireModel; },
        workingTireFitmentSetFront (state) {
            return state.availableTireFitmentsFront;
        },
        workingTireFitmentSetRear (state) {
            return state.availableTireFitmentsRear;
        },
        presumedTireFitmentFront(state, getters) {
            if (state.selectedTireFitmentFront) return state.selectedTireFitmentFront;
            if (getters.workingTireFitmentSetFront && getters.workingTireFitmentSetFront.length) return getters.workingTireFitmentSetFront[0];
        },
        presumedTireFitmentRear(state, getters) {
            if (state.selectedTireFitmentRear) return state.selectedTireFitmentRear;
            if (getters.workingTireFitmentSetRear && getters.workingTireFitmentSetRear.length) return getters.workingTireFitmentSetRear[0];
        },
        frontTireCanBeVisualized(state, getters) {
            return state.vehicleDescription && state.vehicleDescription.HasSideImage && getters.presumedTireFitmentFront && getters.presumedTireFitmentFront.HasRenderSideImage;
        },
        rearTireCanBeVisualized(state, getters) {
            return state.vehicleDescription && state.vehicleDescription.HasSideImage &&  getters.presumedTireFitmentRear && getters.presumedTireFitmentRear.HasRenderSideImage;
        },
        computedTireFilters(state, getters) {
            /** @type {ridestyler.Requests.TireFilterModel} */
            const filters = {
                VehicleTireOption: state.tireOption,
                HasCatalogImage: true,
                IncludePricing: settings.showPricing
            };
    
            let wheelFitment;

            if (state.fitmentPosition === 'rear') wheelFitment = getters.presumedWheelFitmentRear;
            else wheelFitment = getters.presumedWheelFitmentFront;
    
            if (wheelFitment) {
                const wheelDiameter = wheelFitment.DiameterMin;
                const wheelWidth = wheelFitment.WidthMin;
    
                filters.Recommend = {
                    NewWheelDiameter: wheelDiameter,
                    NewWheelWidth: wheelWidth,
                    VehicleTireOption: state.tireOption
                };
            }
    
            return filters;
        },
        tireFilters(state, getters) {
            /** @type {ridestyler.Requests.TireFilterModel} */
            const tireFilters = Object.assign({}, getters.computedTireFilters, state.selectedTireFilters);
    
            if (tireFilters.Recommend) tireFilters.Recommend = Object.assign({}, getters.computedTireFilters.Recommend, state.selectedTireFilters.Recommend);
    
            return tireFilters;
        },

    // #endregion

    // #region Wheels

        selectedWheelModelDisplayName (state) {
            if (!state.selectedWheelModel) return undefined;

            return state.selectedWheelModel.WheelBrandName + ' ' + state.selectedWheelModel.WheelModelName;
        },
        selectedWheelBrandName (state) {
            if (!state.selectedWheelModel) return undefined;

            return state.selectedWheelModel.WheelBrandName;
        },
        selectedWheelModelName (state) {
            if (!state.selectedWheelModel) return undefined;

            return state.selectedWheelModel.WheelModelName;
        },
        hasWheelModelSelected (state) { return !!state.selectedWheelModel; },

        /** @return {ridestyler.Requests.WheelFilterModel} */
        computedWheelFilters (state, getters) {
            /** @type {ridestyler.Requests.WheelFilterModel} */
            
            const filters = {
                HasCatalogImage: true,
                IncludePricing: settings.showPricing
            };

            if( getters.settings.options.showPartNumbers && 
                Array.isArray(getters.settings.options.showPartNumbers) && 
                getters.settings.options.showPartNumbers.length > 0)
            {
                if( getters.settings.options.partNumbersAreInventoryNumbers )
                    filters["ItemNumbers"] = getters.settings.options.showPartNumbers;
                else
                    filters["partNumbers"] = getters.settings.options.showPartNumbers;
            } else {
                filters["VehicleConfiguration"] = state.vehicleConfiguration;
            }
            
            if (state.vehicleDescription) {
                const sideVehicleViewSupported = state.vehicleDescription.HasSideImage;
                const angleVehicleViewSupported = !settings.disableAngledVehicles && state.vehicleDescription.HasAngledImage;
                const wheelFitmentResourceTypes = [];

                if (sideVehicleViewSupported) wheelFitmentResourceTypes.push('Side');
                if (angleVehicleViewSupported) wheelFitmentResourceTypes.push('Angled');

                filters.FitmentResourceTypes = wheelFitmentResourceTypes;
            }
            return filters;
        },

        /** @return {ridestyler.Requests.WheelFilterModel} */
        wheelFilters (state, getters) {
            return Object.assign({}, getters.computedWheelFilters, state.selectedWheelFilters);
        },

        activeWheelDiameterFront(state, getters) {
            return getters.availableWheelDiametersFront.includes(state.lastSelectedDiameterFront) ? state.lastSelectedDiameterFront : getters.availableWheelDiametersFront[0];
        },
    
        activeWheelDiameterRear(state, getters) {
            return getters.availableWheelDiametersRear.includes(state.lastSelectedDiameterRear) ? state.lastSelectedDiameterRear : getters.availableWheelDiametersRear[0];
        },

        activeWheelDiameterForCurrentPosition(state, getters) {
            return state.fitmentPosition === 'rear' ? getters.activeWheelDiameterRear : getters.activeWheelDiameterFront;
        },

        availableWheelDiametersFront(state) {
            return Products.Wheel.getUniqueDiameters(state.availableWheelFitmentsFront);
        },

        availableWheelDiametersRear(state) {
            return Products.Wheel.getUniqueDiameters(state.availableWheelFitmentsRear);
        },

        availableWheelDiametersForCurrentPosition(state, getters) {
           
            if( state.fitmentPosition === 'rear') {
                if( getters.presumedWheelFitmentRear && getters.presumedWheelFitmentRear.DiameterMin != getters.presumedWheelFitmentRear.DiameterMax )
                {
                    //filter our list
                    var list = [];
                    for(var i = 0; i<getters.availableWheelDiametersRear.length; i++) {
                        if( getters.availableWheelDiametersRear[i] >= state.vehicleFitmentDiameterMin && getters.availableWheelDiametersRear[i] <= state.vehicleFitmentDiameterMax)
                            list.push(getters.availableWheelDiametersRear[i]);
                    }
                    return list;
                }
                return getters.availableWheelDiametersRear;
            }
            else {
                if( getters.presumedWheelFitmentFront && getters.presumedWheelFitmentFront.DiameterMin != getters.presumedWheelFitmentFront.DiameterMax )
                {
                    var list1 = [];
                    for(var i1 = 0; i1<getters.availableWheelDiametersRear.length; i1++) {
                        if( getters.availableWheelDiametersRear[i1] >= state.vehicleFitmentDiameterMin && getters.availableWheelDiametersRear[i1] <= state.vehicleFitmentDiameterMax)
                            list1.push(getters.availableWheelDiametersRear[i1]);
                    }
                    return list1;
                }

                return getters.availableWheelDiametersFront;
            }


        },

        workingWheelFitmentSetFront (state, getters) {
            /** @type {Products.WheelFitmentMatchesFilters} */
            const filters = {
                diameter: getters.activeWheelDiameterFront
            };
    
            return state.availableWheelFitmentsFront.filter(fitment => {
                const matches = Products.Wheel.fitmentMatches(fitment, filters);
                
                return matches.diameter;
            });
        },
        workingWheelFitmentSetRear (state, getters) {
            /** @type {Products.WheelFitmentMatchesFilters} */
            const filters = {
                diameter: getters.activeWheelDiameterRear
            };
    
            return state.availableWheelFitmentsRear.filter(fitment => {
                const matches = Products.Wheel.fitmentMatches(fitment, filters);
                
                return matches.diameter;
            });
        },
        presumedWheelFitmentFront(state, getters) {
            if (state.selectedWheelFitmentFront) return state.selectedWheelFitmentFront;
            if (getters.workingWheelFitmentSetFront && getters.workingWheelFitmentSetFront.length) return getters.workingWheelFitmentSetFront[0];
        },
        presumedWheelFitmentRear(state, getters) {
            if (state.selectedWheelFitmentRear) return state.selectedWheelFitmentRear;
            if (getters.workingWheelFitmentSetRear && getters.workingWheelFitmentSetRear.length) return getters.workingWheelFitmentSetRear[0];
        },

    // #endregion

    settings () {
        return settings;
    },

    userHasProductSelections (state, getters) {
        return getters.hasWheelModelSelected;
    },
};

export default getters;
