<template>
    <div class="ridestyler-showcase-popover">
        <slot />

        <transition name="fade">
            <div
                class="ridestyler-showcase-popover-content"
                v-bind:id="id"
                v-bind:aria-expanded="isOpen ? 'true' : 'false'"
                v-show="isOpen">
                    <slot name="popover" />
            </div>
        </transition>
    </div>
</template>

<script>
let popoverID = 0;

export default {
    props: {
        isOpen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        this.id = 'ridestyler-showcase-popover-' + popoverID++;
    },
    methods: {
        /**
         * @param {MouseEvent} e
         */
        onBodyClick(e) {
            if (this.$el.contains(e.target)) return;

            this.setIsOpen(false);
        },

        setIsOpen(isOpen) {
            this.$emit('update:isOpen', isOpen);
        }
    },
    watch: {
        isOpen(isOpen) {
            if (isOpen) document.body.addEventListener('click', this.onBodyClick);
            else document.body.removeEventListener('click', this.onBodyClick);
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-popover {
        position: relative;
        display: inline-grid;

        .ridestyler-showcase-share-button-networks button.ridestyler-showcase-button {
          top: 2px;
          position: relative;
          padding: 2px 6px;
          max-height: 27px;
          min-height: 27px;
          line-height: 0.5em;
          vertical-align: middle;
          margin: 0 .8em;
          outline: none;
        }
    }

    .ridestyler-showcase-popover-content {
        position: absolute;
        background-color: #eaedf0;
        padding: 0.5em;
        width: auto;
        z-index: 102;
        border-radius: 6px;

        &:before {
            position: absolute;
            z-index: -1;
            content: "";
            right: calc(50% - 10px);
            top: -8px;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent #eaedf0 transparent;
        }
    }
}
</style>
