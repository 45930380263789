<template>
    <ButtonPicker class="ridestyler-showcase-size-picker" v-bind:options="diameters" v-bind:selectedValue="selectedDiameter" v-on:change="onChange" />
</template>

<script>
import ButtonPicker from './ButtonPicker';
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
        ButtonPicker
    },
    computed: {
        diameters() {
            
            if( !this.$store.state.lastSelectedDiameterFront ){
                this.$store.commit('selectDiameter', this.availableWheelDiametersForCurrentPosition[0]);
            }

            return this.availableWheelDiametersForCurrentPosition.map(diameter => {
                return {
                    label: diameter + '&rdquo;',
                    value: diameter
                }
            });
        },

        selectedDiameter() {
            return this.activeWheelDiameterForCurrentPosition;
        },

        ...mapState([
            'availableWheelFitments',
            'visualizedWheelFitment'
        ]),

        ...mapGetters([
            'activeWheelDiameterForCurrentPosition',
            'availableWheelDiametersForCurrentPosition'
        ])
    },
    methods: {
        onChange(diameter) {
            if (diameter === this.selectedDiameter) return;

            this.$store.commit('selectDiameter', diameter);
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-size-picker {
        position: relative; 
        padding-bottom: 1rem;
    }
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-size-picker {

        }
        &.ridestyler-showcase-page-visualize.ridestyler-showcase-breakpoint-lg {

        }
        &.ridestyler-showcase-page-visualize.ridestyler-showcase-breakpoint-md {

        }
        &.ridestyler-showcase-page-visualize.ridestyler-showcase-breakpoint-sm {

        }
        &.ridestyler-showcase-page-visualize.ridestyler-showcase-breakpoint-xs {

        }
    }
    &.ridestyler-showcase-page-visualize.ridestyler-showcase-breakpoint-lg {

    }
}
</style>
