/** @typedef {import("../state").RideStylerShowcaseState} RideStylerShowcaseState */

/**
 * @typedef VisualizeModuleState
 * @prop {boolean} filterModalVisible
 * @prop {boolean} paintModalVisible
 */

/** @type {VisualizeModuleState} */
const state = {
    filterModalVisible: false,
    paintModalVisible: false,
    disclaimerModalVisible: false
};

/**
 * @type {import("../../../node_modules/vuex/types/index").MutationTree<VisualizeModuleState>}
 */
const mutations = {
    showFilterModal(state) { state.filterModalVisible = true; },
    hideFilterModal(state) { state.filterModalVisible = false; },
    showPaintModal(state) { state.paintModalVisible = true; },
    hidePaintModal(state) { state.paintModalVisible = false; },
    hideDisclaimerModal(state) {  state.disclaimerModalVisible = false; },
    showDisclaimerModal(state) {  state.disclaimerModalVisible = true; }
};

export default {
    namespaced: true,
    state,
    mutations
};
