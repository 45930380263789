import state from "./state";

/** @typedef {import('./state').ProductType} ProductType */
/** @typedef {import('./state').VehicleFitmentPosition} VehicleFitmentPosition */

/** @type {import("../../node_modules/vuex/types/index").MutationTree<import("./state").RideStylerShowcaseState>} */
const mutations = {
    // Setup default state setters, these can be overriden below
    // this allows us to commit basic mutations to the state like this:
    // commit('setVehicleDescription', vehicleDescription);
    ... (function () {
        const setters = {};

        for (let k in state) {
            const setterName = 'set' + k[0].toUpperCase() + k.slice(1);

            setters[setterName] = (state, val) => { state[k] = val };
        }

        return setters;
    })(),

    /** @param {ProductType} [type] */
    clearFilters(state, type) {
        if (!type || type === 'wheel') {
            state.selectedWheelFilters = {};
        }

        if (!type || type === 'tire') {
            state.selectedTireFilters = {};
        }
    },

    /**
     * @param {import("./state").ShowcasePage} page
     */
    goToPage(state, page) {
        state.page = page;
        if (page === 'visualize') {
            state.selectedCustomizationTab = 'wheel';
        }
    },

    /**
     * @param {import("./state").ShowcasePage} page
     */
    showDisclaimerModal(state, value) {
          state.visualize.disclaimerModalVisible = value;
    },

    /**
     * @param {import("./state").VehicleAngle} [newAngle]
     */
    rotateVehicle(state, newAngle) {
        if (!newAngle) newAngle = state.vehicleRotation === 'side' ? 'angle' : 'side';

        state.vehicleRotation = newAngle;
    },

    /**
     * @param {import("./state").VehicleSelection} selection
     */
    setSelection(state, selection) {
        
        if (!selection || !selection.vehicleConfiguration) {
            state.tireOption = undefined;
            state.tireOptionDetails = undefined;
            state.paintColor = undefined;
            state.vehicleConfiguration = undefined;
            state.vehicleDescription = undefined;
           
        } else {
            state.vehicleConfiguration = selection.vehicleConfiguration;
            state.tireOption = selection.tireOption.TireOptionID;
            state.paintColor = selection.paintColor;
            state.tireOptionDetails = selection.tireOption;
        }

        state.vehicleRotation = 'side';
        state.suspensionFront = 0;
        state.suspensionRear = 0;

        state.selectedWheelFilters = {};
        state.selectedTireFilters = {};
        state.selectedWheelModel = undefined;
        state.selectedTireModel = undefined;

        state.selectedWheelFitmentFront = undefined;
        state.selectedWheelFitmentRear = undefined;
        state.selectedTireFitmentFront = undefined;
        state.selectedTireFitmentRear = undefined;

        state.availableTireFitmentsFront = [];
        state.availableTireFitmentsRear = [];
        state.availableWheelFitmentsFront = [];
        state.availableWheelFitmentsRear = [];
    },

    /**
     * @param {string|number|{front?:number,rear?:number}} suspension
     */
    setSuspension(state, suspension) {
        switch (typeof suspension) {
            case "string":
            case "number":
                state.suspensionFront = suspension;
                state.suspensionRear = suspension;
                break;
            default:
                if ('front' in suspension) state.suspensionFront = suspension.front;
                if ('rear' in suspension) state.suspensionRear = suspension.rear;
        }
    },

    appendNotification(state, notification) {
        state.notifications.push(notification);
    },

    clearNotification(state, notification) {
        const notificationIndex = state.notifications.findIndex(n => n.type === notification.type && n.text === notification.text)

        if (notificationIndex >= 0) state.notifications.splice(notificationIndex, 1);
    },

    setSelectedWheelModel(state, wheelModel) {
        if (state.selectedWheelModel === wheelModel || state.selectedWheelModel && wheelModel && state.selectedWheelModel.WheelModelID === wheelModel.WheelModelID) {
            return;
        } else {
            state.selectedWheelFitmentFront = undefined;
            state.selectedWheelFitmentRear = undefined;
        }

        state.selectedWheelModel = wheelModel;

        if (!wheelModel) {
            state.availableWheelFitmentsFront = [];
            state.availableWheelFitmentsRear = [];
            state.selectedWheelFitmentFront = undefined;
            state.selectedWheelFitmentRear = undefined;
        }

        // Clear tire filters for package building
        state.selectedTireFilters = {};
    },

    setSelectedTireModel(state, tireModel) {
        if (state.selectedTireModel === tireModel || state.selectedTireModel && tireModel && state.selectedTireModel.TireModelID === tireModel.TireModelID) {
            return;
        } else {
            state.availableTireFitmentsFront = [];
            state.availableTireFitmentsRear = [];
            state.selectedTireFitmentFront = undefined;
            state.selectedTireFitmentRear = undefined;
        }

        state.selectedTireModel = tireModel;

        if (!tireModel) {
            state.availableTireFitmentsFront = [];
            state.availableTireFitmentsRear = [];
            state.selectedTireFitmentFront = undefined;
            state.selectedTireFitmentRear = undefined;
        }
    },

    selectDiameter(state, diameter) {
        if( diameter != state.lastSelectedDiameterFront)
            document.dispatchEvent(new CustomEvent("rs-wheel-diameter-selected", { detail: { diameter:diameter, selectedWheelModel:state.selectedWheelModel }} ));

        const fitmentPosition = state.fitmentPosition;
        if (fitmentPosition === 'front' || fitmentPosition === 'both') state.lastSelectedDiameterFront = diameter;
        if (fitmentPosition === 'rear' || fitmentPosition === 'both') state.lastSelectedDiameterRear = diameter;

        
       

        // Clear the selected tire and wheel fitments when the user changes the diameter selection
        state.selectedTireModel = undefined;
        state.availableTireFitmentsFront = [];
        state.availableTireFitmentsRear = [];
        state.selectedTireFitmentFront = undefined;
        state.selectedTireFitmentRear = undefined;
        state.selectedWheelFitmentFront = undefined;
        state.selectedWheelFitmentRear = undefined;
    },

    updateTranslations(state, translations) {
        state.translations = translations;
    }
};

export default mutations;
