/** @typedef {import("../state").RideStylerShowcaseState} RideStylerShowcaseState */

/**
 * @typedef PackagConfirmationState
 * @prop {boolean} isVisible
 * @prop {object} data
 * @prop {function} whenFinished
 */

/** @type {PackagConfirmationState} */
const state = {
    isVisible: false,
    data: {},
    whenFinished: undefined
};

/**
 * @type {import("vuex").MutationTree<any>}
 */
const mutations = {
    SET_MODAL(state, payload){
        state.isVisible = payload.isVisible;
        state.data = payload.data;
        state.whenFinished = payload.whenFinished;
    },
};

const actions = {
    showModal(context, payload) {
        return new Promise((resolve) => {
            context.commit('SET_MODAL', {
                isVisible: true,
                data: payload.data,
                whenFinished: resolve,
            });
        });
    },
    hideModal(context){ 
        context.commit('SET_MODAL', {
            isVisible: false,
            data: undefined
        })
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
