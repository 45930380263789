<template>
    <div class="ridestyler-showcase-page" id="ridestyler-showcase-unavailable">
        <div class="card">
            <h3>Visualizer Unavailable</h3>
            <p v-if="!visualizerAccess && rsKeyAvailable">The Visualizer is temporarily offline due to an issue with the subscription for this account. Please update your subscription via the <a href="https://account.ridestyler.com" target="_blank">Account Portal</a> to restore access to this feature.</p>
            <p v-if="!visualizerAccess && !rsKeyAvailable">The API key used to access the visualizer is invalid. Please see the <a href="https://account.ridestyler.com/#/products/visualizer/general" target="_blank">Account Portal</a> for instructions on how to set up the link to your visualizer.</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visualizerAccess: Boolean
    },
    computed: {
        rsKeyAvailable(){
            var apiKeyRegex = /^([a-zA-Z0-9\-]{32,36})/g;
            var apiKeyMatch = apiKeyRegex.exec(window.location.pathname.substr(1)) || apiKeyRegex.exec(window.location.search.substr(1));

            return apiKeyMatch != null;
        }
    }
};
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-page#ridestyler-showcase-unavailable {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .card {
            display: inline-block;
            text-align: center;
            background: #e6ebf2;
            border-radius: .4em;
            color: #aeadad;
            padding: 6.4em 3.2em;
            text-align: center;
            position: relative;
            width: 50%;
            margin: 0 auto;
            h3 {
                font-size: 25px;
            }
        }
    }
}
</style>
