<template>
    <aside class="ridestyler-showcase ridestyler-app" v-on:click="globalClick($event)" v-bind:class="showcaseClass" aria-label="RideStyler Showcase">

        <LoadingIndicator v-if="page === 'init'" />

        <template v-else-if="hasAccessToVisualizer">
            <HomePage v-if="page === 'home'" />
            <VehicleSelectionPage v-else-if="page === 'select'" v-on:disclaimer="setDisclaimerSettings" />
            <VehicleVisualizationPage v-else-if="page === 'visualize'" />
        </template>

        <VisualizerUnavailable v-else :visualizerAccess="hasAccessToVisualizer" />

        <transition-group class="ridestyler-showcase-notifications" tag="div" name="fade">
            <div v-bind:class="['ridestyler-showcase-notification', 'ridestyler-showcase-notification-' + notification.type]" v-for="notification in notifications" v-bind:key="notification.key" v-text="notification.text"></div>
        </transition-group>

        <GlobalOverlay v-if="$store.state.globaloverlay.isVisible" />
        <ProductDetailsModal />
        <DisclaimerModal v-on:confirmed="onDisclaimerConfirmed" :canClose="disclaimerSettings!=null" />
        <FilterModal v-if="$store.state.visualize.filterModalVisible" />
        <RefineProductsComponent v-if="$store.state.refineproducts.isVisible" />
        <OrderDetailsComponent v-if="$store.state.orderdetails.isVisible" />
        <PackageConfirmation v-if="$store.state.packageconfirmation.isVisible" />
        <StateVehiclePaintCustomizerModal v-if="$store.state.visualize.paintModalVisible" />
    </aside>
</template>

<script>
import LoadingIndicator from './components/LoadingIndicator';
import HomePage from './components/HomePage';
import VehicleSelectionPage from './components/VehicleSelectionPage'
import VehicleVisualizationPage from './components/VehicleVisualizationPage'
import GlobalOverlay from './components/GlobalOverlay'
import ProductDetailsModal from './components/ProductDetailsModal'
import FilterModal from './components/FilterModal'
import RefineProductsComponent from './components/RefineProductsComponent'
import OrderDetailsComponent from './components/OrderDetailsComponent'
import PackageConfirmation from './components/PackageConfirmation'
import StateVehiclePaintCustomizerModal from './components/StateVehiclePaintCustomizerModal';
import DisclaimerModal from './components/DisclaimerModal';
import VisualizerUnavailable from './components/VisualizerUnavailable';
import Initialization from './Initialization'
import Settings from './Settings'
import IdleJs from 'idle-js';
import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
    data: function(){
        return {
            disclaimerSettings: null
        }
    },
   
    computed: {
        showcaseClass: function () {
            const classes = [
                'ridestyler-showcase-page-' + this.page,
                'ridestyler-showcase-breakpoint-' + this.breakpoint
            ],
            showcaseWrapper = this.$root.$el;

            if (this.isPortrait) classes.push('ridestyler-showcase-breakpoint-portrait');
            if (this.viewportIsShort) classes.push('ridestyler-showcase-breakpoint-viewport-short');
            if (showcaseWrapper.style.width != '' || showcaseWrapper.style.height != '') classes.push('ridestyler-showcase-border');

            return classes;
        },
        ...mapState([
            'page',
            'breakpoint',
            'isPortrait',
            'notifications',
            'viewportIsShort',
        ]),
        ...mapGetters([
            'hasAccessToVisualizer'
        ])
    },
    methods: {
        globalClick(event){
            if( event.screenX != 0 && event.screenY != 0 )
            {
                if(document.activeElement && event.target.nodeName != "SELECT" && event.target.nodeName != "INPUT")
                    document.activeElement.blur();
                //console.log(event.target.nodeName);
            }
                
            
        },
        setDisclaimerSettings(settings) {
            this.disclaimerSettings = settings;
            this.onDisclaimerConfirmed();
        },
        onDisclaimerConfirmed() {
            let settings = this.disclaimerSettings;

            if( settings == null ){ //this happens if the Disclaimer is shown from persistence.js after restoreSavedState
              return;
            }
            const tireOptionSelection = settings.getSelection(settings.tireOptionKey);

            /** @type {ridestyler.Descriptions.VehicleTireOptionDescriptionModel[]} */
            const loadedTireOptions = settings.loadedTireOptions;
            const tireOptionDetails = loadedTireOptions.find(tireOption => tireOption.TireOptionID === tireOptionSelection.value);

            this.$store
                .dispatch('selectVehicle', {
                    vehicleConfiguration: settings.vehicleConfiguration,
                    paintColor: settings.paintColor,
                    tireOption: tireOptionDetails
                })
                .then(() => {
                    this.$store.commit('goToPage', 'visualize');
                });
        },
        ...mapMutations({
            onConfirm: 'visualize/showDisclaimerModal'
        })
    },
    components: {
        HomePage,
        VehicleSelectionPage,
        VehicleVisualizationPage,
        LoadingIndicator,
        GlobalOverlay,
        ProductDetailsModal,
        StateVehiclePaintCustomizerModal,
        FilterModal,
        RefineProductsComponent,
        OrderDetailsComponent,
        PackageConfirmation,
        DisclaimerModal,
        VisualizerUnavailable
    },
    mounted () {
        Initialization.run.call(this);

        if (Settings.resetAfterIdleSeconds > 0) {
            this.idleTimer = new IdleJs({
                idle: Settings.resetAfterIdleSeconds * 1000,
                onIdle: () => {
                    this.$store.commit('goToPage', 'home');
                }
            });

            this.idleTimer.start();
        }
        
        document.dispatchEvent(new CustomEvent("rs-showcase-init"));
    },
    destroyed() {
        if (this.idleTimer) this.idleTimer.stop();
    }
}
</script>

<style lang="scss">
@import './scss/RideStylerShowcase.scss';

.ridestyler-showcase {
    .ridestyler-showcase-border {
        border: 2px solid darken($secondary-background-color, 20%);
        border-radius: 0.5em;
    }

    .ridestyler-showcase-notifications {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0.5em;
        z-index: 1000;
    }

    .ridestyler-showcase-notification {
        border-radius: 5px;
        background-color: #eee;
        padding: 1em;
        margin-top: 1em;

        &-error {
            background-color: #d80000;
            color: white;
        }
    }
}
</style>
