<template>
    <div class="ridestyler-showcase-modal" role="dialog" aria-modal="true" tabindex="-1" @click="onModalClick">
        <div class="ridestyler-showcase-modal-content">
            <button type="button" ref="close" class="ridestyler-showcase-modal-close" v-if="closeCallback !== null" @click="closeCallback" aria-label="Close Modal"><SvgIcon name="times-solid" title="Close Modal" focusable="false" /></button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import SvgIcon from './SvgIcon';

export default {
    components: {
        SvgIcon
    },
    props: {
        closeCallback: {
            type: Function,
            required: false,
            default: null
        }
    },
    mounted() {
        if( this.closeCallback !== null)
        {
          this.$refs.close.focus();
        }
        else
        {
          this.$el.focus();
        }
    },
    destroyed() {
        this.$root.$el.focus();
    },
    methods: {
        /**
         * @param {MouseEvent} e
         */
        onModalClick(e) {
            if (e.target === e.currentTarget && typeof this.closeCallback === 'function') this.closeCallback();
        }
    }
}
</script>

<style lang="scss">
    .ridestyler-showcase {
        //Landscape breakpoints
        &.ridestyler-showcase-breakpoint-xs {
            .ridestyler-showcase-modal-content {
                font-size: 1.4em;
                width: 80%;
                max-height: 85%;
            }
        }
        &.ridestyler-showcase-breakpoint-sm {
            .ridestyler-showcase-modal-content {
                width: 70%;
                max-height: 87%;
            }
        }
        &.ridestyler-showcase-breakpoint-md {
            .ridestyler-showcase-modal-content {
                width: 70%;
                max-height: 80%;
            }
        }
        &.ridestyler-showcase-breakpoint-lg {
            .ridestyler-showcase-modal-content {
                width: 55%;
                max-height: 92%;
            }
        }
        //end landscape
        //Portrait breakpoints
        &.ridestyler-showcase-breakpoint-portrait {
            &.ridestyler-showcase-breakpoint-xs {
                .ridestyler-showcase-modal-content {
                    font-size: 0.8em;
                    width: 90%;
                    max-height: 93%;
                    padding: 1em;
                }
            }
            &.ridestyler-showcase-breakpoint-sm {
                .ridestyler-showcase-modal-content {
                    font-size: 0.8em;
                    width: 70%;
                    max-height: 80%;
                }
            }
        }
        //end portrait
        //Short breakpoints
        &.ridestyler-showcase-breakpoint-viewport-short {
            &.ridestyler-showcase-breakpoint-xs {
                .ridestyler-showcase-modal-content {
                    width: 80%;
                    max-height: 87%;
                }
            }
            &.ridestyler-showcase-breakpoint-sm {
                .ridestyler-showcase-modal-content {
                    width: 70%;
                    max-height: 86%;
                }
            }
            &.ridestyler-showcase-breakpoint-md {
                .ridestyler-showcase-modal-content {
                    width: 70%;
                    max-height: 90%;
                }
            }
        }
        //end short

        .ridestyler-showcase-modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100000;
            overflow: hidden;
            text-align: center;
            background: rgba(0, 0, 0, 0.8);
            -webkit-box-sizing: border-box;
            box-sizing: border-box;

            h3 {
                text-align: left;
                font-size: 1.2em;
                margin: 10px 0px;
            }

            h2 {
                text-align: center;
                margin: 1em 0;
                font-size: 1.2em;
            }
        }

        .ridestyler-showcase-modal-close {
            position: absolute;
            right: 1em;
            top: 1em;
            z-index: 100;
            border: none;
            background: none;
            padding: 0px;
            height: 1.75em;
            width: 1.75em;

            &:focus {
                outline: none;
            }

            svg {
                height: 1.75em;
                width: 1.75em;
            }
        }

        .ridestyler-showcase-modal-content {
            margin: 0 auto;
            position: relative;
            height: auto;
            background: white;
            border-radius: 5px;
            top: 50%;
            transform: translateY(-50%);
            vertical-align: middle;
            display: block;
            padding: 2em;
        }
    }
</style>
