/**
 * 
 * @param {string} string The string to parse
 * @param {boolean} [defaultValue] The value to return if string is undefined or not boolean-like
 */
export function parseBoolean(string, defaultValue) {
    if (typeof defaultValue === 'undefined') defaultValue = false;

    if (typeof string !== 'string') return defaultValue;

    string = string.toLowerCase();

    switch (string) {
        case "true":
        case "yes":
        case "t":
        case "y":
        case "1":
            return true;
        
        case "false":
        case "no":
        case "f":
        case "n":
        case "0":
            return false;
        
        default:
            return defaultValue;
    }
}

/**
 * 
 * @param {string} string The string to parse
 * @param {any} defaultValue The value to return if a string is not passed in or there is an error parsing the JSON
 */
export function parseJSON(string, defaultValue) {
    if (typeof string !== 'string') return defaultValue;

    try {
        return JSON.parse(string);
    } catch (e) {
        return defaultValue;
    }
}


/**
 * 
 * @param {string} string The string to parse
 */

export function praseCamelCase(string) {
    if (typeof string !== 'string') return string;

    var result = string                  
        .replace(/([a-z])([A-Z][a-z])/g, "$1 $2") 
        .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2")
        .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2")
        .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2") 
        .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2") 
        .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2")
        .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2")
        .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2")        
        .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2")        
        .trim();


    // capitalize the first letter
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function parseQueryString(queryString, isLowerCase){
    const parsedString = {};

    if(isLowerCase) queryString = queryString.toLowerCase();

    if(queryString.startsWith('?')){
        queryString = queryString.substring(1);
    }

    queryString.split('&').forEach(pair => {
        const [key, value] = pair.split('=');

        parsedString[key] = decodeURIComponent(value);
    });

    return parsedString;
}