<template>
    <div class="ridestyler-showcase-tab-bar" :class="{ 'ridestyler-full-width-tab-bar' : tabBarIsFull }"
      role="tablist" v-on:keydown="onKeyDown" aria-orientation="vertical" v-bind:tabindex="tabFocused ? -1 : 0" v-on:focus="onFocused">
        <SvgIcon aria-label="Tab Expand" role='img' focusable="false" class="ridestyler-showcase-tab-bar-mobile-tab-toggle" name="caret-right" v-if="isPortrait" />
        <select class="ridestyler-showcase-tab-bar-mobile-tabs" aria-label="Tabs" v-if="isPortrait" :value="tabs[0]" v-model="selectedTabData">
            <option v-for="tab in tabs" :key="tab.label" :value="tab">{{ tab.label }}</option>
        </select>
        <template v-else>
            <TabBarTab
                ref="tab"
                tabindex="0"
                v-on:focus.native="tabFocused = true"
                v-on:blur.native="tabFocused = false"
                v-for="tab in tabs"
                v-bind:key="tab.label"
                v-bind:label="tab.label"
                v-bind:style="tabStyle"
                v-bind:icon="tab.icon"
                v-bind:selected="selectedTabData.icon === tab.icon"
                v-on:click.native="onTabClicked(tab)" />
        </template>
    </div>
</template>

<script>
import TabBarTab from './TabBarTab';
import { mapGetters, mapState } from 'vuex';
import SvgIcon from './SvgIcon';

export default {
    data: function () {
        return {
            selectedTabData: 'wheel',
            tabFocused: true
        };
    },
    props: {
        tabs: {
            type: Array,
            required: true
        },
        selectedTab: String
    },
    computed:  {
        tabBarIsFull() {
            return this.isPortrait && this.selectedTab != 'wheel';
        },
        tabStyle () {
            const tabPercentage = (100/this.tabs.length).toFixed(4) + '%';

            return this.$store.state.breakpoint === 'portrait' ? {width: tabPercentage} : {height: tabPercentage};
        },
        selectedTabIndex() {
            if(this.tabs.indexOf(this.selectedTabData) != -1) return this.tabs.indexOf(this.selectedTabData);
            else return 0;
        },
        ...mapGetters([
            'hasWheelModelSelected',
        ]),
        ...mapState([
            'breakpoint',
            'isPortrait',
        ]),
    },
    methods: {
        onTabClicked (tab) {
            if(tab.icon === 'tire' && !this.hasWheelModelSelected){
                var self = this;
                this.$store.commit('globaloverlay/showPrompt', {message: "Please select a wheel to see matching tire packages.", callback: function(){self.$store.commit('globaloverlay/hideModal')}, confirmText: "Ok"});
            } else {
                this.selectedTabData = tab;
            }
        },
        onFocused() {
            this.focusActiveOption();
        },
        getAllOptions() {
            return Array.from(this.$el.children);
        },
        getActiveOption() {
            if (this.selectedTabIndex < 0) return undefined;

            const allOptions = this.getAllOptions();

            return allOptions[this.selectedTabIndex];
        },
        getFocusedOption() {
            const activeElement = document.activeElement;

            return activeElement.classList.contains('ridestyler-showcase-tab-bar-tab') ? activeElement : undefined;
        },
        focusActiveOption() {
            const activeOption = this.getActiveOption();

            if (activeOption) activeOption.focus();
        },
        focusPreviousOption() {
            const focusedOption = this.getFocusedOption();
            const previousOption = focusedOption && focusedOption.previousElementSibling;

            if (previousOption) previousOption.focus();
            else this.focusLastOption();
        },
        focusNextOption() {
            const focusedOption = this.getFocusedOption();
            const nextOption = focusedOption && focusedOption.nextElementSibling;

            if (nextOption) nextOption.focus();
            else this.focusFirstOption();
        },
        focusFirstOption() {
            const allOptions = this.getAllOptions();

            if (allOptions.length === 0) return;

            allOptions[0].focus();
        },
        focusLastOption() {
            const allOptions = this.getAllOptions();

            if (allOptions.length === 0) return;

            allOptions[allOptions.length - 1].focus();
        },
        selectFocusedOption() {
            const focusedOption = this.getFocusedOption();

            if (focusedOption) {
                const index = this.getAllOptions().indexOf(focusedOption);

                if (index < 0 || index >= this.tabs.length) return;

                this.selectedTabData = this.tabs[index];
            }
        },
        onKeyDown(e) {
            let key = e.which || e.keyCode;

            switch (key) {
                case 38: // up
                    this.focusPreviousOption();
                    break;
                case 40: // down
                    this.focusNextOption();
                    break;
                case 36: // home
                    this.focusFirstOption();
                    break;
                case 35: // end
                    this.focusLastOption();
                    break;
                case 32: // space
                case 13: // enter
                    this.selectFocusedOption();
                    break;

                case 9: // tab
                    return;
            }

            e.preventDefault();
        }
    },
    watch: {
        selectedTabData(newTabData) {
            this.$emit('tab-changed', newTabData);
        },
        selectedTab: {
            immediate: true,
            handler(newTabKey) {
                for (const tab of this.tabs) {
                    if (tab.key !== newTabKey) continue;

                    this.selectedTabData = tab;
                    return;
                }
            }
        }
    },
    components: {
        TabBarTab,
        SvgIcon
    },
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-tab-bar {
        position: relative;
        display: -webkit-box; display: -ms-flexbox; display: flex;
        -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row;
        -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start;
        height: 14%;
        top: 2px;
        padding: 0 1%;
        color: white;
        z-index: 1;
       
    }
    //Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-tab-bar {
            width: 68%;
            top: 0;
            padding: 0;
            height: auto;
            &.ridestyler-full-width-tab-bar {
                width: 100%;
            }
        }
        .ridestyler-showcase-tab-bar-mobile-tabs {
            -webkit-appearance: none; -moz-appearance: none; appearance: none;
            height: 2.8em;
            color: black;
            margin-bottom: 0;
            font-size: 1em;
            background: white;
        }
        .ridestyler-showcase-tab-bar-mobile-tab-toggle {
            position: absolute;
            width: 1.3em; height: 1.3em;
            right: 10px; top: 0.8em;
            @include var(fill, primary-color);
            &.active {
                transform: rotate(90deg);
            }
        }
        &.ridestyler-showcase-breakpoint-md {

        }
        &.ridestyler-showcase-breakpoint-sm {

        }
        &.ridestyler-showcase-breakpoint-xs {

        }
    }
    //Landscape breakpoints
    &.ridestyler-showcase-breakpoint-sm {

    }
    &.ridestyler-showcase-breakpoint-xs {

    }
    //Short breakpoints
    &.ridestyler-showcase-breakpoint-viewport-short {
        &.ridestyler-showcase-breakpoint-lg {

        }
        &.ridestyler-showcase-breakpoint-md {

        }
    }
}

</style>
