<template>
    <div class="ridestyler-showcase-viewport">
        <div class="ridestyler-showcase-renderer" ref="renderer" />

        <div class="ridestyler-showcase-viewport-messages" role="status">
            <div class="ridestyler-showcase-viewport-message" v-for="message in messages" v-bind:key="message" v-text="message" />
        </div>
    </div>
</template>

<script>
    const RideStylerViewport = require('exports-loader?RideStylerViewport!com.burkson.ridestyler.widgets/widgets/vehicle-renderer')

    export default {
        created() {
            this.rotateSVGPath = '#' + require('../assets/images/icons/rotate-vehicle.svg').default.id;
        },
        props: {
            center : {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            messages() { return []; },
            renderInstructions() { return {}; },
            canRotateVehicle() { return false; }
        },
        mounted: function () {
            this.renderer = new RideStylerViewport(this.$refs.renderer);
            var instructions = this.renderInstructions;
            if( this.center )
                instructions.PositionY = 1;
            this.renderer.Update(instructions);
        },
        watch: {
            renderInstructions: {
                immediate: true,
                handler: function () {
                    if (this.renderer) {
                        var instructions = this.renderInstructions;
                        if( this.center )
                            instructions.PositionY = 1;
                        this.renderer.Update(this.renderInstructions);
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-viewport {
        position: relative;
        height: 75%; width: 65%;
        margin-bottom:5%;
        align-self: center;
    }
    .ridestyler-showcase-renderer {
        position: relative;
        width: 100%; height: 100%;
        z-index: 99;
        .rsvr-viewport {
            max-height: 100%;
        }
    }
    .ridestyler-showcase-rotate-vehicle {
        position: absolute;
        width: 100%; height: 30%;
        bottom: 1.5em;
        opacity: 0.25;
    }
    .ridestyler-showcase-viewport-messages {
        position: absolute;
        z-index: 100;
        top: 100%;
        width: 100%;
        left: 4%;
        right: 5%;
        text-align: center;
        opacity: 1;
        pointer-events: none;
    }
    .ridestyler-showcase-viewport-message {
        padding: 8px 0;
        color: black;
        opacity: 1;
        font-size: 1.2em;
        font-weight: 500;
        width: 100%;
    }
    &.ridestyler-showcase-breakpoint-md {
        .ridestyler-showcase-viewport-messages {
            font-size: 1.2em;
        }
    }
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-viewport {
            width: 83%; height: 30%;
            bottom: 23px;
        }
        .ridestyler-showcase-viewport-messages {
            left: 0;
            right: 0;
            top: 95%;
            .ridestyler-showcase-viewport-message {
                font-size: 0.9em;
            }
        }
        &.ridestyler-showcase-breakpoint-sm {
            .ridestyler-showcase-viewport-messages {
                left: 0;
                right: 0;
                bottom: -7%;
            }
        }
    }
}
</style>
