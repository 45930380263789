<template>
    <table class="ridestyler-showcase-order-details">
        <thead class="ridestyler-showcase-order-details-head">
            <tr>
                <th>PRODUCT</th>
                <th></th>
                <th>QTY</th>
                <th v-if="showPricing">PRICE TOTAL*</th>
                <th v-if="showStock">STOCK</th>
                <th></th>
            </tr>
        </thead>
        <tbody class="ridestyler-showcase-order-details-body">
            <tr v-for="(product, i) in order" :key="i">
                <td v-if="product.HasCatalogImage">
                    <img v-bind:src="getImage(product)" class="ridestyler-showcase-order-details-product-image" :alt="getProductTitle(product)"  >
                </td>
                <td v-html="getProductTitle(product)"></td>
                <td v-if="product">
                    <input v-if="product.Quantity" v-bind:name="i" type="number" min="1" class="ridestyler-showcase-order-details-product-quantity" v-on:input="quantityUpdate" :value="product.Quantity" aria-label="Quantity" />
                    <input v-else v-bind:name="i" type="number" min="1" class="ridestyler-showcase-order-details-product-quantity" v-on:input="quantityUpdate" :value="defaultQTY" aria-label="Quantity" />
                </td>
                <td v-if="showPricing" >
                   
                   <template v-if="product.Price">{{ getPriceTotal(product)}}</template>
                   <template v-else>{{nullPrice(product)}}</template>
                
                </td>
                <td v-if='showStock' :data-stock="product.QuantityAvailable">
                    {{getStock(product)}}
                </td>
                <td v-if="product">
                    <button type="button" v-on:click="removeProduct(i)" class="ridestyler-showcase-button ridestyler-showcase-order-details-remove" aria-label="Remove" >X</button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { mapActions } from 'vuex';
import { formatWheelSize, formatOffset, formatPrice } from '../Formatters';
import settings from '../Settings';


export default {
    data: function(){
        return {
            defaultQTY: 4,
        }
    },
    props: {
        order: undefined,
    },
    computed: {
        showPricing: function() {
            return settings.showPricing;
        },
        showStock: function () {
            return settings.showInventory;
        }
        
        
    },
    methods: {
        productsAvailable(){
            if(Object.keys(this.order).length == 0){
                this.closeModal();
            }
        },
        nullPrice: function(product) {
            return this.translate( this.isTire(product)?"tirePriceNull":"wheelPriceNull");
        },
        isTire: function(product) {
            return !product.BoltPattern;
        },
        getStock(product){
            let lowStockWarning = 0;
            let showInventoryValues = false;
            let isTire = this.isTire(product);

            if(!isTire) {
                showInventoryValues = settings.showWheelInventoryValues;
                lowStockWarning = settings.lowWheelStockWarning;
            } 
            else {
                showInventoryValues = settings.showTireInventoryValues;
                lowStockWarning = settings.lowTireStockWarning;
            }

            if( settings.showInventory && showInventoryValues )
            {
                if( !product.QuantityAvailable && product.QuantityAvailable != 0 )
                    return this.translate( isTire?"tireStockNullShort":"wheelStockNullShort" ) ;
                else
                    return  product.QuantityAvailable;
            }
               

            if(lowStockWarning && settings.showInventory) {
                if( !product.QuantityAvailable && product.QuantityAvailable != 0 )
                    return this.translate( isTire ? "tireStockNullShort":"wheelStockNullShort") ;
                else if( product.QuantityAvailable == 0)
                   return this.translate( isTire ? "tireStockOut":"wheelStockOut") ;
                else if( product.QuantityAvailable < lowStockWarning)
                    return this.translate(isTire?"tireStockLow":"wheelStockLow");
                else
                    return this.translate(isTire? "tireStockIn":"wheelStockIn");
            }

            return "";
        },
        getProductTitle(product){
            let label;

            if(!product.BoltPattern){
                label = "<strong>" + product.TireBrandName + ' ' + product.TireModelName + "</strong>" +
                "<div>" + product.TireSize.Description + " " + product.TireSize.ServiceDescription +  "</div>";
            } else if(product.BoltPattern){
                
                label = "<strong>" + product.WheelBrandName + ' ' + product.WheelModelName + ' ' + product.WheelModelFinishDescription + "</strong>" +
                "<div>" + formatWheelSize([product.DiameterMin, product.DiameterMax], [product.WidthMin, product.WidthMax]) + ' ' + formatOffset(product.OffsetMin, product.OffsetMax) + '</div>';
            }

            return label;
        },
        getImage(product) {
            var defaultImageValue = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
            maxResolution = 800,
            dpi = window.devicePixelRatio || 1,
            imageSize = 112,
            imageResolution = Math.min(Math.floor(imageSize * dpi), maxResolution),
            type = null;

            if(product.BoltPattern) type = 'wheel';
            else type = 'tire';

            if (imageResolution > 0 && product.HasCatalogImage) {
                if (type === 'wheel') {
                    var wheelRender = {
                        Width: imageResolution,
                        Height: imageResolution,
                        IncludeShadow: true,
                        WheelFitmentResourceType: 3,
                        PositionX: 1,
                        PositionY: 1
                    };

                    if ('WheelFitmentID' in product) wheelRender.WheelFitment = product.WheelFitmentID;
                    else if ('WheelModelID' in product) wheelRender.WheelModel = product.WheelModelID;
                    else return defaultImageValue;

                    return ridestyler.ajax.url('wheel/image', wheelRender);

                } else if (type === 'tire') {
                    var tireRender = {
                        Width: imageResolution,
                        Height: imageResolution,
                        IncludeShadow: true,
                        TireFitmentResourceType: "Catalog",
                        PositionX: 1,
                        PositionY: 1
                    };

                    if ('TireFitmentID' in product) tireRender.TireFitment = product.TireFitmentID;
                    else if ('TireModelID' in product) tireRender.TireModel = product.TireModelID;
                    else return defaultImageValue;

                    return ridestyler.ajax.url('tire/image', tireRender);
                }
            }
        },
        quantityUpdate(event) {
            
            const type = event.target.name,
            quantity = parseInt(event.target.value),
            payload = {"key": type, "Quantity": quantity};
            this.$store.dispatch("orderdetails/updateQuantity", payload);
            this.$forceUpdate();
        },
        getPriceTotal(product){
            if( product.Quantity )
                return formatPrice(product.Price * product.Quantity) ;
            else
                return formatPrice(product.Price * this.defaultQTY) ;
        },
        removeProduct(productId){
            this.$store.dispatch("orderdetails/deleteProduct", productId);
            this.productsAvailable();
        },
        ...mapActions({
            closeModal: 'orderdetails/hideModal'
        })
    }
}
</script>
<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-order-details {
        width: 100%;
        border-radius: 0.6em;
        overflow: hidden;
        .ridestyler-showcase-button {
            background: $secondary-button-color;
            border-color: $secondary-button-color;
        }
        .ridestyler-showcase-order-details-product-image {
            width: 70px;
            display: inline-block;
            vertical-align: middle;
        }
        .ridestyler-showcase-order-details-product-quantity {
            display: inline-block;
            width: auto;
            max-width: 87px;
            text-align: center;
            margin: 0;
        }
        tr {
            th {
                padding: 1em;
                background-color: $secondary-button-color;
                color: white;
            }

            td, th {
                padding: 1.2em 0.5em;
                text-align: center;
                border: none;
                vertical-align: middle;
            }

            &:nth-child(even) {
                @include var(background-color, secondary-background-color);
            }

            &.highlight {
                td, th {
                    font-weight: bold;
                }
            }

            &.disabled {
                td, th {
                    color: #6b6b6b;
                    text-decoration: line-through;
                }
            }
        }
    }
}
</style>
