<template>
    <div class="ridestyler-showcase-vehicle-paint-customizer" :aria-labelledby="(page == 'select')?'ridestyler-showcase-vehicle-paint-customizer-label':null" role="form">
        <VehicleRenderer v-bind:vehicleConfiguration="vehicleConfiguration" v-bind:paintColor="paintColor" />
        <div class="ridestyler-showcase-vehicle-select-confirm-wrapper" v-if="page == 'select'">
            <h3 id="ridestyler-showcase-vehicle-paint-customizer-label">{{translate('colorHeading')}}</h3>
            <p>{{translate('colorMessage')}}</p>
            <button type="button" class="ridestyler-showcase-button ridestyler-showcase-button-large" v-on:click="confirm">
                {{translate('colorButton')}}
            </button>
        </div>
        <ProductGallery aria-label="Select your Paint Color" v-bind="productGallerySettings" v-on:product-selected="onPaintSchemeSelected" :hasIndicators="true" />
    </div>
</template>

<script>
import VehicleRenderer from "./VehicleRenderer";
import ProductGallery from "./ProductGallery";
import { mapState } from 'vuex';

export default {
    created() {
        this.paintColor = this.initialColor;
    },
    data() {
        return {
            /** @type {string} */
            paintColor: undefined
        };
    },
    components: {
        VehicleRenderer,
        ProductGallery
    },
    props: {
        vehicleConfiguration: {
            type: String,
            required: true
        },
        initialColor: String
    },
    computed: {
        productGallerySettings() {
            return {
                productType: 'paint',
                resultsAction: 'Vehicle/GetPaintSchemeDescriptions',
                responseKey: 'Schemes',
                currentFilters: {
                    vehicleConfiguration: this.vehicleConfiguration
                },
                productKeyRetriever(paintScheme) {
                    const id = paintScheme.VehiclePaintSchemeID;
                    const colors = paintScheme.Colors.map(color => color.Hex).sort().join(',');

                    return id + '-'+ colors;
                }
            };
        },
        ...mapState([ 'page' ]),
    },
    methods: {
        onPaintSchemeSelected(scheme) {
            const color = scheme.Colors[0].Hex;
           
            document.dispatchEvent(new CustomEvent("rs-vehicle-option-selection", 
                { detail: { 
                    step: "color", 
                    value:scheme.SchemeName, 
                    rsID:color, 
                    selectedWheelModel:this.$store.state.selectedWheelModel,
                    selectedDiameter:this.$store.state.lastSelectedDiameterFront
                }} ));

            this.paintColor = color;
            this.$emit('paint-scheme-selected', scheme);
            this.$emit('paint-color-selected', color);
        },
        confirm() {
           
            this.$emit('show-disclaimer');
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {

    .ridestyler-showcase-vehicle-paint-customizer {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly;
        height: 100%;

        .ridestyler-showcase-viewport {
            position: relative;
            top: 0; left: 0;
            margin: 0;
            height: 55%;
        }

        .ridestyler-showcase-vehicle-select-confirm-wrapper {
            text-align: center;
            margin: auto 0;
            h3 {
                font-size: 1.7em;
                font-weight: bold;
            }
            p {
                margin: 0 0 2em;
                padding: 0 1em;
            }
        }

        .ridestyler-showcase-product-gallery-wrapper {
            &.fade-before {
                &::before {
                    background: linear-gradient(to left, transparent, white);
                }
            }
            &.fade-after {
                &::after {
                    background: linear-gradient(to right, transparent, white);
                }
            }
        }

        .ridestyler-showcase-product-gallery {
            position: relative;
            height: 25%;
            margin: 0 0 0;

            .ridestyler-showcase-product-gallery-next, .ridestyler-showcase-product-gallery-prev {
                top: 50%;
            }
        }

        .ridestyler-showcase-product-gallery-product-label {
            width: 90%;
            margin: 5px auto;
            cursor: pointer;
            color: #262626;
            font-size: 1.5em;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .ridestyler-showcase-product-gallery-next .ridestyler-showcase-icon, .ridestyler-showcase-product-gallery-prev .ridestyler-showcase-icon {
            color: white;
            fill: white;
        }
    }
    .ridestyler-showcase-paint-swatch {
        display: inline-block;
        width: 6em; height: 6em;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 50%;
    }
}
</style>
