<template>
    <div class="ridestyler-showcase-page" :aria-hidden='modalVisible' >
        <TopBar>
            <template v-slot:title>
                <div id="ridestyler-showcase-top-bar-title" class="ridestyler-showcase-top-bar-selected-vehicle" v-text="vehicleDescriptionText"></div>
                  <button type="button" v-on:click.prevent="$store.commit('visualize/showPaintModal')" tabindex="0" title="Change Color" role="button" aria-label="Change Color"><SvgIcon name="paint" focusable="false" /></button>
            </template>
            <template v-slot:right>
                <ShareButton v-if="$showcaseSettings.enableSharing" />
            </template>
        </TopBar>

        <div class="ridestyler-showcase-visualizer-content">
            <VehicleControlPanel @zoomEvent="zoomAction" :isZoomed="isVehicleZoomed" />

            <StateVehicleRenderer />

            <div class="ridestyler-showcase-selections" v-if="selectedWheelModel || selectedTireModel">
                <ProductDetailsComponent />
            </div>
        </div>

        <div class="ridestyler-showcase-visualizer-footer">
            <div class="ridestyler-showcase-active-customization-information" v-if="!isPortrait">
                <button type="button" v-on:click="showFilters" v-if="['wheel'].indexOf(selectedCustomizationTab) >= 0 && !partNumbersLoaded">
                    <span>Filters </span>
                    <span class="ridestyler-showcase-label">{{ activeFilterCount }}</span>
                </button>
            </div>
            <TabBar v-bind:tabs="tabBarTabs" v-on:tab-changed="onTabChanged" v-bind:selectedTab="selectedCustomizationTab" v-if="!isPortrait" />

            <div class="ridestyler-showcase-vehicle-customization-tabs">
                <div class="ridestyler-showcase-vehicle-custimization-mobile-top" v-if="isPortrait">
                    <TabBar v-bind:tabs="tabBarTabs" v-on:tab-changed="onTabChanged" v-bind:selectedTab="selectedCustomizationTab" />
                    <div class="ridestyler-showcase-active-customization-information" v-show="selectedCustomizationTab == 'wheel'">
                        <button type="button" v-on:click="showFilters" v-if="['wheel'].indexOf(selectedCustomizationTab) >= 0 && !partNumbersLoaded" class="ridestyler-showcase-button-large">
                            <span>Filters </span>
                            <span class="ridestyler-showcase-label">{{ activeFilterCount }}</span>
                        </button>
                    </div>
                </div>

                <div class="ridestyler-showcase-vehicle-customization-tab" v-show="selectedCustomizationTab === 'wheel'">
                    <ProductGallery v-bind="wheelProductGallerySettings" v-on:product-selected="product => onWheelProductSelected({wheelModel: product})" v-bind:key="'wheel'" :hasIndicators="!isPortrait">
                        <template slot="no-results">
                            <div>No Results</div>
                            <p v-if="activeFilterCount > 0">
                                Try <a href="#" v-on:click.prevent="clearWheelFilters">clearing</a> your filters to see more results
                            </p>
                        </template>
                    </ProductGallery>
                </div>
                <div class="ridestyler-showcase-vehicle-customization-tab" v-show="selectedCustomizationTab === 'tire'">
                    <ProductGallery v-bind="tireProductGallerySettings" v-on:product-selected="product => onTireProductSelected({tireModel: product})" v-bind:key="'tire'" :hasIndicators="!isPortrait">
                        <template slot="no-results">
                            <div>No Results</div>
                        </template>
                    </ProductGallery>
                </div>
                <SuspensionAdjustment class="ridestyler-showcase-vehicle-customization-tab" v-if="selectedCustomizationTab === 'suspension'" />
            </div>
        </div>

        <VehicleImageZoomer :isVisible="isVehicleZoomed" @closeZoomer="zoomAction(false)" />
    </div>
</template>

<script>
import TopBar from './TopBar';
import SvgIcon from './SvgIcon';
import StateVehicleRenderer from './StateVehicleRenderer'
import TabBar from './TabBar';
import ProductGallery from './ProductGallery';
import ProductDetailsComponent from './ProductDetailsComponent';
import SuspensionAdjustment from './SuspensionAdjustment';
import VehicleControlPanel from './VehicleControlPanel';
import ShareButton from './ShareButton';
import { getActiveFilterCount } from '../Filters';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import VehicleImageZoomer from './VehicleImageZoomer';
import settings from '../Settings';

export default {
    data: function () {
        return {
            settingsMenuOpen: false,
            isVehicleZoomed: false,
        };
    },
    mounted() {
        this.onWheelChange({tireSettings:this.tireProductGallerySettings});
        this.$el.querySelector("button").focus();
    },
    methods: {
        onTabChanged(newTab) {
            const key = newTab.key;

            this.$store.commit('setSelectedCustomizationTab', key);
        },
        clearWheelFilters() {
            this.$store.commit('clearFilters', 'wheel');
        },
        clearTireFilters() {
            this.$store.commit('clearFilters', 'tire');
        },
        zoomAction(zoomerIsVisible) {
            this.isVehicleZoomed = zoomerIsVisible;
        },
        ...mapActions({
            onWheelProductSelected: 'selectWheelModel',
            onTireProductSelected: 'selectTireModel',
            onWheelChange: 'loadAvailableTireCount',
        }),
        ...mapMutations({
            showFilters: 'visualize/showFilterModal'
        })
    },
    computed: {
        selectedTabData() {
            for (const tabBarData of this.tabBarTabs) {
                if (tabBarData.key === this.selectedCustomizationTab) {
                    return tabBarData;
                }
            }

            return undefined;
        },
        activeFilterCount() {
            return getActiveFilterCount(this.selectedCustomizationTab);
        },
        tireProductGallerySettings() {
            return {
                productType: 'tire',
                resultsAction: "tire/getmodeldescriptions",
                responseKey: "Models",
                baseRequest: {
                    IncludePromotions: true,
                    IncludeFitments: true,
                    //IncludeMetaKeys:["Description", "Highlights"]
                },
                currentFilters: this.tireFilters,
                productKeyRetriever: product => product.TireModelID,
                initiallySelectedProduct: this.selectedTireModel && this.selectedTireModel.TireModelID
            };
        },
        wheelProductGallerySettings() {
            
            return {
                productType: 'wheel',
                resultsAction: "wheel/getmodeldescriptions",
                responseKey: "Models",
                baseRequest: {
                    IncludePromotions: true,
                    //IncludeFitments: true
                },
                currentFilters: this.wheelFilters,
                productKeyRetriever: product => product.WheelModelID,
                initiallySelectedProduct: this.selectedWheelModel && this.selectedWheelModel.WheelModelID
            };
        },
        tabBarTabs(){
            let tabs = [];

            if(this.$store.state.wheelsEnabled){
                tabs.push({
                    label: this.translate("wheelsTab"),
                    icon: 'wheel',
                    title: 'Select Wheels',
                    key: 'wheel'
                });
            }

            if(
                this.$store.state.tiresEnabled && 
                this.$store.state.subscription.Description != 'Visualizer Standard' &&
                this.$store.state.availableTireCount &&
                !settings.options.disableMatchingTires
                ){
                tabs.push({
                    label: this.translate("tiresTab"),
                    icon: 'tire',
                    title: 'Select Tires',
                    key: 'tire'
                })
            }

            if( !settings.options.disableSuspension ){
                 tabs.push({
                label: this.translate("suspensionTab"),
                icon: 'suspension',
                title: 'Adjust Suspension',
                key: 'suspension'
            });
            }
           

            return tabs;
        },
        tiresEnabled(){
            return this.$store.state.tiresEnabled;
        },
        wheelsEnabled(){
            return this.$store.state.wheelsEnabled;
        },
        modalVisible() {
          return this.$store.state.orderdetails.isVisible;

        },
        partNumbersLoaded() {
            return settings.options.showPartNumbers && 
                Array.isArray(settings.options.showPartNumbers) && 
                settings.options.showPartNumbers.length > 0;
        },
        ...mapState([
            'selectedTireModel',
            'selectedWheelModel',
            'selectedCustomizationTab',
            'breakpoint',
            'isPortrait',
            'availableTireFitmentsFront',
            'vehicleDescription',

        ]),

        ...mapGetters([
            'vehicleDescriptionText',
            'hasWheelModelSelected',
            'hasTireModelSelected',
            'tireFilters',
            'wheelFilters',
            'presumedWheelFitmentFront'
        ])
    },
    watch: {
        presumedWheelFitmentFront(){
            this.onWheelChange({tireSettings:this.tireProductGallerySettings});
        }
    },
    components: {
        StateVehicleRenderer,
        TabBar,
        ProductGallery,
        ProductDetailsComponent,
        SuspensionAdjustment,
        TopBar,
        ShareButton,
        SvgIcon,
        VehicleControlPanel,
        VehicleImageZoomer
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-visualizer-content {
        display: -webkit-box; display: -ms-flexbox; display: flex;
        -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row;
        width: 100%; height: 67.1%;
        align-self: flex-end;
    }
    .ridestyler-showcase-visualizer-footer {
        display: -webkit-box; display: -ms-flexbox; display: flex;
        flex-direction: column;
        width: 100%; height: 37.5%;
        position:absolute; bottom:0;
        pointer-events: none;
    }
    .ridestyler-showcase-selections {
        height: auto; width: 20%;
        max-height: 100%;
        overflow: auto;
        margin: 0 5% 0 3%;
        align-self: center;
    }
    .ridestyler-showcase-active-customization-information {
        position: relative;
        height: 13%;
        margin: 0;
        padding: 0.4em 1%;
        span.ridestyler-showcase-label {
            padding: 0 .5em 0.1em;
            vertical-align: bottom;
            color: $secondary-button-color;
            border-radius: 2em;
        }
        button {
            width: auto; height: 100%;
            padding: 0 1.3em;
            background: $secondary-button-color;
            color: white;
            border: none; border-radius: 0.2em;
            font-size: 1.1em;
            pointer-events: auto;
        }
    }
    .ridestyler-showcase-vehicle-customization-tabs {
        position: relative;
        height: 73%; width: 100%;
        background: $secondary-background-color;
        border-top: 2px solid darken($secondary-background-color, 5%);
        pointer-events: auto;
    }
    .ridestyler-showcase-vehicle-customization-tab {
        position: relative;
        width: 100%; height: 100%;
        box-sizing: border-box;
    }
    .ridestyler-showcase-settings-menu {
        display: inline-block;
        vertical-align: middle;
        .ridestyler-showcase-menu-list a {
            background-color: #d4d4d4;
            color: #333333;
            border-radius: 4px;
            padding: 0;
            line-height: 2.5em;
            text-align: center;
        }
        .ridestyler-showcase-top-bar-selected-vehicle-settings {
            border: none;
            background: none;
            padding: 0 1em 0;
            .ridestyler-showcase-icon {
                width: 1.5em; height: 1.5em;
                vertical-align: middle;
            }
        }
        .ridestyler-showcase-popover-content {
            width: 150px;
            margin-left: -75px;
            box-sizing: border-box;
            left: 50%; top: 40px;
        }
    }
    // portrait
    &.ridestyler-showcase-breakpoint-portrait {
        &.ridestyler-showcase-page-visualize {
            .ridestyler-showcase-visualizer-content {
                flex-direction: column;
                justify-content: flex-start;
                height: 62.1%;
            }
            .ridestyler-showcase-visualizer-footer {
                height: 31.5%;
            }
            .ridestyler-vehicle-control-panel {
                height: 11%; width: 96%;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding: 0;
            }
            .ridestyler-showcase-selections {
                position: relative;
                width: 96%; height: auto;
                margin: 0; padding: 0;
            }
            .ridestyler-showcase-vehicle-customization-tab {
                height: 73%;
            }
            .ridestyler-showcase-vehicle-customization-tabs {
                height: 100%;
            }
            .ridestyler-showcase-vehicle-custimization-mobile-top {
                height: 27%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 0 2%;
                .ridestyler-showcase-active-customization-information {
                    width: 29%;
                    height: 2.8em;
                    padding: 0;
                    button {
                        width: 100%;
                        height: 100%;
                        padding: 0 1em;
                    }
                }
            }
        }
        &.ridestyler-showcase-breakpoint-md {
            &.ridestyler-showcase-page-visualize {
                .ridestyler-showcase-visualizer-content {
                    height: 67.1%;
                }
                .ridestyler-showcase-vehicle-customization-tab {
                    height: 67%;
                }
                .ridestyler-showcase-visualizer-footer {
                    height: 28.3%;
                }
            }
        }
    }
    //end portrait
}
</style>
