<template>
    <transition name="fade">
        <Modal class="ridestyler-showcase-disclaimer-modal" 
            v-if="false"
            v-bind:close-callback="(canClose)?closeModal:null" 
            aria-labelledby="ridestyler-showcase-disclaimer-modal-label">
            <h1 id='ridestyler-showcase-disclaimer-modal-label'>Disclaimer of Liability</h1>
            <h2>Please read and acknowledge the following</h2>
            <p>This software is only a visual guide to the look of various wheels, tires, and suspension on selected model vehicles.
              Some wheels, tires, or suspension modifications shown may not actually be suitable for application to your specific vehicle.
              Please see a qualified sales person with respect to specific application of new wheels, tires, or suspension on your vehicle.</p>
            <p>No expressed or implied warranties with respect to any wheel, tire, or suspension application derived from the use of this software is made.</p>
            <p>Because of the numerous possible combinations of vehicle models, styles, colors and options, the vehicle images may not match your vehicle choice exactly.</p>
            <button type="button" class="ridestyler-showcase-button ridestyler-showcase-button-large ridestyler-showcase-disclaimer-continue" v-on:click="onConfirm">Continue</button>
        </Modal>
    </transition>
</template>

<script>
import Modal from './Modal'
import { mapState, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapState({
            disclaimerModalVisible: state => state.visualize.disclaimerModalVisible,
        })
    },
    methods: {
        onConfirm(){
            this.$emit("confirmed");
            this.closeModal();
        },
        ...mapMutations({
            closeModal: 'visualize/hideDisclaimerModal'
        })
    },
    props: ['canClose'],
    components: {
        Modal,
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-modal.ridestyler-showcase-disclaimer-modal {
        font-size: 1.3em;
        overflow: auto;

        .ridestyler-showcase-vehicle-disclaimer {
            top: 0; left: 0; right: 0; bottom: 0;
        }

        .ridestyler-showcase-modal-close {
            padding: 0;
            width: auto;
            margin-top: 0px;
            top: 2em;
        }
        button {
            width: 100%;
        }
        h1 {
            text-align: center;
        }
        h3 {
            font-weight: bold;
            margin-bottom: 15px;
        }
        p {
            margin: 15px 0;
            text-align: left;
            line-height: 1.17em;
        }
    }

    //sm
    @media screen and (min-width: 668px) and (max-width: 992px){
        .ridestyler-showcase-modal.ridestyler-showcase-disclaimer-modal {
            .ridestyler-showcase-modal-content {
                overflow: auto;
                max-height: 70%;
            }
            p {
                font-size: .8em;
            }
        }
    }

    //xs
    @media screen and (max-width: 667px){
        .ridestyler-showcase-modal.ridestyler-showcase-disclaimer-modal {
            .ridestyler-showcase-modal-content {
                overflow: auto;
                max-height: 78%;
            }
            p {
                font-size: .8em;
            }
        }
    }
}
</style>
