<template>
    <SelectBox ref="select" v-on="$listeners" :multi="multi" :title="title" :selectedValue="selectedValue" :isLoading="isLoading" :options="options" />
</template>

<script>
import SelectBox from './SelectBox'
import RideStylerAPIRequest from '../RideStylerAPIRequest';

/** 
 * @param {((o:object)=>string)|string} mapping
 * @param {object} object
 */
function executeMapping(mapping, object) {
    return typeof mapping === 'string' ? object[mapping] : mapping(object);
}

export default {
    data: function () {
        return {
            options: [],
            isLoading: true
        };
    },
    components: {
        SelectBox
    },
    props: {
        multi: {
            type: Boolean,
            default: false
        },
        title: String,
        selectedValue: [String, Array, Number],
        requestAction: {
            type: String,
            required: true
        },
        requestData: Object,
        responseKey: {
            type: String,
            required: true
        },
        mapLabel: {
            type: [Function, String],
            required: true
        },
        mapValue: {
            type: [Function, String],
            required: true
        },
        clearOption: String
    },
    methods: {
        loadOptions() {
            this.isLoading = true;

            return RideStylerAPIRequest(this.requestAction, this.requestData, this.responseKey)
                .then((/** @type {any[]} */ objects) => {
                    let newOptions = objects.map(o => {
                        return {
                            label: executeMapping(this.mapLabel, o),
                            value: executeMapping(this.mapValue, o)
                        };
                    });

                    if (this.clearOption) newOptions = [{
                        label: this.clearOption,
                        value: ''
                    }].concat(newOptions);

                    this.options = newOptions;
                })
                .finally(() => this.isLoading = false);
        }
    },
    watch: {
        requestData() {
            this.loadOptions();
        }
    },

    created() {
        this.loadOptions();
    }
}
</script>

<style>

</style>
