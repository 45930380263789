import Vue from 'vue';
import Vuex from 'vuex';

import { parseQueryString, parseBoolean } from '../Parsers';
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import visualize from './modules/visualize';
import globaloverlay from './modules/globaloverlay';
import refineproducts from './modules/refineproducts';
import orderdetails from './modules/orderdetails';
import packageconfirmation from './modules/packageconfirmation';
import * as persistence from './persistence';
import * as watchers from './watchers';

Vue.use(Vuex);

/** @typedef {import('./state').RideStylerShowcaseState} RideStylerShowcaseState */
/** @typedef {import('../../node_modules/vuex/types/index').Store<RideStylerShowcaseState>} RideStylerShowcaseStore */

/** @type {RideStylerShowcaseStore} */
const store = new Vuex.Store({
    state,
    getters,
    mutations,
    actions,

    modules: {
        visualize,
        globaloverlay,
        refineproducts,
        orderdetails,
        packageconfirmation
    },

    strict: process.env.NODE_ENV !== 'production'
});

function isSkipIntro(){
    let query = parseQueryString(window.location.search, true);

    return parseBoolean(query.skipintro);
}

export default store;
export function initialize() {
    this.$on('initialized', function() {
        if(isSkipIntro()){
            store.commit('goToPage', 'select');
        } else {
            store.commit('goToPage', 'home');
        }
        persistence.initialize().then(watchers.initialize)
    });
}
