<template>
    <div class="ridestyler-showcase-modal ridestyler-showcase-global-overlay-modal">
        <div class="ridestyler-showcase-modal-content ridestyler-showcase-global-overlay-modal-content" v-bind:class="{ messagevisible : message !== undefined, prompt : isPrompt }">
            <template v-if="isExitable !== undefined">
            <button type="button" class="ridestyler-showcase-modal-close" title="Close" aria-label="Close" v-on:click="closeModal">
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="svg-inline--fa fa-times fa-w-11" viewBox="0 0 352 512" focusable="false" id="ridestyler-showcase-icon-times-solid"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
            </button>
            </template>
            <template v-if="component !== undefined">
                <component :is="component" />
            </template>
            <template v-else-if="html !== undefined">
                <div class="ridestyler-showcase-global-overlay-html-wrapper" v-html="html"></div>
            </template>
            <template v-else>
                <div class="ridestyler-showcase-global-overlay-message-wrapper">
                    <div class="ridestyler-showcase-global-overlay-modal-title" v-if="title !== undefined">{{ title }}</div>
                    <img class="ridestyler-showcase-global-overlay-modal-loader" v-if="isLoader" src="//static.ridestyler.com/images/loaders/loader_radial_chaser_back_on_white_32.gif" v-bind:class="{ 'has-message': message }"/>
                    <div class="ridestyler-showcase-global-overlay-modal-message" v-if="message !== undefined">
                        <p v-html="message"></p>
                    </div>
                    <button type="button" class="ridestyler-showcase-global-overlay-modal-cancel ridestyler-showcase-button ridestyler-showcase-button-large ridestyler-showcase-button-secondary" v-if="isPrompt && isCancel" v-on:click="closeModal">Close</button>
                    <button type="button" class="ridestyler-showcase-global-overlay-modal-confirm ridestyler-showcase-button ridestyler-showcase-button-large" v-if="isConfirm" v-on:click="onConfirm">{{ confirmText || "Confirm"}}</button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';

    export default {
        methods: {
            onConfirm(){
                this.isConfirm();
                this.closeModal();
            },
            ...mapMutations({
                closeModal: 'globaloverlay/hideModal',
            }),
        },
        computed: {
            ...mapState({
                component: state => state.globaloverlay.component,
                html: state => state.globaloverlay.html,
                message: state => state.globaloverlay.message,
                title: state => state.globaloverlay.title,
                isConfirm: state => state.globaloverlay.isConfirm,
                isCancel: state => state.globaloverlay.isCancel,
                isPrompt: state => state.globaloverlay.isPrompt,
                isLoader: state => state.globaloverlay.isLoader,
                isExitable: state => state.globaloverlay.isExitable,
                isVisible: state => state.globaloverlay.isVisible,
                whenLoaded: state => state.globaloverlay.whenLoaded,
                confirmText: state => state.globaloverlay.confirmText
            }),
        },
        mounted(){
            if(this.whenLoaded){
                this.whenLoaded(this.$el);
            }
        }
    }
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-modal.ridestyler-showcase-global-overlay-modal {
        .ridestyler-showcase-global-overlay-modal-loader {
            max-width: 200px;
            position: absolute;
            display: inline-block;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto !important;

            &.has-message {
                bottom: 68px;
            }
        }
        .ridestyler-showcase-global-overlay-message-wrapper {
            height: auto;
            width: 100%;

            button {
                margin: 10px;
            }
        }
        .ridestyler-showcase-global-overlay-modal-message {
            font-size: 1.7em !important;
            width: 100%;
            margin: auto !important;
            height: auto;
            position: relative;
        }
        .ridestyler-showcase-global-overlay-modal-message p {
            height: auto;
            text-align: center;
            display: inline-block;
            padding: 3em 3em !important;
            position: relative;
        }
        .ridestyler-showcase-global-overlay-modal-title {
            top: 10px;
            font-weight: bold !important;
            position: absolute;
            font-size: 2em !important;
            width: 100%;
        }
    }
}
</style>
