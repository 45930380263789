import StyleManager from './StyleManager';
import Settings from './Settings'
import {initialize as initializeStore} from './store';
import store from './store';

export default {
    run: function () {
        const authFailed = new Promise(resolve => ridestyler.events.on("auth-failed", resolve));
        
        Promise.race([
            authFailed,
            Promise.all([
                StyleManager.initialize.call(this),
                initializeStore.call(this),
                Settings.initialize.call(this).then(async () => {
                    if(Settings.tiresEnabled && store.state.subscription.Description != 'Visualizer Standard') { 
                        let actionSettings = {isDisabled: false, isVisible: true, key:"tirepackage"},
                            tireAction = this.$showcasePlugins.registerShowcaseAction('tire', 'Add A Matching Tire Package', function(){store.commit('setSelectedCustomizationTab', 'tire')}, actionSettings);
                        
                        store.watch(function(state) {
                            return state.availableTireCount && !state.selectedTireModel;
                        }, function (tireCount) {
                            actionSettings.isVisible = tireCount;
                            tireAction.isVisible = tireCount;
                        });
                    } 
                }),
            ])
        ]).then(() => 
            this.$emit('initialized')
        );
    }
};