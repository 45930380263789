<template>
    <Popover class="ridestyler-showcase-share-button" v-bind:isOpen.sync="opened">
        <button type="button" :title="translate('shareButton')" :aria-label="translate('shareButton')" aria-haspopup="true" v-on:click="onButtonClick" v-bind:disabled="!userHasProductSelections" v-bind:aria-expanded="opened ? 'true' : 'false'">
            <LoadingIndicator v-bind:inline="true" v-if="loading" />
            <span v-else-if="!loading && !isPortrait">{{translate('shareButton')}}</span>
            <SvgIcon v-else-if="!loading && isPortrait" name="share" :title="translate('shareButton')" focusable="false" />
        </button>

        <template v-slot:popover>
            <div v-show="showingEmail">
                <div class="ridestyler-showcase-share-email-header">
                    <button type="button" v-on:click="showingEmail = false">
                        <SvgIcon name="chevron-left" title="Show All Share Options" />
                    </button>

                    Share via Email
                </div>

                <form class="ridestyler-showcase-share-email-form" v-on:submit.prevent="sendEmail">
                    <label>
                        <input type="email"  v-model="emailToAddress" placeholder="Enter recipient's email" aria-label="Enter recipient's email" />
                    </label>

                    <button class="ridestyler-showcase-button" ref="sendEmailButton" type="submit" v-bind:disabled="sendingEmail || !emailToAddress">Send</button>
                </form>
            </div>
            <div v-show="!showingEmail">
                <div class="ridestyler-showcase-share-button-networks">
                    <button :title="network.title" :aria-label='network.title'  type="button" class="ridestyler-showcase-button ridestyler-showcase-share-button-network" v-for="network in networks" v-bind:key="network.key" v-on:click="onNetworkButtonClick(network)">
                        <SvgIcon focusable="false" v-bind:name="network.icon" v-bind:title="network.title" />
                    </button>
                </div>
                <img v-bind:src="qrImageSrc" alt="QR Code" />
                <input aria-label="Share URL" type="text" ref="input" v-bind:value="shareURL" v-on:focus="selectInput" readonly v-on:click="selectInput" />
            </div>
        </template>
    </Popover>
</template>

<script>
import Settings from '../Settings';
import SvgIcon from './SvgIcon'
import LoadingIndicator from './LoadingIndicator';
import Popover from './Popover';

import { mapGetters, mapState } from 'vuex';
import RideStylerAPIRequest from '../RideStylerAPIRequest';

let shareButtonID = 0;

/**
 * @param {string} url
 * @param {object} [settings]
 * @param {number} [settings.width=420]
 * @param {number} [settings.height=550]
 * @param {boolean} [settings.enableScrollbars=true]
 * @param {boolean} [settings.enableResizeable=true]
 * @param {boolean} [settings.enableToolbar=false]
 * @param {boolean} [settings.enableLocation=true]
 * @param {string} [settings.target="share"]
 */

function openNewWindow(url, settings) {
    settings = settings || {};

    const defaultHeight = 420,
            defaultWidth = 550;

    const width = settings.width || defaultWidth,
            height = settings.height || defaultHeight;

    const screenHeight = screen.height,
            screenWidth = screen.width;

    const top = screenHeight > height ? Math.round((screenHeight / 2) - (height / 2)) : 0,
            left = Math.round((screenWidth / 2) - (width / 2));

    const target = settings.target || 'share';

    const windowSettings = [];
    const addSetting = (name, value) => windowSettings.push(name + '=' + value);
    const addBooleanSetting = (name, enabled, enabledByDefault) => {
        if (typeof enabled !== 'boolean') enabled = enabledByDefault;

        addSetting(name, enabled ? 'yes' : 'no');
    };

    addBooleanSetting('scrollbars', settings.enableScrollbars, true);
    addBooleanSetting('resizable', settings.enableResizeable, true);
    addBooleanSetting('toolbar', settings.enableToolbar, false);
    addBooleanSetting('location', settings.enableLocation, true);

    addSetting('width', width.toString());
    addSetting('height', height.toString());
    addSetting('top', top.toString());
    addSetting('left', left.toString());

    window.open(url, target, target !== '_blank' ? windowSettings.join(',') : '');
}

export default {
    created() {
        this.id = shareButtonID++;
        this.shareRedirectURL = (function () {
            let appURL = 'https://app.ridestyler.net';

            if (Settings.rideStylerEnvironment === 'alpha') appURL = 'http://app-alpha.ridestyler.net';
            else if (Settings.rideStylerEnvironment === 'beta') appURL = 'http://app-beta.ridestyler.net';

            return appURL + '/shared.html';
        })();
    },
    data: function () {
        return {
            loading: false,
            needsReload: true,
            shareURL: undefined,
            emailToAddress: undefined,
            sendingEmail: false,
            networks: [
                {
                    key: 'facebook',
                    icon: 'facebook',
                    title: 'Share with Facebook'
                },
                {
                    key: 'twitter',
                    icon: 'twitter',
                    title: 'Share with Twitter'
                },
                {
                    key: 'email',
                    icon: 'envelope-solid',
                    title: 'Share with QR Code'
                }
            ],
            opened: false,
            showingEmail: false,
            qrImageSrc: undefined
        };
    },
    methods: {
        async loadShareURL() {
            this.loading = true;

            this.qrImageSrc = ridestyler.ajax.url('Share/Vehicle', Object.assign({
                Mode: 'QR',
                PixelsPerModule: 3,
                To: this.emailToAddress
            }, this.currentVehicleRenderInstructions));
            this.shareURL = await RideStylerAPIRequest('Share/Vehicle', this.currentVehicleRenderInstructions, 'Url');

            this.loading = false;
            this.needsReload = false;
            this.opened = true;
        },
        selectInput() {
            this.$refs.input.select();
        },
        onButtonClick() {
            if (this.opened) {
                this.opened = false;
                return;
            }

            if (this.needsReload) this.loadShareURL();
            else this.opened = true;
        },
        onNetworkButtonClick(network) {
            switch (network.key) {
                case 'facebook': this.shareFacebook(); break;
                case 'twitter': this.shareTwitter(); break;
                case 'email': this.shareEmail(); break;
            }
        },
        shareFacebook() {
            let url = 'https://www.facebook.com/dialog/share?';
            const facebookDialogParameters = [
                'app_id=1576380412582845',
                'display=popup',
                'href=' + encodeURIComponent(this.shareURL),
                'redirect_uri=' + encodeURIComponent(this.shareRedirectURL)
            ];

            // Facebook only lets you specify one hashtag, so we use the first
            if (this.tags && this.tags.length)
                facebookDialogParameters.push('hashtag=' + encodeURIComponent('#' + this.tags[0]));

            url += facebookDialogParameters.join('&');

            openNewWindow(url);
        },
        shareTwitter() {
            let url = 'https://twitter.com/intent/tweet?';
            const twitterIntentParameters = [
                'url=' + encodeURIComponent(this.shareURL)
            ];

            if (this.message)
                twitterIntentParameters.push('text=' + encodeURIComponent(this.message));

            if (this.tags && this.tags.length)
                twitterIntentParameters.push('hashtags=' + this.tags.map(tag => encodeURIComponent(tag)).join(','));

            url += twitterIntentParameters.join('&');

            openNewWindow(url);
        },
        shareEmail() {
            this.showingEmail = true;
        },
        sendEmail() {
            const sendEmailButton = this.$refs.sendEmailButton;

            this.sendingEmail = true;

            RideStylerAPIRequest("Share/Vehicle", Object.assign({
                Mode: 'Email',
                To: this.emailToAddress
            }, this.currentVehicleRenderInstructions))
                .then(
                    () => {
                        this.emailToAddress = '';

                        sendEmailButton.style.backgroundColor = 'rgb(0,180,0)';
                        sendEmailButton.style.borderColor = 'rgb(0,100,0)';
                        sendEmailButton.textContent = 'Sent!';
                    },
                    () => {
                        sendEmailButton.style.backgroundColor = 'rgb(180,0,0)';
                        sendEmailButton.style.borderColor = 'rgb(100,0,0)';
                        sendEmailButton.textContent = 'Error';
                    }
                )
                .finally(() => {
                    this.sendingEmail = false;

                    setTimeout(function () {
                        sendEmailButton.style.backgroundColor = sendEmailButton.style.borderColor = '';
                        sendEmailButton.textContent = 'Send';
                    }, 5000);
                })
        }
    },
    computed: {
        tags() {
            const tags = ['vehicle'];

            if ('WheelFitment' in this.currentVehicleRenderInstructions) tags.push('wheels');
            if ('TireFitment' in this.currentVehicleRenderInstructions) tags.push('tires');

            return tags;
        },
        message() {
            return 'Check out my vehicle!';
        },
        ...mapGetters(['currentVehicleRenderInstructions', 'userHasProductSelections']),
        ...mapState(['breakpoint', 'isPortrait'])
    },
    watch: {
        currentVehicleRenderInstructions() {
            this.needsReload = true;

            if (this.opened) {
                this.opened = false;
                this.loadShareURL();
            }
        },
        opened(isOpened) {
            if (isOpened) this.showingEmail = false;
        }
    },
    components: {
        SvgIcon,
        LoadingIndicator,
        Popover
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {

    .ridestyler-showcase-top-bar .ridestyler-showcase-share-button button[type="submit"] {
        margin: 0;
        width: 80%;
    }

    .ridestyler-showcase-share-button {
        height: 100%; width: 100%;

        .ridestyler-showcase-icon-share {
            color: $secondary-button-color;
        }

        .ridestyler-showcase-share-button-networks {
            button {
                width: auto;
            }
        }

        .ridestyler-showcase-share-button-network {
            padding: 1px 0.5em 0.2em;
            margin: 0px 6%;
            svg {
              vertical-align: middle;
            }
        }

        .ridestyler-showcase-loading-indicator {
            width: 1.3em;
            height: 1.3em;
            padding: 0;
        }

        .ridestyler-showcase-popover-content {
            width: 14em;
            margin-left: -8em;
            right: 0;
            top: 41px;
            text-align: center;

            &:before {
                right: 10px;
            }
        }

        .ridestyler-showcase-loading-indicator-inline {
            margin: 0px;
        }

        img {
            margin: 8px 0;
            max-width: 100%;
        }

        input {
            width: 88%;
            font-size: .9em;
            margin: 0 auto 0.5em;
            display: block;
        }

        button {
            position: relative;
            height: 100%; width: 100%;
            padding: 0 1.5em;
            vertical-align: middle;
            color: white;
            background: $secondary-button-color;
            border-radius: 0;
            border: none;
            .ridestyler-showcase-icon {
                width: 1em;
                height: 1em;
            }

            @include focusable;

            
        }
    }

    .ridestyler-showcase-share-email-form {
        $labelSize: 20%;
        margin-top: 10px;
        label > span {
            display: inline-block;
            width: $labelSize;
        }
        input {
            width: 100% - $labelSize;
        }
    }

    .ridestyler-showcase-share-button-networks {
        white-space: nowrap;
        height: auto;
        margin-bottom: .8em;
        display: flex;
        justify-content: space-evenly;
    }

    .ridestyler-showcase-share-email-header {
        text-align: center;
        position: relative;

        button {
            position: absolute;
            left: 0; bottom: 0;
            border: none;
            background: none;
        }

        .ridestyler-showcase-icon {
            width: 1em; height: 1em;
        }
    }

    &.ridestyler-showcase-breakpoint-xs {
        .ridestyler-showcase-share-button .ridestyler-showcase-icon-share {
            color: white;
        }
        .ridestyler-showcase-share-button-networks button {
            background-color: $secondary-button-color;
        }
    }
    &.ridestyler-showcase-breakpoint-portrait {
        &.ridestyler-showcase-breakpoint-xs, &.ridestyler-showcase-breakpoint-sm, &.ridestyler-showcase-breakpoint-md {
            .ridestyler-showcase-share-button button {
                background-color: transparent;
                width: auto; height: 100%;
                padding: 0 0.5em;
                svg {
                    width: 1.7em; height: 1.7em;
                    vertical-align: middle;
                }
            }
            .ridestyler-showcase-share-button-networks {
                .ridestyler-showcase-share-button-network {
                    background-color: $secondary-button-color;
                    padding: 0.2em 0.5em;
                    width: auto;
                    height: 100%;
                    svg {
                        width: 1em; height: 1em;
                    }
                }
            }
            .ridestyler-showcase-share-button .ridestyler-showcase-icon-share {
                color: $secondary-button-color;
            }
        }
        .ridestyler-showcase-share-button img {
            margin: 0 auto 3.5px;
        }
    }
}
</style>
