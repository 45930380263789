<template>
    <transition name="fade">
        <Modal class="ridestyler-showcase-paint-customizer-modal" v-if="paintModalVisible" v-bind:close-callback="closeModal">
            <VehiclePaintCustomizer v-bind:vehicleConfiguration="vehicleConfiguration" v-bind:initialColor="paintColor" v-on:paint-color-selected="onPaintColorSelected" />
        </Modal>
    </transition>
</template>

<script>
import Modal from './Modal'
import VehiclePaintCustomizer from './VehiclePaintCustomizer'
import { mapState, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapState([
            'vehicleConfiguration',
            'paintColor'
        ]),

        ...mapState({
            paintModalVisible: state => state.visualize.paintModalVisible,
        })
    },
    methods: {
        ...mapMutations({
            onPaintColorSelected: 'setPaintColor',
            closeModal: 'visualize/hidePaintModal'
        })
    },
    components: {
        Modal,
        VehiclePaintCustomizer
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    &.ridestyler-showcase-page-visualize {
        .ridestyler-showcase-paint-customizer-modal {
            .ridestyler-showcase-vehicle-paint-customizer {
                .ridestyler-showcase-viewport {
                    margin: 0 auto 3%;
                }
                .ridestyler-showcase-paint-swatch {
                    width: 3.5em; height: 3.5em;
                }
                .ridestyler-showcase-product-gallery-product-label {
                    font-size: 1em;
                }
                .ridestyler-showcase-product-gallery-product-select {
                    height: 0.2em;
                }
                .ridestyler-showcase-product-gallery {
                    padding: 0 4.3%;
                }
                .ridestyler-showcase-product-gallery-wrapper {
                    &.fade-after {
                        &::after {
                            right: 4.3%;
                        }
                    }
                    &.fade-before {
                        &::before {
                            left: 4.3%;
                        }
                    }
                }
            }
        }
    }
}
</style>
