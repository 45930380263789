/** @typedef {import("../state").RideStylerShowcaseState} RideStylerShowcaseState */

/**
 * @typedef GlobalOverlayModuleState
 * @prop {boolean} isLoader
 * @prop {boolean} isVisible
 * @prop {boolean} isConfirm
 */

/** @type {GlobalOverlayModuleState} */
const state = {
    isLoader: false,
    message: undefined,
    title: undefined,
    isVisible: false,
    isConfirm: false,
    isCancel: true,
    isPrompt: false,
    confirmText: undefined,
    isExitable: true,
    whenLoaded: undefined,
    component: undefined,
    html: undefined
};

/**
 * @type {import("../../../node_modules/vuex/types/index").MutationTree<any>}
 */
const mutations = {
    showComponent(state, component){ state.component = component; state.isVisible = true; },
    showMessage(state, payload){ state.message = payload.message; state.title = payload.title; state.isVisible = true; },
    showPrompt(state, payload){ state.isPrompt = true; state.message = payload.message; state.isConfirm = payload.callback; state.confirmText = payload.confirmText; state.isVisible = true; state.isCancel = payload.cancel },
    showHTML(state, payload){ state.html = payload.html; state.whenLoaded = payload.whenLoaded; state.isVisible = true; },
    showModal(state){ state.isVisible = true; },
    hideModal(state){ state.component = undefined; state.html = undefined; state.message = undefined; state.title = undefined; state.isConfirm = false; state.isCancel = false; state.isPrompt = false; state.isLoader = false; state.isExitable = true; state.isVisible = false; state.whenLoaded = undefined; state.confirmText = undefined; },
    showLoader(state){ state.isLoader = true; state.isVisible = true; },
    hideLoader(state){ state.isLoader = false; state.isVisible = false; },
};

export default {
    namespaced: true,
    state,
    mutations
};
