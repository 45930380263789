<template>
    <button type="button" class="ridestyler-showcase-tab-bar-tab" v-bind:class="classList" role="tab" v-bind:aria-selected="selected">
        <span class="ridestyler-showcase-tab-bar-tab-label" v-text="label"></span>
    </button>
</template>

<script>

export default {
    props: {
        label: String,
        icon: String,
        selected: Boolean
    },
    computed: {
        classList() {
            const classes = [];

            if (this.selected) classes.push('ridestyler-showcase-tab-bar-tab-selected');

            return classes;
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    $colorSelected: #fff;
    $colorUnselected: #aaa;

    .ridestyler-showcase-tab-bar-tab {
        cursor: pointer;
        width: auto;
        height: 100% !important;
        position: relative;
        text-align: center;
        background: none;
        color: $colorUnselected;
        padding: 0 1.8em;
        margin: 0;
        border-top-right-radius: 0.3em; border-top-left-radius: 0.3em;
        border-color: transparent;
        outline: none;
        @include focusable;
        pointer-events: auto;
        
    }
    .ridestyler-showcase-tab-bar-tab-selected {
        background: $secondary-background-color;
        border-top: 2px solid darken($secondary-background-color, 5%);
        border-right: 2px solid darken($secondary-background-color, 5%);
        border-left: 2px solid darken($secondary-background-color, 5%);
        border-bottom: 2px solid transparent;
    }

    .ridestyler-showcase-tab-bar-tab-label {
        display: inline-block;
        font-size: 1.2em;
        color: $secondary-button-color;
    }

    &.ridestyler-showcase-breakpoint-md {

    }

    &.ridestyler-showcase-breakpoint-sm {

    }

    &.ridestyler-showcase-breakpoint-xs {

    }
}
</style>
