<template>
    <fieldset
        class="ridestyler-showcase-select-box"

        role="listbox"
        v-bind:id="groupName"
        v-bind:aria-labelledby="groupName + '-title'"
        v-on:keydown="onKeyDown">

        <legend class="ridestyler-showcase-select-box-title" v-if="title || $slots.title" v-bind:id="groupName + '-title'">
            <slot name="title">
                {{title}}
            </slot>
        </legend>

        <div class="ridestyler-showcase-select-box-options" ref="options">
            <div class="ridestyler-showcase-select-box-option" v-for="option in options"
              v-bind:key="option.value" v-bind:aria-selected="valueIsSelected(option.value)"  v-on:touchstart="focusTargetOption" v-on:touchend="focusTargetOption" >
                <label>
                    <input class="radio" role="option" v-bind:type="multi ? 'checkbox' : 'radio'" v-bind:name="groupName" v-bind:value="option.value" v-bind:checked="valueIsSelected(option.value)" v-on:change="onInputStateChanged">
                    <span v-text="option.label"></span>
                </label>
            </div>
        </div>

        <LoadingIndicator v-if="isLoading" />
    </fieldset>
</template>

<script>

import LoadingIndicator from "./LoadingIndicator";

let selectBoxID = 1;

function normalizeValue(value) {
    if (typeof value === 'undefined') return [];
    if (Array.isArray(value)) return value;
    return [value];
}

/**
 * @typedef Option
 * @prop {string} label
 * @prop {any} value
 */

/**
 * @param {HTMLElement} element
 */
function isOption(element) {
    return element.classList.contains('ridestyler-showcase-select-box-option');
}

/**
 * @param {HTMLElement} element
 */
function isOptionInput(element) {
    const optionElement = getOptionElement(element);
    return optionElement && isOption(optionElement);
}

/**
 * @param {HTMLInputElement} inputElement
 */
function getOptionElement(inputElement) {
    return inputElement.parentElement.parentElement;
}

/**
 * @param {HTMLElement} optionElement
 * @returns {HTMLInputElement}
 */
function getOptionInput(optionElement) {
    return optionElement.firstElementChild.firstElementChild;
}

export default {
    data () {
        return {
            values: []
        };
    },
    created() {
        this.groupName = 'ridestyler-showcase-select-box-' + selectBoxID++;
        this.keysSoFar = '';
    },
    mounted() {
        this.focusFirstOption();
    },
    props: {
        multi: {
            type: Boolean,
            default: false
        },
        title: String,
        options: {
            type: Array,
            required: true
        },
        selectedValue: [String, Array, Number],
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /** @returns {HTMLInputElement[]} */
        selectedInputs() {
            if (this.values.length === 0) return [];
            return this.findInputElements(input => this.valueIsSelected(input.value));
        },
        /** @type {HTMLElement[]} */
        selectedOptions() {
            return this.selectedInputs.map(getOptionElement);
        }
    },
    methods: {
        /**
         * @param {(input:HTMLInputElement)=>boolean} [filter]
         * @returns {HTMLInputElement[]}
         */
        findInputElements(filter) {
            const inputs = this.$el.querySelectorAll('input');
            const matchingInputs = [];

            if (typeof filter !== 'function') return inputs;

            for (let i = 0; i < inputs.length; i++) {
                const input = inputs[i];

                if (filter(input)) {
                    matchingInputs.push(input);
                }
            }

            return matchingInputs;
        },
        /**
         * @param {(input:HTMLInputElement)=>boolean} [inputElementFilter]
         * @returns {HTMLElement[]}
         */
        findOptionElements(inputElementFilter) {
            return this.findInputElements(inputElementFilter).map(getOptionElement);
        },
        valueIsSelected(value) {
            return this.values.some(v => value == v);
        },
        getSelectedValues() {
            const values = [];
            const inputs = this.$el.querySelectorAll('input');

            for (let i = 0; i < inputs.length; i++) {
                const input = inputs[i];

                if (!input.checked) continue;

                values.push(input.value);
            }

            return values;
        },
        onInputStateChanged() {
            this.values = this.getSelectedValues();
            this.$emit('selection', this.values);
        },
        onKeyDown(e) {
            let key = e.which || e.keyCode;

            switch (key) {
                case 38: // up
                    this.focusPreviousOption();
                    break;
                case 40: // down
                    this.focusNextOption();
                    break;
                case 36: // home
                    this.focusFirstOption();
                    break;
                case 35: // end
                    this.focusLastOption();
                    break;
                case 32: // space
                case 13: // enter
                    this.toggleFocusedOption();
                    break;

                case 9: // tab
                    return;

                default: {
                    // Numpad keys
                    if (key >= 96 && key <= 105) {
                        key -= 48;
                    }

                    const character = String.fromCharCode(key);
                    const inputs = Array.from(this.$el.querySelectorAll('input'));

                    if (!this.keysSoFar) {
                        if (this.selectedInputs.length) this.searchIndex = inputs.indexOf(this.selectedInputs[0]);
                        else this.searchIndex = 0;
                    }

                    this.keysSoFar += character;

                    // Clear keysSoFar after delay
                    {
                        if (this.keyClear) {
                            clearTimeout(this.keyClear);
                            this.keyClear = undefined;
                        }

                        this.keyClear = setTimeout(() => {
                            this.keysSoFar = '';
                            this.keyClear = undefined;
                        }, 750)
                    }

                    const findMatchWithinRange = (start, end) => {
                        for (let i = start; i < end; i++) {
                            const input = inputs[i];
                            const option = getOptionElement(input);
                            const label = option.innerText;

                            if (label && label.toUpperCase().indexOf(this.keysSoFar) === 0) {
                                return input;
                            }
                        }
                    };

                    let nextMatch = findMatchWithinRange(this.searchIndex + 1, inputs.length);

                    if (!nextMatch) nextMatch = findMatchWithinRange(0, this.searchIndex);

                    if (nextMatch) nextMatch.focus();

                    break;
                }
            }

            e.preventDefault();
        },
        onFocused() {
            if (this.selectedOptions.length) {
                getOptionInput(this.selectedOptions[0]).focus();
            } else {
                this.focusFirstOption();
            }
        },
       
        /**
         * @param {Event} event
         */
        focusTargetOption(event) {
            const option = event.currentTarget;

            if (!isOption(option)) return;

            getOptionInput(option).focus();
        },
        toggleFocusedOption() {
            const focusedElement = document.activeElement;

            // If there's an option focused, toggle its state
            if (isOptionInput(focusedElement)) {

                focusedElement.checked = !focusedElement.checked;
                this.onInputStateChanged();
            }
        },
        focusNextOption() {
            const focusedElement = document.activeElement;

            // If there's an option focused, and an option after it,
            // focus on the option
            if (isOptionInput(focusedElement)) {
                const optionElement = getOptionElement(focusedElement);
                const nextElement = optionElement.nextSibling;

                if (nextElement) {
                    getOptionInput(nextElement).focus();
                    return;
                }
            }

            this.focusFirstOption();
        },
        focusPreviousOption() {
            const focusedElement = document.activeElement;

            // If there's an option focused, and an option before it,
            // focus on the option
            if (isOptionInput(focusedElement)) {
                const optionElement = getOptionElement(focusedElement);
                const previousElement = optionElement.previousSibling;

                if (previousElement) {
                    getOptionInput(previousElement).focus();
                    return;
                }
            }

            // Otherwise focus on the last option
            this.focusLastOption();
        },
        focusFirstOption() {
            /** @type {HTMLElement} */
            const optionContainer = this.$refs.options;
            const firstChild = optionContainer.firstChild;

            if (firstChild) getOptionInput(firstChild).focus();
        },
        focusLastOption() {
            /** @type {HTMLElement} */
            const optionContainer = this.$refs.options;
            const lastChild = optionContainer.lastChild;

            if (lastChild) getOptionInput(lastChild).focus();
        }
    },
    watch: {
        selectedValue: {
            immediate: true,
            handler() {
                this.values = normalizeValue(this.selectedValue);
            }
        }
       
    },
    components: {
        LoadingIndicator
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {

    &.ridestyler-showcase-breakpoint-portrait.ridestyler-showcase-page-select {
      .ridestyler-showcase-select-box-options {
        height: auto;
        max-height: 42vh;
        margin-bottom: 10px;
      }
    }

    .ridestyler-showcase-select-box {
        position: relative;
        outline: none;
    }

    .ridestyler-showcase-select-box-title {
        margin: 0;
        padding: 0;
        float: left;
        width: 100%;
        box-sizing: border-box;
        font-size: 1.5em;
    }

    .ridestyler-showcase-select-box-options {
        @include var(background-color, secondary-background-color);
        border-radius: 0.5em;
        // padding: 10px 0;
        display: block;
        position: absolute;
        top: 3.25em;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        font-size: 1.2em;
        transition: height 500ms ease-in-out;

        &:empty {
            height: 30px;
        }
    }

    .ridestyler-showcase-select-box-option {
        display: block;
        text-align: center;

        label {
            display: block;
            position: relative;

        }

        span {
            padding: 9px;
            display: block;
            cursor: pointer;
            position: relative;;

            // Hover State
            &:hover {
                @include var(background-color, secondary-active-background-color);

                @media (hover: none) {
                    background-color: transparent;
                }
            }
        }

        input.radio {
          position: absolute;
          top:0;
          left:0;
          right:0;
          bottom:0;
          overflow: hidden;
          opacity: 0;
          margin: 0;
          height: 100%;
        }

        // Selected State
        input:checked + span {
            @include var(background-color, primary-color);
            color: #fff;
        }

        input:focus + span {
            outline: none;
            @include var(background-color, secondary-active-background-color);
        }
    }
}
</style>
