import RideStylerAPIRequest from './RideStylerAPIRequest';
import store from './store';
import Vue from 'vue'
import state from "./store/state";

async function loadOrganizationSettings() {
    const organizationSettings = await RideStylerAPIRequest('Client/GetSettings', undefined, 'Settings');

    settings.useRawTireDescription = organizationSettings.InterfaceUseRawTireDescription;
    settings.enableSharing = organizationSettings.InterfaceEnableSharing;
    settings.suspensionRanges = organizationSettings.ConfigSuspensionRanges;
    settings.tireTruckMinusSizeVariation = organizationSettings.ConfigTireTruckMinusSizeVariation;
    settings.tireTruckPlusSizeVariation = organizationSettings.ConfigTireTruckPlusSizeVariation;
    settings.disableAngledVehicles = organizationSettings.ConfigDisableAngledVehicles;
    settings.resetAfterIdleSeconds = organizationSettings.ConfigResetAfterIdleSeconds;
    settings.enableRequestQuote = organizationSettings.InterfaceEnableQuotes;
    settings.integrations = organizationSettings.ActiveIntegrations;
    settings.poweredByMessage = organizationSettings.ShowPoweredByMessage;
    settings.showPricing = organizationSettings.ShowPricing;
    settings.lowWheelStockWarning = organizationSettings.LowWheelStockWarning;
    settings.lowTireStockWarning = organizationSettings.LowTireStockWarning;
    settings.showInventory = organizationSettings.ShowInventory;
   
    settings.showWheelInventoryValues = organizationSettings.ShowWheelInventoryQuantities;
    settings.showTireInventoryValues = organizationSettings.ShowTireInventoryQuantities;

    settings.noWheelStockWarning = organizationSettings.NoWheelStockWarning;
    settings.noTireStockWarning = organizationSettings.NoTireStockWarning;

    
    if( settings.showInventory && !settings.lowTireStockWarning) {
        settings.lowTireStockWarning = 0;
    }
    if( settings.showInventory && !settings.lowWheelStockWarning) {
        settings.lowWheelStockWarning = 0;
    }
    if( settings.showInventory && !settings.noWheelStockWarning) {
        settings.noWheelStockWarning = 0;
    }
    if( settings.showInventory && !settings.noTireStockWarning) {
        settings.noTireStockWarning = 0;
    }

    if (settings.disableAngledVehicles) {
        settings.defaultVehicleAngle = 'side';
    }
    
    if( organizationSettings.StringOverrides != null ) {
        store.commit("updateTranslations", {
            ...state.translations, ...organizationSettings.StringOverrides
        });
    }

}

function loadTheme() {
    return new Promise((resolve) => {
        ridestyler.ajax.send({
            action: 'Client/GetTheme',
            callback: response => {
                // Skip loading the theme - but do not return an error if there 
                // is a problem loading the theme for an Organization
                if (!response.Success) return resolve();

                const theme = settings.theme = response.Theme || settings.theme;

                if (theme.CustomCSS || theme.GeneratedCSS) {
                    let styleBlock = document.createElement('style');
                    if (theme.CustomCSS) styleBlock.innerHTML += theme.CustomCSS;
                    if (theme.GeneratedCSS) styleBlock.innerHTML += theme.GeneratedCSS;

                    document.getElementsByTagName('head')[0].appendChild(styleBlock);
                }

                resolve();
            }
        })
    });   
}

function loadEnabledProducts() {
    const resolveCountPromise = (action, settingsKey) => new Promise((resolve) => {
        ridestyler.ajax.send({
            action: action,

            callback: response => {
                settings[settingsKey] = response.Success && response.Count > 0;
                store.commit('set' + settingsKey.charAt(0).toUpperCase() + settingsKey.slice(1), response.Success && response.Count > 0);

                resolve();
            }
        });
    });

    const tireOptionsLoaded = resolveCountPromise('tire/countbrands', 'tiresEnabled');
    const wheelOptionsLoaded = resolveCountPromise('wheel/countbrands', 'wheelsEnabled');

    return Promise.all([tireOptionsLoaded, wheelOptionsLoaded]);
}

function detectRideStylerEnvironment() {
    const url = ridestyler.ajax.url('', undefined);

    if (/\/api-alpha\./i.test(url)) settings.rideStylerEnvironment = 'alpha';
    else if (/\/api-beta\./i.test(url)) settings.rideStylerEnvironment = 'beta';
    else if (/\/api\./i.test(url)) settings.rideStylerEnvironment = 'live';
    else settings.rideStylerEnvironment = 'other';
}

function loadUserSubscription(){
    return new Promise((resolve) => {
        ridestyler.ajax.send({
            action: 'auth/status',
            callback: response => {
                if (!response.Success) return resolve();
                if(response.SubscriptionPlan) store.commit('setSubscription', response.SubscriptionPlan);
                resolve();
            }
        })
    });
}

/**
 * @typedef RideStylerClientTheme
 * @prop {string} CompanyName
 * @prop {any} Content
 * @prop {string} CustomCSS
 * @prop {string} GeneratedCSS
 * @prop {string} PrimaryColor
 */

/**
 * @typedef {'live'|'beta'|'alpha'|'other'} RideStylerAPIEnvironment
 */

/** 
 * Callbacks to be run when the settings is done initializing
 * @type {(()=>void)[]}
 */
const settingsInitializedCallbacks = [];
let isInitialized = false;

const settings = Vue.observable( {
    async initialize() {
        detectRideStylerEnvironment();
        
        await Promise.all([
            loadOrganizationSettings(),
            loadTheme(),
            loadUserSubscription(),
            loadEnabledProducts()
        ]);
        
        isInitialized = true;
        settingsInitializedCallbacks.forEach(cb => cb());
        settingsInitializedCallbacks.splice(0, settingsInitializedCallbacks.length);
    },

    /**
     * @param {()=>void} callback Callback to be ran after the settings are initialized
     */
    onInitialized(callback) {
        if (isInitialized) {
            callback();
            return;
        }

        settingsInitializedCallbacks.push(callback);
    },

    /** @type {import('./main').RideStylerShowcaseOptions} */
    options: {
        disableAutoContainerSizing:undefined,
        persistenceMode:undefined,
        locationReferenceCode:undefined,
        location:undefined,
        wheelFitmentIDFront:undefined,            
        wheelFitmentIDRear:undefined,
        rotateVehicle:undefined,
        tireOption:undefined,
        paintColor:undefined,
        vehicleConfiguration:undefined,
        disableChangeVehicle:undefined,
        disableMatchingTires:undefined,
        disableSuspension:undefined,
        showPartNumbers:undefined,
        selectPartNumber:undefined,
        partNumbersAreInventoryNumbers:undefined,
        translations:undefined,
        scrollWheelEnabled:true
    },

    /** @type {RideStylerAPIEnvironment} */
    rideStylerEnvironment: undefined,

    tiresEnabled: true,
    wheelsEnabled: true,

    enableSharing: true,

    disableAngledVehicles: false,

    enableRequestQuote: false,
    
    /** @type {number} */
    tireTruckMinusSizeVariation: undefined,
    /** @type {number} */
    tireTruckPlusSizeVariation: undefined,

    /** @type {import('./store/state').VehicleAngle} */
    defaultVehicleAngle: 'side',

    resetAfterIdleSeconds: 0,

    suspensionRanges: {
        Default: {
            Min: -4,
            Max: 0
        },
        Truck4WD: {
            Min: -4,
            Max: 4
        },
        Van4WD: {
            Min: -4,
            Max: 4
        }
    },
    useRawTireDescription: false,

    integrations: undefined,

    poweredByMessage: false,

    /** @type {RideStylerClientTheme} */
    theme: {}
});

export default settings;
