const mousewheelEventScale = -1/40;
const mouseEventListenerDataKey = '$_wheelListener';



const scrollEvent = (function () {
    const testElement = document.createElement('div');

    return 'onwheel' in testElement ? 'wheel' :                 // Modern browers support "wheel"
           document.onmousewheel !== undefined ? 'mousewheel' : // Webkit and IE support at least "mousewheel"
           'MozMousePixelScroll';                               // older Firefox
})();

/**
 * @param {HTMLElement} element 
 * @param {Function} listener 
 */
function attachWheelListener(element, listener) {
    removeWheelListener(element);

    if (scrollEvent === 'wheel') {
        element[mouseEventListenerDataKey] = (event) => {
            const normalizedEvent = Object.assign(event, {
                originalEvent: event
            });

            return listener(normalizedEvent);
        };
    } else {
        element[mouseEventListenerDataKey] = (event) => {
            if (!event) event = window.event;

            const normalizedEvent = {
                originalEvent: event,
                target: event.target || event.srcElement,
                type: 'wheel',
                deltaMode: event.type === 'MozMousePixelScroll' ? 0 : 1,
                deltaX: 0,
                deltaY: 0,
                deltaZ: 0,

                preventDefault: () => {
                    if (typeof event.preventDefault === 'function') event.preventDefault();
                    else {
                        event.returnValue = false;
                    }
                }
            };

            if (scrollEvent === 'mousewheel') {
                normalizedEvent.deltaY = mousewheelEventScale * event.wheelDelta;
                
                if (event.wheelDeltaX)
                    normalizedEvent.deltaX = mousewheelEventScale * event.wheelDeltaX;
            } else {
                normalizedEvent.deltaY = event.detail;
            }

            return listener(normalizedEvent);
        };
    }
    
    element.addEventListener(scrollEvent, element[mouseEventListenerDataKey]);
}

/**
 * @param {HTMLElement} element 
 */
function removeWheelListener(element) {
    if (mouseEventListenerDataKey in element === false) return;

    element.removeEventListener(scrollEvent, element[mouseEventListenerDataKey]);
}

/** @type {Vue.DirectiveOptions} */
const wheelDirective = {
    bind: function (el, binding) {
        if (typeof binding.value !== 'function') return;

        attachWheelListener(el, binding.value);
    },
    update: function(el, binding) {
        if (typeof binding.oldValue === 'function') removeWheelListener(el, binding.oldValue);
        if (typeof binding.value === 'function') attachWheelListener(el, binding.value);
    },
    unbind: function(el, binding) {
        if (typeof binding.value === 'function') removeWheelListener(el, binding.value);
    }
};

export default wheelDirective;