<template>
    <img class="ridestyler-showcase-product-image" v-bind:src="imageSrc" alt="">
</template>

<script>

var defaultImageValue = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
var observedElements = [];
setInterval(function() {
    for(var i = 0; i < observedElements.length; i++) {
        refreshElement(observedElements[i]);
    }
}, 200);

function refreshElement(vm) {
    var el = vm.$el;
    var w = el.clientWidth;
    var h = el.clientHeight;

    var maxSize = Math.max(w, h);
    if (maxSize == 0) return;

    if (vm.imageSize != maxSize) {
        vm.imageSize = maxSize;
    }
}

const maxResolution = 800;

export default {
    data: function () {
        return {
            imageSize: 0
        };
    },
    mounted: function() {
        var self = this;
        setTimeout(function() {
            refreshElement(self);
            observedElements.push(self);
        }, 0);
    },
    beforeDestroy: function() {
        var i = observedElements.indexOf(this);
        if (i !== -1) observedElements.splice(i, 1);
    },
    computed: {
        imageSrc() {
            var dpi = window.devicePixelRatio || 1;
            var imageResolution = Math.min(Math.floor(this.imageSize * dpi), maxResolution);

            if (imageResolution > 0 && this.product.HasCatalogImage) {
                if (this.productType === 'wheel') {
                    const wheelRender = {
                        Width: imageResolution,
                        Height: imageResolution,
                        IncludeShadow: true,
                        WheelFitmentResourceType: 3,
                        PositionX: 1,
                        PositionY: 1
                    };

                    if ('WheelFitmentID' in this.product) wheelRender.WheelFitment = this.product.WheelFitmentID;
                    else if ('WheelModelID' in this.product) wheelRender.WheelModel = this.product.WheelModelID;
                    else return defaultImageValue;

                    return ridestyler.ajax.url('wheel/image', wheelRender);
                } else if (this.productType === 'tire') {
                    const tireRender = {
                        Width: imageResolution,
                        Height: imageResolution,
                        IncludeShadow: true,
                        TireFitmentResourceType: "Catalog",
                        PositionX: 1,
                        PositionY: 1
                    };

                    if ('TireFitmentID' in this.product) tireRender.TireFitment = this.product.TireFitmentID;
                    else if ('TireModelID' in this.product) tireRender.TireModel = this.product.TireModelID;
                    else return defaultImageValue;

                    return ridestyler.ajax.url('tire/image', tireRender);
                }
            }

            return defaultImageValue;
        },
        productType() { return this.product.type; }
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
</style>
