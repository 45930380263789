/**
* @param {object} action
* @param {'selected-product'|'global'} action.type The type of action to register
* @param {string} action.label The label to use for this action
* @param {object} action.params Parameters on how to display/use this action
* @param {'wheel'|'tire'} action.params.type If action is a 'selected-product' action this dictates what type of product this action should be shown for
* @param {'wheel'|'tire'} action.params.location If action is a 'global' action this dictates where to show this action
* @param {(data:any)=>void} action.callback A function to call, preforming the action logic
* @param {(data:any)=>bool} action.isVisible If definied, the action will only be shown if this function returns true
* @param {(data:any)=>bool} action.isDisabled If defined, the action will be disabled if this function returns true
*/

export default class Action {
    type;
    label;
    callback;
    isVisible;
    isDisabled;

    constructor(action){
        this.type = action.type;
        this.label = action.label;
        this.callback = action.callback;
        this.isVisible = action.isVisible;
        this.isDisabled = action.isDisabled;
    }
}
