<template>
    <div class="ridestyler-showcase-product-gallery-product" v-bind:class="productClasses" tabindex="0" role="option" :aria-labelledby="ariaLabelID" >
        <!-- <div class="ridestyler-showcase-product-gallery-product-tags">
            <template v-if="!isStandardPlan">
                <div
                    v-for="tag in tags"
                    v-bind:key="tag.name"
                    v-bind:title="tag.description"
                    v-text="tag.name"
                    class="ridestyler-showcase-badge-label">
                </div>
            </template>
        </div> -->
        <template v-if="productType === 'wheel'">
            <!--<div class="ridestyler-showcase-product-gallery-lowstock" v-bind:class="lowStockClass" v-if="isLowStock">&#9888; <span class="tooltiptext" v-html="stockMessage"></span></div>-->
            <img class="ridestyler-showcase-product-gallery-product-wheel-image" v-bind:src="imageSrc" alt="">
            <div class="ridestyler-showcase-product-gallery-product-wheel-info">
               
                <div class="ridestyler-showcase-product-gallery-product-label" v-text="product.WheelBrandName" :id="labelID"></div>
                <div class="ridestyler-showcase-product-gallery-product-label-primary" v-text="product.WheelModelName" :id="labelPrimaryID"></div>
                <div class="ridestyler-showcase-product-gallery-product-label-secondary" v-text="productPrice"></div>
            </div>
            
            
        </template>
        <template v-else-if="productType === 'tire'">
            
            <SvgIcon class="ridestyler-showcase-product-gallery-product-not-visualizable-icon" name="eye-slash-solid" v-if="!product.HasRenderSideImage || (selectedWheelModel && !selectedWheelModel.HasSideImage)" />
            <!--<div class="ridestyler-showcase-product-gallery-lowstock" v-bind:class="lowStockClass" v-if="isLowStock">&#9888; <span class="tooltiptext" v-html="stockMessage"></span></div>-->
            <img class="ridestyler-showcase-product-gallery-product-tire-image" v-bind:src="imageSrc" alt="">
            <div class="ridestyler-showcase-product-gallery-product-tire-info">
               
                <div class="ridestyler-showcase-product-gallery-product-tire-divider"></div>
                <div class="ridestyler-showcase-product-gallery-product-label-size" v-text="tireDescription"></div>
                <div class="ridestyler-showcase-product-gallery-product-label" style='margin-top:0;' v-text="product.TireBrandName" :id="labelID"></div>
                <div class="ridestyler-showcase-product-gallery-product-label-primary" v-text="product.TireModelName" :id="labelPrimaryID"></div>
                <div class="ridestyler-showcase-product-gallery-product-label-secondary" v-text="productPrice"></div>
                
            </div>
        </template>
        <template v-else-if="productType === 'paint'">
            <span class="ridestyler-showcase-paint-swatch" v-bind:style="{backgroundColor: product.Colors[0].Hex}" role="presentation" />
            <div class="ridestyler-showcase-product-gallery-product-label" v-text="product.SchemeName" :id="labelID" ></div>
        </template>
        <div class="ridestyler-showcase-product-gallery-product-select" :class="{ 'selected' : selected }"></div>

    </div>
</template>

<script>
import SvgIcon from './SvgIcon';
import { mapState, mapGetters } from 'vuex';
import settings from '../Settings';
import { formatPrice } from '../Formatters';

export default {
    data: function () {
        return {
            imageWidth: 200,
            imageHeight: 200
        };
    },
    methods: {
        stock() {
            if( settings.showInventory) {
                if (this.productType === 'wheel') {
                    if( this.product.InventoryMin )
                        return this.product.InventoryMin;
                    else
                        return Infinity;
                } else if (this.productType === 'tire') {
                    if( this.product.InventoryMin )
                        return this.product.InventoryMin;
                    else
                        return Infinity;
                }
            }
            return -Infinity;
        }
    },
    computed: {
        st() {
            return this.stock();
        },
        stockMessage() {
            let q = this.stock();
            
            let out = 0;
            let isTire = this.productType === 'tire';
            if (!isTire) {
                out = settings.noWheelStockWarning;
            } else { 
                out = settings.noTireStockWarning;
            }

            if( q == Infinity){
                return  this.translate(isTire?"tireStockNull":"wheelStockNull" ) + "<br>(some sizes)";
            }
            else if (q <= out) {
                return this.translate(isTire?"tireStockOut":"wheelStockOut") + "<br>(some sizes)"
            }
            else{
                return this.translate(isTire?"tireStockLow":"wheelStockLow") + "<br>(some sizes)";
            }
               
        },
        lowStockClass() {
            let out = 0;

            if (this.productType === 'wheel') {
                out = settings.noWheelStockWarning;
            } else if (this.productType === 'tire') { 
                out = settings.noTireStockWarning;
            }

            let q = this.stock();
            if( q <= out || q == Infinity) {
                return "outofstock";
            }
            return "";
        },
        isLowStock() {
            let q = this.stock();
            let low = 0;
            let out = 0;

            if (this.productType === 'wheel') {
                low = settings.lowWheelStockWarning;
            } else if (this.productType === 'tire') { 
                low = settings.lowTireStockWarning;
            }

            if (this.productType === 'wheel') {
                out = settings.noWheelStockWarning;
            } else if (this.productType === 'tire') { 
                out = settings.noTireStockWarning;
            }

            if( q == -Infinity ){
                //stock off
            } else if( q == Infinity ){
                return true; 
            } else if( q < low || q < out) {
                return true;
            }
            return false;
        },
        imageSrc() {
            if (this.productType === 'wheel') {
                return ridestyler.ajax.url('wheel/image', {
                    Width: this.imageWidth,
                    Height: this.imageHeight,
                    IncludeShadow: true,
                    WheelModel: this.product.WheelModelID,
                    WheelFitmentResourceType: 3,
                    PositionX: 1,
                    PositionY: 1
                });
            } else if (this.productType === 'tire') {
                return ridestyler.ajax.url('tire/image', {
                    Width: this.imageWidth,
                    Height: this.imageHeight,
                    IncludeShadow: true,
                    TireFitmentResourceType: "Catalog",
                    TireModel: this.product.TireModelID,
                    PositionX: 1,
                    PositionY: 1
                });
            }

            return undefined;
        },
        productClasses() {
            let classes = [];

            if(this.product.type == 'tire') classes.push('ridestyler-showcase-product-gallery-tire-product');
            if(this.loading) classes.push('ridestyler-showcase-product-gallery-product-loading');

            return classes;
        },
        productType() { return this.product.type; },
        tireDescription() {
            if( this.product && this.product.TireFitments && this.product.TireFitments.length != 0)
                return this.product.TireFitments[0].TireSize.Description;
            else
                return null;
        },
        productPrice() { 
            
            if(settings.showPricing)
            {
                if(this.product.PriceMin) {
                    if( this.product.PriceMin == this.product.PriceMax)
                    {
                        return formatPrice(this.product.PriceMin);
                    } else {
                        return "Starting at " + formatPrice(this.product.PriceMin);
                    }
                } else {
                    return this.translate("wheelPriceNull") + " for Pricing";
                }
                
            }
                 
            return "";
        },
        tags() {
            const promotions = [];

            if (Array.isArray(this.product.Promotions)) {
                this.product.Promotions.forEach(promotion => {
                    promotions.push({
                        name: promotion.PromotionName,
                        description: promotion.PromotionDescription
                    })
                });
            }

            return promotions;
        },
        isStandardPlan() {
            return this.$store.state.subscription.Description == 'Visualizer Standard';
        },
        ...mapState([
            'selectedWheelModel',
        ]),
        ...mapGetters([
            'selectedWheelModelDisplayName',
        ]),
        labelID() {
          return "label-"+this.product.key;
        },
        labelPrimaryID() {
          return "labelPrimary-"+this.product.key;
        },
        ariaLabelID() {
          if(  this.product.type == "paint" )
            return "label-"+this.product.key;
          return "label-"+this.product.key + " " + "labelPrimary-"+this.product.key;
        }
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        selected: Boolean,
        loading: Boolean
    },
    components: {
        SvgIcon
    },
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-product-gallery-product > * {
        cursor: pointer;
    }
    .ridestyler-showcase-product-gallery-product {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 0 3%;
        text-align: center;
        vertical-align: top;
        border: none;
        background: none;
        outline: none;
        &.ridestyler-showcase-product-gallery-tire-product {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            max-width: none;
            text-align: center;
            border: none;
            background: none;
            outline: none;
            padding: 0 2.5%;
        }
        &:focus {
          .ridestyler-showcase-product-gallery-product-select {
              @include var(background-color, primary-color-active);
          }
        }
    }
    .ridestyler-showcase-product-gallery-product-tags {
        height: 8%;
        position: relative;
        .ridestyler-showcase-badge-label {
            z-index: 2;
            top: 3em;
            right: 3em;
            padding: 0.5em 1.7em;
            &::after {
                width: 5.5em;
            }
        }
    }
    .ridestyler-showcase-product-gallery-product-tag {
        display: inline-block;
        padding: 0.1em 0.3em;
        margin: 0 0.2em;
        font-size: 0.75em;
        @include var(background-color, secondary-background-color);
        @include var(color, secondary-text-color);
    }
    .ridestyler-showcase-product-gallery-product-not-visualizable-icon {
        position: absolute;
        top: 50%; left: 50%;
        font-size: 1.3em;
        opacity: 0.5;
        color: white;
        margin-left: -1.1em; margin-top: -2em;
        z-index: 12;
    }
    .ridestyler-showcase-product-gallery-product-wheel-info {
        position: relative;
        height: 26%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .ridestyler-showcase-product-gallery-product-wheel-image {
        height: 65% !important;
        margin: 0 auto;
    }
    .ridestyler-showcase-product-gallery-product-tire-image {
        width: auto; height: 70%;
        display: inline-block;
        position: relative;
        margin: 0;
    }
    .ridestyler-showcase-product-gallery-product-tire-info {
        position: absolute;
        bottom: 0; left: 0;
        background: #f1f3f4;
        height: 44%; width: 100%;

        .ridestyler-showcase-product-gallery-product-label-size {
            margin-top: .25rem;
            margin-bottom: .25rem;

            height: auto;
            font-weight: normal;
            font-size: .75em;
            position: relative;
            overflow: hidden;
            color: #868686;
        }

        .ridestyler-showcase-product-gallery-product-label {
            
            bottom: 0;
        }
        .ridestyler-showcase-product-gallery-product-label-secondary {
            top: 0;
        }
        .ridestyler-showcase-product-gallery-product-label-primary {
            overflow: hidden;
            bottom: 2px;
        }
    }
    .ridestyler-showcase-product-gallery-product-tire-divider {
        position: absolute;
        display: inline-block;
        height: 1px;
        width: 70%;
        margin: 0 auto;
        background: darken($secondary-background-color, 20%);
        top: 0; left: 0; right: 0;
    }
    %product-gallery-label {
        color: currentColor;
        overflow: inherit;
        text-overflow: ellipsis;
        font-weight: 500;
    }
    .ridestyler-showcase-product-gallery-product-label {
        @extend %product-gallery-label;

        font-size: 1em;
        position: relative;
        overflow: hidden;
        width: 100%
    }
    .ridestyler-showcase-product-gallery-product-label-primary {
        @extend %product-gallery-label;

        height: auto;
        font-weight: bold;
        font-size: 1.2em;
        position: relative;
        width: 100%;
        overflow: hidden;
    }
    .ridestyler-showcase-product-gallery-lowstock {
        position: absolute;
        top:10px;
        right:10px;
        color:#ffd700;
        font-size:2em;
        line-height: 1;
        &.outofstock {
            color:red;
        }

        .tooltiptext {
            visibility: hidden;
          
            background-color: black;
            color: #fff;
            text-align: center;
            padding: 5px;
            border-radius: 6px;
            font-size:.5em;
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 50%;
            transform:translateX(-50%);
        }

        &:hover .tooltiptext {
            visibility: visible;
        }
    }

    .ridestyler-showcase-product-gallery-product-label-secondary {
        @extend %product-gallery-label;

        height: auto;
        font-weight: bold;
        font-size: .75em;
        position: relative;
        overflow: hidden;
    }
    .ridestyler-showcase-product-gallery-product-select {
        display: inline-block;
        position: absolute;
        height: 2%; width: 77%;
        background-color: transparent;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1234;
        &.selected {
            @include var(background-color, primary-color);
        }
    }
    .ridestyler-showcase-product-gallery-product-loading:after {
        @include crispImageRendering;
        @include rotate;

        background-image: url('../assets/images/tire-loading.png');
        background-size: 100%;
        visibility: visible;
    }
}
</style>
