/**
 * @param {ridestyler.Descriptions.VehicleTireOptionDescriptionModel} tireOptionDetails 
 * @returns {string}
 */
export function formatTireOptionDetails(tireOptionDetails) {
    const rear = tireOptionDetails.Rear.Description,
        front = tireOptionDetails.Front.Description;
    
    if (rear === front) return rear;

    return "F: " + front + "\n" + "R: " + rear;
}

export function formatPrice(price, includeSign = true)
{
    if(price == null) return price;

    if( includeSign)
        return "$" + price.toFixed(2);
    else
        return price.toFixed(2);
}

/**
 * @param {Number} diameter 
 * @param {Number} width 
 * @returns {string}
 */
export function formatWheelSize(diameterRange, widthRange) {
    let diameterMin = diameterRange[0],
        diameterMax = diameterRange[1],
        widthMin = widthRange[0],
        widthMax = widthRange[1],
        formattedDiameter = diameterMin ,
        formattedWidth = widthMin ;

    if(diameterMin !== diameterMax){
        formattedDiameter = diameterMin + ' to ' + diameterMax + '';
    }

    if(widthMin !== widthMax){
        formattedWidth = widthMin + ' to ' + widthMax + '';
    }

    return formattedDiameter + ' x ' + formattedWidth;
}

/**
 * @param {Number} offset 
 * @returns {string}
 */
export function formatOffset(offsetMin, offsetMax) {
    if(offsetMin !== offsetMax){
        return getOffsetUnit(offsetMin) + ' to ' + getOffsetUnit(offsetMax);
    } else {
        return getOffsetUnit(offsetMin);
    }

    function getOffsetUnit(offset){
        if (offset > 0) return '+' + offset + 'mm';
        else return offset + 'mm';
    }
}

/**
 * @type {{[formattingKey:string]:(input:string)=>string}}
 */
const templateFormattingFunctions = {
    spacetodash(input) {
        // TODO
        let noSpaces = input.split(' ').join('-');

        return noSpaces;
    },
    lowercase(input) {
        // TODO
        return input.toLowerCase();
    },
    noslash(input) {
        let noSlashes = input.split('/').join('');
        return noSlashes;
    },
    noperiod(input) {
        let noPeriods = input.toString().split('.').join('');
        return noPeriods;
    }
};

/**
 * @param {string} template 
 * @param {object} dataModel
 * @param {boolean} [urlEncode=true]
 * @returns {string}
 */
export function formatTemplate(template, dataModel, urlEncode){
    let templateTokens = [...new Set(template.match(/{{[^}]+}}/g))],
        formattedTemplate = template;

    if (typeof urlEncode === 'undefined') urlEncode = true;

    for(let i = 0; i < templateTokens.length; i++) {
        const rawToken = templateTokens[i]; // The entire token with braces Ex. {{Wheel.Front.BrandName:spacetodash:lowercase}}
        let tokenKey = rawToken.substr(2, rawToken.length - 4); // Token and formatting functions without the braces Ex. Wheel.Front.BrandName:spacetodash:lowercase
        const tokenParts = tokenKey.split(':'); // Token with formatting functions Ex. [Wheel.Front.BrandName, spacetodash, lowercase]
        const valueTokens = tokenParts[0].substr(0, rawToken.length - 4).split('.');
        const formattingFunctions = tokenParts.slice(1);
        
        let replaceValue = getValue(dataModel, valueTokens);

        for (const formattingFunction of formattingFunctions) {
            if (formattingFunction in templateFormattingFunctions === false) continue;

            replaceValue = templateFormattingFunctions[formattingFunction](replaceValue);
        }

        if (urlEncode) replaceValue = encodeURIComponent(replaceValue);

        formattedTemplate = formattedTemplate.replace(rawToken, replaceValue);
    }

    return formattedTemplate;
}

/**
 * @param {object} base 
 * @param {array} tokens
 * @returns {string}
 */
function getValue(base, tokens) {
    const value = base[tokens.shift()];
    if (tokens.length === 0) return value;

    return getValue(value, tokens);
}