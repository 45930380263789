<template>
    <div class="ridestyler-action-button-wrapper">
        <button 
            type="button"
            v-if="action.isVisible" 
            v-bind:disabled="action.isDisabled" 
            v-on:click="onClick" 
            class="ridestyler-showcase-action-button" 
            v-bind:class="[ buttonStyle != 'link' ? 'ridestyler-showcase-button-large ridestyler-showcase-button' : 'ridestyler-showcase-link-button' ]">{{ action.label }}</button>
    </div>
</template>

<script>
export default {
    props: {
        action: Object,
        buttonStyle: String
    },
    methods: {
        onClick(){
            if(this.action.type === 'tire'){
                this.action.callback(this.$store.getters.presumedTireFitmentFront);
            } else {
                this.action.callback(this.$store.getters.presumedWheelFitmentFront);
            }
        }
    },
    computed: {
        isActionGlobal(){
            return this.action.type == 'global';
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    &.ridestyler-showcase-page-select {
        .ridestyler-showcase-action-container {
            .ridestyler-showcase-action-button {
                height: 2.1em;
                padding: 1px 10px;

                &:focus {
                    outline: none;
                }
            }
        }
    }
    .ridestyler-action-button-wrapper {
        display: block;
        margin-bottom:10px;
    }
}
</style>
