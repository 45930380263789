export function loadIntegration(integration){
    return new Promise(function(resolve, reject){
        var loadedScripts = [];

        if (integration.ProductType != 1) return; // Do not load scripts for non-visualizer integrations

        for(var script=0; script < integration.RequiredScripts.length; script++){
            var resource;
            if(integration.RequiredScripts[script].includes('.js')){
                resource = document.createElement('script');
            } else {
                resource = document.createElement('link');
            }
            resource.src = integration.RequiredScripts[script];
            resource.onload = function(){
                loadedScripts.push(resource);
                if(loadedScripts.length == integration.RequiredScripts.length){
                    resolve();
                }
            };
            resource.onerror = function(){
                reject();
            };
            
            document.body.appendChild(resource);
        }
    });
}