/**
 * @typedef {'side'|'angle'} VehicleAngle
 * @typedef {'front'|'rear'|'both'} VehicleFitmentPosition
 * @typedef {'init'|'home'|'select'|'visualize'} ShowcasePage
 * @typedef {'xs'|'sm'|'md'|'lg'} ShowcaseBreakpoint
 * @typedef {'wheel'|'tire'|'suspension'} CustomizationTab
 * @typedef {'wheel'|'tire'} ProductType
 */

/**
 * @typedef Product
 * @prop {ProductType} type
 */

/**
 * @typedef Notification
 * @prop {string} text
 * @prop {string} type
 * @prop {number} time
 */

/**
 * @typedef VehicleSelection
 * @prop {string} vehicleConfiguration
 * @prop {string} paintColor
 * @prop {ridestyler.Descriptions.VehicleTireOptionDescriptionModel} tireOption
 */

/**
 * @typedef RideStylerShowcaseState
 * @prop {ShowcasePage} page The currently viewed page of the showcase
 * @prop {CustomizationTab} selectedCustomizationTab The currently selected tab
 * @prop {Product} productDetailsModel The currently displayed product in the product details modal
 * @prop {Notification[]} notifications The currently displayed set of notifications
 * @prop {VehicleFitmentPosition} fitmentPosition Whether we're currently editing the front of the vehicle, rear of the vehicle or both.
 * 
 * @prop {ShowcaseBreakpoint} breakpoint The current breakpoint of the interface
 * @prop {boolean} isPortrait Whether or not the interface is taller than it is wide
 * @prop {boolean} viewportIsShort Whetther or not the interface's height is "short"
 * 
 * @prop {string} vehicleConfiguration The ID of the currently selected VehicleConfiguration
 * @prop {ridestyler.Descriptions.VehicleDescriptionModel} vehicleDescription The description model for the currently selected vehicle configuration
 * 
 * @prop {string} tireOption The ID of the currently selected tire option
 * @prop {ridestyler.Descriptions.VehicleTireOptionDescriptionModel} tireOptionDetails The description model for the currently selected vehicle tire option
 * 
 * @prop {string} paintColor The currently visualized paint color
 * @prop {VehicleAngle} vehicleRotation The currently visualized angle of the vehicle
 * 
 * @prop {number} suspensionFront The visualized front suspension value
 * @prop {number} suspensionRear The visualized rear suspension value
 * 
 * @prop {number} lastSelectedDiameterFront The last selected wheel diameter for the front of the vehicle
 * @prop {number} lastSelectedDiameterRear The last selected wheel diameter for the rear of the vehicle
 * 
 * @prop {boolean} tiresEnabled Whether or not tires are enabled
 * @prop {ridestyler.Requests.TireFilterModel} selectedTireFilters The user selected tire filters
 * @prop {ridestyler.Descriptions.TireModelDescriptionModel}  selectedTireModel The currently selected tire model
 * @prop {ridestyler.Descriptions.TireFitmentDescriptionModel[]}  availableTireFitmentsFront The entire set of tire fitments from the model available for the front of the vehicle
 * @prop {ridestyler.Descriptions.TireFitmentDescriptionModel[]}  availableTireFitmentsRear The entire set of tire fitments from the model available for the rear of the vehicle
 * @prop {ridestyler.Descriptions.TireFitmentDescriptionModel}  selectedTireFitmentFront The tire fitment selected for the front of the vehicle
 * @prop {ridestyler.Descriptions.TireFitmentDescriptionModel}  selectedTireFitmentRear The tire fitment selected for the rear of the vehicle
 * 
 * @prop {boolean} wheelsEnabled Whether or not wheels are enabled
 * @prop {ridestyler.Requests.WheelFilterModel} selectedWheelFilters The user selected wheel filters
 * @prop {ridestyler.Descriptions.WheelModelDescriptionModel}  selectedWheelModel The currently selected wheel model
 * @prop {ridestyler.Descriptions.WheelFitmentDescriptionModel[]}  availableWheelFitmentsFront The set of wheel fitments available for the front of the vehicle
 * @prop {ridestyler.Descriptions.WheelFitmentDescriptionModel[]}  availableWheelFitmentsRear The set of wheel fitments available for the rear of the vehicle
 * @prop {ridestyler.Descriptions.WheelFitmentDescriptionModel}  selectedWheelFitmentFront The wheel fitment selected for the front of the vehicle
 * @prop {ridestyler.Descriptions.WheelFitmentDescriptionModel}  selectedWheelFitmentRear The wheel fitment selected for the rear of the vehicle
 * 
 * @prop {object} translations Dictionary of possible translations
 */

/** @type {RideStylerShowcaseState} */
const state = {
    page: 'init',
    selectedCustomizationTab: 'wheel',
    productDetailsModel: undefined,
    notifications:[],
    subscription:undefined,
    
    fitmentPosition: 'both',

    translations: undefined,
    
    //------ Responsive state ----------//
    breakpoint: undefined,
    isPortrait: undefined,
    viewportIsShort: undefined,
    //----------------------------------//

    //------ Selected vehicle data ------//
    vehicleConfiguration: undefined,
    vehicleDescription: undefined,
    
    tireOption: undefined,
    tireOptionDetails: undefined,
    
    paintColor: undefined,
    vehicleRotation: undefined,
    
    suspensionFront: 0,
    suspensionRear: 0,

    lastSelectedDiameterFront: undefined,
    lastSelectedDiameterRear: undefined,
    //----------------------------------//
    
    //---------- Tires -----------------//
    tiresEnabled: false,
    selectedTireFilters: {},
    selectedTireModel: undefined,

    availableTireCount: 0,
    availableTireFitmentsFront: [],
    availableTireFitmentsRear: [],

    selectedTireFitmentFront: undefined,
    selectedTireFitmentRear: undefined,
    //----------------------------------//
    
    //---------- Wheels ----------------//
    wheelsEnabled: false,
    selectedWheelFilters: {},
    selectedWheelModel: undefined,

    availableWheelFitmentsFront: [],
    availableWheelFitmentsRear: [],
    selectedWheelFitmentFront: undefined,
    selectedWheelFitmentRear: undefined,
    //----------------------------------//
};

export default state;
