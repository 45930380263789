<template>
    <fieldset role="listbox"  class="ridestyler-showcase-button-picker" >
        <div class="ridestyler-showcase-button-picker-options" v-bind:class="{'ridestyler-showcase-button-picker-many-options': options.length > 4}">
            <button type="button" aria-label="Previous" class="ridestyler-showcase-button-picker-pagination-button" v-on:click="selectPreviousOption" v-bind:disabled="selectedIndex <= 0">
                <SvgIcon title="Previous" focusable='false' name="chevron-up" />
            </button>

            <button type="button"
              class="ridestyler-showcase-button-picker-option radio"
              v-bind:class="(option.value == value)?'checked':''"
              v-for="option in options"
              v-on:click="onChanged(option.value)"
              v-bind:data-id="option.value"
              role="option"
              v-bind:key="option.value"
              :aria-selected="option.value == value"
              v-html="option.label">

            </button>

            <button type="button" aria-label="Next" class="ridestyler-showcase-button-picker-pagination-button" v-on:click="selectNextOption" v-bind:disabled="selectedIndex >= options.length - 1">
                <SvgIcon title="Next" focusable="false" name="chevron-down" />
            </button>
        </div>
    </fieldset>
</template>

<script>
import SvgIcon from './SvgIcon';

let buttonPickerID = 1;

export default {
    data() {
        return {
            value: undefined,
            optionFocused: false
        };
    },
    created() {
        this.groupName = 'ridestyler-showcase-button-picker-' + buttonPickerID++;
        this.value = this.selectedValue;
    },
    computed: {
        selectedIndex() {
            return this.options.findIndex(option => option.value == this.value);
        }
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        selectedValue: [String, Number]
    },
    components: {
        SvgIcon
    },
    methods: {
        onChanged(value) {
            this.value = value;
            this.$emit('change', value);
        },
        onKeyPress(value) {
          this.value = value;
          this.$emit('change', value);
        },
        onFocused() {
            this.focusActiveOption();
        },
        getAllOptions() {
            return Array.from(this.$el.querySelectorAll('label'));
        },
        getActiveOption() {
            const inputs = Array.from(this.$el.querySelectorAll('input'));
            const activeInput = inputs.find(input => input.checked);

            return activeInput ? activeInput.parentElement : undefined;
        },
        getFocusedOption() {
            const activeElement = document.activeElement;

            return activeElement.classList.contains('ridestyler-showcase-button-picker-option') ? activeElement : undefined;
        },
        focusActiveOption() {
            const activeOption = this.getActiveOption();

            if (activeOption) activeOption.focus();
        },
        focusPreviousOption() {
            const focusedOption = this.getFocusedOption();
            const previousOption = focusedOption && focusedOption.previousElementSibling;

            if (previousOption) previousOption.focus();
            else this.focusLastOption();
        },
        focusNextOption() {
            const focusedOption = this.getFocusedOption();
            const nextOption = focusedOption && focusedOption.nextElementSibling;

            if (nextOption) nextOption.focus();
            else this.focusFirstOption();
        },
        focusFirstOption() {
            const allOptions = this.getAllOptions();

            if (allOptions.length === 0) return;

            allOptions[0].focus();
        },
        focusLastOption() {
            const allOptions = this.getAllOptions();

            if (allOptions.length === 0) return;

            allOptions[allOptions.length - 1].focus();
        },
        selectFocusedOption() {
            const focusedOption = this.getFocusedOption();

            if (focusedOption) {
                const input = focusedOption.querySelector('input');

                input.checked = true;

                this.onChanged(input.value);
            }
        },
        onKeyDown(e) {
            let key = e.which || e.keyCode;

            switch (key) {
                case 38: // up
                    this.focusPreviousOption();
                    break;
                case 40: // down
                    this.focusNextOption();
                    break;
                case 36: // home
                    this.focusFirstOption();
                    break;
                case 35: // end
                    this.focusLastOption();
                    break;
                case 32: // space
                case 13: // enter
                    this.selectFocusedOption();
                    break;

                case 9: // tab
                    return;
            }

            e.preventDefault();
        },
        iterateOption(change) {
            const inputs = Array.from(this.$el.querySelectorAll('input'));
            const activeIndex = inputs.findIndex(input => input.checked);

            let newIndex = activeIndex + change;

            if (newIndex < 0) newIndex = inputs.length - 1;
            if (newIndex >= inputs.length) newIndex = 0;

            const newSelectedInput = inputs[newIndex];

            newSelectedInput.checked = true;

            this.onChanged(newSelectedInput.value);
        },
        selectPreviousOption() {
            this.iterateOption(-1);
        },
        selectNextOption() {
            this.iterateOption(1);
        }
    },
    watch: {
        selectedValue(newValue) {
            this.value = newValue;
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-button-picker {
        background: none;
        border: none;
        padding: 0;
    }
    .ridestyler-showcase-button-picker-title {
        margin: 0;
        padding: 0.5em;
        float: left;
        width: 100%;
        box-sizing: border-box;
    }
    .ridestyler-showcase-button-picker-options {
        clear: both;
        overflow-x: auto;
    }
    .ridestyler-showcase-button-picker-option {
        display: inline-block;
        margin-right: 0.5em;
        outline: none;
        position:relative;

        input {
            position:absolute;
            top:0;
            left:0;
            display:block;
            opacity:0;
        }

        &.radio {
          background:none;
          color: $secondary-button-color;
          border: 0.24em solid $secondary-background-color; border-radius: 0.4em;
          padding: 0.4em 0.9em;
          cursor: pointer;
          font-weight: 700;
          &.checked {
            background-color: #fff;
            cursor:default;
            @include var(border-color, primary-color);

          }
          @include focusable;
          &:focus {
              outline-offset: -6px;
          }
        }

        span {
            color: $secondary-button-color;
            border: 0.24em solid $secondary-background-color; border-radius: 0.4em;
            display: block;
            padding: 0.4em 0.9em;
            cursor: pointer;
            font-weight: 700;
        }

        input:checked + span {
            background-color: #fff;
            @include var(border-color, primary-color);
        }
    }
    .ridestyler-showcase-button-picker-pagination-button {
        display: none;
        width: 100%;
        background: none;
        border: none;
        cursor: pointer;

        .ridestyler-showcase-icon {
            fill: white;
            height: 1.5em;
        }

        &:disabled {
            opacity: 0.5;
        }

        &:focus {
          outline: none;
        }
    }
}
</style>
