<template>
	<svg :id="id" :class="className" xmlns="http://www.w3.org/2000/svg">
		<title v-if="title" v-text="title"></title>
		<use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink"/>
	</svg>
</template>

<script>
export default {
	name: 'svg-icon',

	props: {
		name: {
			type: String,
			required: true
		},

		id: String,

		title: {
			type: String,
			default: null
		}
	},

	computed: {
		iconPath() {
			const icon = require('../assets/images/icons/' + this.name + '.svg').default;
			
			return '#' + icon.id;
		},

		className() {
			return 'ridestyler-showcase-icon ridestyler-showcase-icon-' + this.name;
		}
	}
};
</script>

<style>
	.ridestyler-showcase-icon {
		fill: currentColor;
		height: 2em;
		width: 2em;
	}
</style>
