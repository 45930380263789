<template>
    <div class="ridestyler-showcase-suspension-adjustment">
        <label for="ridestyler-showcase-suspension-adjustment-slider-front" id='ridestyler-showcase-suspension-adjustment-slider-front-label'>Front:</label>
        <VueSlider id="ridestyler-showcase-suspension-adjustment-slider-front" v-bind:aria-hidden="false" v-bind="sliderSettingsFront" v-model="suspensionFront" />
        <label for="ridestyler-showcase-suspension-adjustment-slider-rear" v-bind:class="!adjustingSeperately ? 'suspension-adjustment-slider-rear-disabled': 'Adjusting Together'">
            Rear:
        </label>
        <VueSlider id="ridestyler-showcase-suspension-adjustment-slider-rear" v-bind:aria-hidden="false"
          v-bind:class="!adjustingSeperately ? 'suspension-adjustment-slider-rear-disabled': 'Adjusting Together'" v-bind="sliderSettingsRear" v-model="suspensionRear" />
        <button type="button" class="ridestyler-showcase-suspension-adjustment-lock-button" v-on:click="adjustingSeperately = !adjustingSeperately">
            <SvgIcon v-bind:name="adjustingSeperately ? 'lock-open-solid' : 'lock-solid'" v-bind:title="adjustingSeperately ? 'Adjusting Separately': 'Adjusting Together'" role="button" aria-label="Lock Front and Rear" />
        </button>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component/';
import settings from '../Settings';
import SvgIcon from './SvgIcon';

export default {
    data() {
        return {
            adjustingSeperately: false
        };
    },
    computed: {
        suspensionFront: {
            get() {
                return this.$store.state.suspensionFront;
            },
            set(newSuspension) {
                this.$store.commit('setSuspension', this.adjustingSeperately ? {
                    front: newSuspension
                } : newSuspension);
            }
        },
        suspensionRear: {
            get() {
                return this.$store.state.suspensionRear;
            },
            set(newSuspension) {
                this.$store.commit('setSuspension', this.adjustingSeperately ? {
                    rear: newSuspension
                } : newSuspension);
            }
        },
        suspensionSettings() {
            const suspensionRanges = settings.suspensionRanges;
            const vehicleDescription = this.$store.state.vehicleDescription;

            const {StyleType,DriveType} = vehicleDescription;
            const suspensionRangeKey = StyleType + DriveType;

            if (!suspensionRanges[suspensionRangeKey]) return suspensionRanges.Default;
            return suspensionRanges[suspensionRangeKey];
        },
        sliderSettingsBase() {
            const marks = {};
            const interval = 1;

            for (let i = this.suspensionSettings.Min; i <= this.suspensionSettings.Max; i += interval) {
                marks[i] = {
                    label: ''
                };
            }

            return {
                min: this.suspensionSettings.Min,
                max: this.suspensionSettings.Max,
                dotSize: 28,
                height: 15,
                interval: interval,
                marks: marks,
                lazy: true,
                tooltip: 'always',
                width: '100%',
                useKeyboard: true,

                tooltipFormatter: val => (val > 0 ? '+' + val : val) + '″'
            }
        },
        sliderSettingsFront() {
            return Object.assign({value: this.suspensionFront}, this.sliderSettingsBase)
        },
        sliderSettingsRear() {
            return Object.assign({value: this.suspensionRear}, this.sliderSettingsBase)
        }
    },
    methods: {

    },
    watch: {
        adjustingSeperately(adjustingSeperately) {
            if (!adjustingSeperately) {
                this.suspensionRear = this.suspensionFront;
            }
        }
    },

    components: {
        VueSlider,
        SvgIcon
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-suspension-adjustment {
        color: $secondary-button-color;
        padding: 0 4.5%;
        top: auto;
        font-size: 0.8em;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        label:last-of-type {
          left: 3em; top: 0;
          position: relative;
        }

        .vue-slider {
            padding: 1% 0 !important;
            &:first-of-type {
                margin-bottom: 1em;
            }
        }
    }

    .ridestyler-showcase-suspension-adjustment-lock-button {
        background: none;
        border: none;
        position: absolute;
        top: 47%; left: 4%;
        outline: none;
    }

    .suspension-adjustment-slider-rear-disabled {
        opacity: 0.5;
    }
    //Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        &.ridestyler-showcase-breakpoint-xs {
            .ridestyler-showcase-suspension-adjustment {
                label:last-of-type {
                    left: 1.5em;
                }
            }
            .ridestyler-showcase-suspension-adjustment-lock-button {
                left: 5.5%; top: 56%;
                padding: 0;
                svg {
                    height: 1.5em;
                    width: 1.5em;
                }
            }
        }
    }
    //Landscape breakpoints
    &.ridestyler-showcase-breakpoint-sm {
        .ridestyler-showcase-suspension-adjustment label {
            font-size: 1em;
        }
        .ridestyler-showcase-suspension-adjustment-lock-button {
            top: 48%;
            svg {
                height: 2.3em;
                width: 2.3em;
            }
        }
    }
    &.ridestyler-showcase-breakpoint-xs {
        .ridestyler-showcase-suspension-adjustment label {
            margin-bottom: 0px;
            font-size: 1.6em;
        }
        .ridestyler-showcase-suspension-adjustment-lock-button {
            svg {
                height: 2.3em;
                width: 2.3em;
            }
        }
    }
}
</style>
