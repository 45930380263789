import Vue from 'vue'
import Showcase from './Showcase'
import Store from './store/index'
import Notify from './Notify';
import Settings from './Settings';
import Plugins from './Plugins';
import EventHandler from './EventHandler';
import UiLibrary from './UILibrary';
import { loadIntegration } from './Integrations';
import * as Formatters from './Formatters';
import language from './mixins/language';

/**
 * @typedef RideStylerShowcaseOptions
 * @prop {boolean} [disableAutoContainerSizing]
 * @prop {'hash'|'localStorage'|'none'} [persistenceMode]
 * @prop {string} [locationReferenceCode]
 * @prop {string} [location]
 * @prop {string} [wheelFitmentIDFront]            
 * @prop {string} [wheelFitmentIDRear]
 * @prop {string} [rotateVehicle]
 * @prop {string} [tireOption]
 * @prop {string} [paintColor]
 * @prop {string} [vehicleConfiguration]
 * @prop {boolean} [disableChangeVehicle]
 * @prop {boolean} [disableMatchingTires]
 * @prop {boolean} [disableSuspension]
 * @prop {Array<string>} [showPartNumbers]
 * @prop {string} [selectPartNumber]
 * @prop {boolean} [partNumbersAreInventoryNumbers]
 * @prop {object} [translations] //key/value pairs of translations to merge with the defaults
 * @prop {boolean} [scrollWheelEnabled]
 */

Vue.config.productionTip = false;
Vue.use(Notify);
Vue.use(UiLibrary);
Vue.use(Plugins);
Vue.use(EventHandler);

const registeredPlugins = {};
// source: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
(function() {
    if (typeof window.CustomEvent === "function") return false
  
    function CustomEvent(event, params) {
      params = params || { bubbles: false, cancelable: false, detail: undefined }
      var evt = document.createEvent("CustomEvent")
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
      return evt
    }
  
    CustomEvent.prototype = window.Event.prototype
  
    window.CustomEvent = CustomEvent
  })();

(function () {
    /**
     * @param {HTMLElement|HTMLElement[]|string} container
     * @param {RideStylerShowcaseOptions} options
     */
    function RideStylerShowcase (container, options) {
        options = options || {};

        // Do some type checking on the container to accept, a string (CSS selector), HTML element, or array of HTML elements (such as a jQuery object)
        if (container && typeof container === 'object' && 'length' in container) {
            if (container.length > 0) container = container[0];
            else container = undefined;
        }

        // Make sure we have a container to instantiate into
        if (!container) throw "Unable to instantiate RideStyler Showcase without a container";

        // Expose $showcaseSettings as a property on every Vue instance
        Vue.prototype.$showcaseSettings = Settings;

        //extend the defaults so everything is preset for vuex
        Settings.options = {...Settings.options, ...options};
        Vue.mixin(language);
        this.app = new Vue({
            el: container,
            store: Store,
            
            render: h => h(Showcase)
        });

        this.formatters = Formatters;

        Settings.onInitialized(async () => {
            const integrations = Settings.integrations;

            if(!integrations || !integrations.length) return;
            await Promise.all(integrations.map(loadIntegration));

            // Loop through plugins and install
            integrations.forEach(integration => {
                if (integration.ProductType != 1) return; // Skip non-visualizer integrations
                this.installPlugin(integration.Name, integration.Settings);
            });
        })
    }

    /**
    * @param {object|function} plugin
    */
    RideStylerShowcase.prototype.installPlugin = function(pluginName, options) {
        if(registeredPlugins[pluginName]){
            registeredPlugins[pluginName](this, this.app.$showcasePlugins, this.app.$store.state, this.app.$uiLibrary, options);
        } else {
            throw 'Plugin with the name ' + pluginName + ' was not found as a registered plugin.';
        }
    }

    /**
     * @param {HTMLElement|HTMLElement[]|string} container
     * @param {RideStylerShowcaseOptions} options
     */
    RideStylerShowcase.create = function (container, options) {
        return new RideStylerShowcase(container, options);
    };

    /**
    * @param {string} pluginName
    * @param {function} pluginInitializer
    */
    RideStylerShowcase.registerPlugin = function(pluginName, pluginInitializer){
        if(typeof pluginName === "string" && typeof pluginInitializer === "function"){
            if(registeredPlugins[pluginName] === undefined){
                registeredPlugins[pluginName] = pluginInitializer;
            } else {
                throw pluginName + " has already been registered.";
            }
        } else {
            throw "Type of plugin name or plugin intializer was not correct.";
        }
    };

    /**
     * 
     * @param {Array} partNumbers 
     * @param {boolean} replace 
     */
    RideStylerShowcase.prototype.loadPartNumbers = function(partNumbers, replace=false) {
        if( replace || Settings.options.showPartNumbers == undefined ) {
            
            //if( Settings.options.showPartNumbers.length == partNumbers.length)
            //{
                //Settings.options.showPartNumbers = [];
                //Settings.options.replacePartNumbers = true;
                //Vue.nextTick(() =>{ 
                  //  Settings.options.showPartNumbers = partNumbers;
                   // Settings.options.replacePartNumbers = true; });
            //} else {
                Settings.options.showPartNumbers = partNumbers;
                Settings.options.replacePartNumbers = true;
            //}
            
            
        }
        else {
           
            Settings.options.showPartNumbers = Settings.options.showPartNumbers.concat(partNumbers);
            
        } 
            
       
    };

    /**
     * 
     * @param {Array} extraDetails 
     * @param {boolean} replace 
     */
     RideStylerShowcase.prototype.loadExtraDetails = function(extraDetails, replace=false) {
        if( replace || Settings.options.extraDetails == undefined )
            Settings.options.extraDetails = extraDetails;
        else {
            Settings.options.extraDetails = Settings.options.extraDetails.concat(extraDetails);
        } 
            
       
    };

    /**
     * 
     * @param {Array} partNumbers 
     * @param {boolean} replace 
     */
     RideStylerShowcase.prototype.loadPartNumbersWithExtraDetails = function(partNumbers, extraDetails, replace=false) {
       this.loadExtraDetails(extraDetails, replace);
       this.loadPartNumbers(partNumbers, replace);
       this.app.$forceUpdate();
       var $this = this;
       Vue.nextTick(() =>{ $this.app.$forceUpdate() });
    };

    /**
     * @param {string} partNumber
     */
    RideStylerShowcase.prototype.selectPartNumber = function( partNumber ) {
        Settings.options.selectPartNumber = partNumber;
    }
    
    window.RideStylerShowcase = RideStylerShowcase;
    document.dispatchEvent(new CustomEvent("rs-showcase-loaded"));
})();
