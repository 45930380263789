<template>
    <div class="ridestyler-showcase-details-block">
        <div class="ridestyler-details-info">
            <img id="ridestyler-wheel-brand-logo" :alt="wheelBrandName" :src="wheelBrandLogoURL" v-if="wheelLogoExists" />
            <p class="ridestyler-wheel-brand-name" v-if="!wheelLogoExists">{{wheelBrandName}}</p>
            <p class="ridestyler-wheel-info-name">{{ wheelDisplayName }}</p>
            <div class="ridestyler-wheel-info-wrap">
                <div class="ridestyler-showcase-details-block-secondary" v-text="wheelFinish" v-if="!isPortrait"></div>
                <button type="button" class="ridestyler-showcase-features-specs" v-on:click="showWheelDetails" v-html="translate('featuresAndSpecs')">Features &amp; Specs</button>
            </div>
            <GlobalActionContainer actionType="wheel" styleType="link" />
            <SizePicker />
        </div>
        <div class="ridestyler-details-pricing">
            <div class="ridestyler-starting-price" v-if="showPricing">
                <div class="ridestyler-price-box" v-if="!wheelNoInfo">
                    <span v-html="translate('priceEach')">Price (ea.)</span>
                    <div class='price' v-html="wheelPricing"></div>
                </div>
                <span class="ridestyler-stock" v-if='!wheelNoInfo' v-bind:class="wheelStockClass"  v-html="wheelStock"></span>
                <span class="ridestyler-no-info" v-if='wheelNoInfo'>Please check with retailer for pricing and availability</span>
            </div>
            
            
        </div>
        <div class="ridestyler-details-info" v-if="hasTireModelSelected">
            <div class="ridestyler-showcase-details-block-tertiary">Matching Tire Package <button type="button" @click="removeTirePackage">Remove</button></div>
            <p class="ridestyler-tire-brand-name" >{{ selectedTireModel.TireBrandName }}</p>
            <p class="ridestyler-tire-model-name" >{{ selectedTireModel.TireModelName }}</p>
            <div class="ridestyler-wheel-info-wrap">
                <div class="ridestyler-showcase-details-block-secondary" v-text="tireSize" v-if="!isPortrait"></div>
                <button type="button" v-if="tireDescription" class="ridestyler-showcase-features-specs" v-on:click="showTireDetails" v-html="translate('featuresAndSpecs')">Features &amp; Specs</button>
            </div>
            <GlobalActionContainer actionType="tire" styleType="link" />
        </div>
        <div class="ridestyler-details-pricing" v-if="hasTireModelSelected">
            <div class="ridestyler-starting-price" v-if="showPricing" >
               
                <div class="ridestyler-price-box" v-if='!tireNoInfo'>
                    <span v-html="translate('priceEach')">Price (ea.)</span>
                    <div class="price" v-html="tirePricing"></div>
                    
                </div>
                <span class="ridestyler-stock" v-if='!tireNoInfo' v-bind:class="tireStockClass" v-html="tireStock"></span>
                <span class="ridestyler-no-info" v-if='tireNoInfo'>Please check with retailer for pricing and availability</span>

                <div class="ridestyler-showcase-badge-label badge-label-right badge-default" v-if="wheelPromotions">
                    Promo Price
                </div>
            </div>
            
            
        </div>
        <div class="ridestyler-details-global-actions">
            <GlobalActionContainer actionType="global" />
        </div>
        
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { formatPrice } from '../Formatters';
//import Initialization from '../Initialization';
import settings from '../Settings';
import state from '../store/state';
import GlobalActionContainer from './GlobalActionContainer';
import SizePicker from './SizePicker';

export default {
    name: 'ProductDetailsComponent',
    data() {
        return {
            wheelLogoExists: false,
        }
    },
    mounted() {
        this.brandLogoExists();
    },
    components: {
        GlobalActionContainer,
        SizePicker
    },
    methods: {
        removeTirePackage() {
            // this.$emit('productSelected', undefined);
            this.$store.commit('setSelectedTireModel', undefined);
        },
        showWheelDetails() {
            let detailsLink = undefined;

            for(let i=0; i<state.availableWheelFitmentsRear.length; i++){
                let fitment = state.availableWheelFitmentsRear[i];
                if( fitment.DiameterMin <= this.selectedDiameter && this.selectedDiameter <= fitment.DiameterMax){
                    
                    if( fitment.DetailsLink )
                    {
                        if( settings.options.selectPartNumber )
                        {
                            var PartNumber = fitment.PartNumber;
                            if( settings.options.partNumbersAreInventoryNumbers )
                                PartNumber = fitment.ItemNumber;
                            
                            if( !detailsLink || settings.options.selectPartNumber == PartNumber )
                            {
                                detailsLink = fitment.DetailsLink;
                            }

                        } else {
                            detailsLink = fitment.DetailsLink;
                        }
                        
                    }
                                
                }
            }
            
            if(detailsLink)
            {
                window.location.href = detailsLink;
            }
            else
                this.$store.commit('setProductDetailsModel', this.selectedWheelModel);
        },
        showTireDetails() {
            this.$store.commit('setProductDetailsModel', this.selectedTireModel);
        },
        brandLogoExists() {
            let logoElement = document.createElement('img'),
                self = this;

            logoElement.src = this.wheelBrandLogoURL;

            logoElement.onload = function(){
                self.wheelLogoExists = true;
            };

            logoElement.onerror = function(){
                self.wheelLogoExists = false;
            };
        },
       
    },
    computed: {
        showPricing() {
            return settings.showPricing;
        },
        selectedDiameter() {
            return this.activeWheelDiameterForCurrentPosition;
        },
        rideStylerEnv() {
            let url = ridestyler.ajax.url('', undefined),
                env = undefined;

            if (/\/api-alpha\./i.test(url)) env = 'alpha';
            else if (/\/api-beta\./i.test(url)) env = 'beta';
            else if (/\/api\./i.test(url)) env = 'edge';

            return env;
        },
        wheelNoInfo() {
            return !this.wheelHasPrice && this.wheelStock == this.translate("wheelStockNull");
        },
        wheelDisplayName() {
            return this.selectedWheelModel.WheelModelName;
            //return this.selectedWheelModelDisplayName;
        },
        wheelPricing() {
            
            if( settings.showPricing && state.availableWheelFitmentsRear)
            {
                let min = Infinity;
                let max = -Infinity;
                for(let i=0; i<state.availableWheelFitmentsRear.length; i++){
                    let fitment = state.availableWheelFitmentsRear[i];
                    if( fitment.DiameterMin <= this.selectedDiameter && this.selectedDiameter <= fitment.DiameterMax){
                        
                        if( fitment.Price ) {
                            
                            if( fitment.Price > max )
                                max = fitment.Price;
                            if( fitment.Price < min )
                                min = fitment.Price;
                        
                        } else {
                            min = -Infinity;
                            max = Infinity;
                        }

                    }
                }
                
                if( min == -Infinity || min == Infinity) {
                    return "<sup>$</sup>INQUIRE"
                } else if( min == max ) {
                    return "<sup>$</sup>"+formatPrice(min, false);
                } else {
                    return "<sup>$</sup>"+formatPrice(min, false) + " to " + "<sup>$</sup>"+formatPrice(max, false);
                }
            }
                
            return null
        },
        wheelHasPrice() {
            if( settings.showPricing && state.availableWheelFitmentsRear)
            {
                let min = Infinity;
                let max = -Infinity;
                for(let i=0; i<state.availableWheelFitmentsRear.length; i++){
                    let fitment = state.availableWheelFitmentsRear[i];
                    if( fitment.DiameterMin <= this.selectedDiameter && this.selectedDiameter <= fitment.DiameterMax){
                        if( fitment.Price ) {
                            if( fitment.Price > max )
                                max = fitment.Price;
                            if( fitment.Price < min )
                                min = fitment.Price;
                        } else {
                            min = -Infinity;
                            max = Infinity;
                        }

                    }
                }
                
                if( min == -Infinity || min == Infinity) {
                    return false
                } else if( min == max ) {
                    return true;
                } else {
                    return true;
                }
            }
                
            return null
        },
        wheelStockClass() {
            if( settings.showInventory && state.availableWheelFitmentsRear) {
                let qty = -Infinity;
                for(let i=0; i<state.availableWheelFitmentsRear.length; i++){
                    let fitment = state.availableWheelFitmentsRear[i];
                    if( fitment.DiameterMin <= this.selectedDiameter && this.selectedDiameter <= fitment.DiameterMax){
                        if( !fitment.QuantityAvailable && fitment.QuantityAvailable != 0 )
                            qty = -Infinity;
                        else
                            qty = fitment.QuantityAvailable;
                            
                    }
                }
                 
                if( qty == -Infinity)
                    return "outofstock";
                else if( qty < settings.noWheelStockWarning)
                    return "outofstock";
                else if( qty < settings.lowWheelStockWarning)
                    return "lowstock";
                else
                    return "";

            }
            return null;
        },
        wheelStock() {
            
            if( settings.showInventory && state.availableWheelFitmentsRear) {
                let qty = -Infinity;
                for(let i=0; i<state.availableWheelFitmentsRear.length; i++){
                    let fitment = state.availableWheelFitmentsRear[i];
                    if( fitment.DiameterMin <= this.selectedDiameter && this.selectedDiameter <= fitment.DiameterMax){
                        if( !fitment.QuantityAvailable && fitment.QuantityAvailable != 0)
                            qty = -Infinity;
                        else
                            qty = fitment.QuantityAvailable;
                            

                    }
                }

                 
                if( qty == -Infinity)
                    return this.translate("wheelStockNull");
                else if( qty < settings.noWheelStockWarning)
                    return this.translate("wheelStockOut");
                else if( qty < settings.lowWheelStockWarning)
                    return this.translate("wheelStockLow");
                else
                    return "";

            }
            return null;
        },
        tireNoInfo() {
            return !this.tireHasPrice && this.tireStock == this.translate("tireStockNull");
        },
        tirePricing() {
             if( settings.showPricing && this.selectedTireModel && this.selectedTireModel.TireFitments){
                 
                 let min = Infinity;
                let max = -Infinity;
                for(let i=0; i<this.selectedTireModel.TireFitments.length; i++){
                    let fitment = this.selectedTireModel.TireFitments[i];
                    if( fitment.Price ) {
                        if( fitment.Price > max )
                            max = fitment.Price;
                        if( fitment.Price < min )
                            min = fitment.Price;
                    } else {
                        min = -Infinity;
                        max = Infinity;
                    }                    
                }

                if( min == -Infinity || min == Infinity) {
                    return "<sup>$</sup>INQUIRE"
                } else if( min == max ) {
                    return "<sup>$</sup>"+formatPrice(min, false);
                } else {
                    return "<sup>$</sup>"+formatPrice(min) + " to " + "<sup>$</sup>"+formatPrice(max);
                }

             }
                
            return null
        },
        tireHasPrice() {
             if( settings.showPricing && this.selectedTireModel && this.selectedTireModel.TireFitments){
                 
                 let min = Infinity;
                let max = -Infinity;
                for(let i=0; i<this.selectedTireModel.TireFitments.length; i++){
                    let fitment = this.selectedTireModel.TireFitments[i];
                    if( fitment.Price ) {
                        if( fitment.Price > max )
                            max = fitment.Price;
                        if( fitment.Price < min )
                            min = fitment.Price;
                    } else {
                        min = -Infinity;
                        max = Infinity;
                    }                    
                }

                if( min == -Infinity || min == Infinity) {
                    return false
                } else if( min == max ) {
                    return true
                } else {
                    return true
                }

             }
                
            return null
        },
        tireStockClass() {
            if( settings.showInventory && state.selectedTireModel && this.selectedTireModel.TireFitments) {
                let qty = -Infinity;
                for(let i=0; i<this.selectedTireModel.TireFitments.length; i++){
                    let fitment = this.selectedTireModel.TireFitments[i];
                    if( !fitment.QuantityAvailable && fitment.QuantityAvailable != 0 )
                        qty = -Infinity;  
                    else
                        qty = fitment.QuantityAvailable;
                                  
                }
                 
                if( qty == -Infinity)
                    return "outofstock";
                else if( qty < settings.noTireStockWarning)
                    return "outofstock";
                else if( qty < settings.lowTireStockWarning)
                    return "lowstock";
                else
                    return "";

            }
            return null;
        },
        tireStock() {
            
            if( settings.showInventory && state.selectedTireModel && this.selectedTireModel.TireFitments) {
                let qty = -Infinity;
                for(let i=0; i<this.selectedTireModel.TireFitments.length; i++){
                    let fitment = this.selectedTireModel.TireFitments[i];
                    if( !fitment.QuantityAvailable && fitment.QuantityAvailable != 0)
                        qty = -Infinity;
                    else
                        qty = fitment.QuantityAvailable;
                                    
                }
                 
                if( qty == -Infinity)
                    return this.translate("tireStockNull");
                else if( qty < settings.noTireStockWarning)
                    return this.translate( "tireStockOut");
                else if( qty < settings.lowTireStockWarning)
                    return this.translate("tireStockLow");
                else
                    return "";

            }
            return null;
        },


        wheelPromotions() {
            return this.selectedWheelModel.Promotions;
        },
        wheelBrandLogoURL() {
            return "https://api" + (this.rideStylerEnv != 'edge' ? ('-' + this.rideStylerEnv) : '') + ".ridestyler.net/wheel/brandimage?NoCache=true&key=" + ridestyler.auth.currentSharedKey() +  "&WheelBrand=" + this.selectedWheelModel.WheelBrandID + "&WheelBrandResourceType=Logo&Width=200&Height=90&PositionX=1&PositionY=1";
        },
        wheelBrandName() {
          return this.selectedWheelModel.WheelBrandName;
        },
        wheelFinish() {
            if (!this.selectedWheelModel) return undefined;

            return this.selectedWheelModel.WheelModelFinishDescription;
        },
        tireDescription(){
            if(!this.selectedTireModel) return undefined;

            return this.selectedTireModel.TireBrandName + " " + this.selectedTireModel.TireModelName;
        },
        tireSize() {
            
            if(this.selectedTireModel && this.selectedTireModel.TireFitments && this.selectedTireModel.TireFitments.length != 0)
                return this.selectedTireModel.TireFitments[0].TireSize.Description;
            else
                return null;
        },
        packagePricing() {
            
            if(this.selectedWheelModel && this.selectedWheelModel.PriceMin && this.selectedTireModel && this.selectedTireModel.PriceMin)
                return this.selectedTireModel.PriceMin + this.selectedWheelModel.PriceMin;
            else
                return null;
        },
        packagePromotions() {
            return this.selectedTireModel && this.selectedWheelModel && this.selectedTireModel.Promotions && this.selectedWheelModel.Promotions;
        },
        ...mapState([
            'selectedTireModel',
            'selectedWheelModel',
            'isPortrait'
        ]),
        ...mapGetters([
            'activeWheelDiameterForCurrentPosition',
            'selectedWheelModelDisplayName',
            'hasWheelModelSelected',
            'hasTireModelSelected'
        ])
        
    },
    watch: {
        selectedWheelModel(){
            this.brandLogoExists();
        }
    }
}
</script>
<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-details-block {
        .ridestyler-details-info {
           
            img {
                height: 2.5em; width: auto;
                position: relative;
            }
            h1 {
                font-weight: 700;
                padding-top: .3em;
                float: right;
                
            }
            .ridestyler-tire-brand-name {
                font-size: 1em;
                margin-top:1rem;
            }
            .ridestyler-wheel-brand-name {
                font-size: 1em;
                
            }
            .ridestyler-wheel-info-name, .ridestyler-tire-model-name {
                font-size: 1.25em;
                line-height: 1;
                font-weight: bold;
                //white-space: nowrap;
                margin-top:0;
            }
            .ridestyler-wheel-info-wrap {
                width: 100%;
                padding: 0;
                white-space: normal;
            }
            a {
                display: inline-block;
                text-decoration: underline;
                cursor: pointer;
            }
            button.ridestyler-showcase-features-specs {
              border:none;
              background:none;
              display: inline-block;
              text-decoration: underline;
              cursor: pointer;
              line-height: 1;
              @include varImportant(color, primary-color);

              padding-left: 0;
              padding-bottom: 1rem;
              &:hover {
                color: var(--primary-color-active, #bf1118);
              }
              &:focus {
                  outline: 2px dotted;
                  outline-offset: 1px;
                  outline-color: #ea1921;
                  outline-color: var(--primary-color, #ea1921);
              }

            }

            .ridestyler-showcase-features-specs {
                .ridestyler-action-button-wrapper {
                    display: inline-block;
                }
                .ridestyler-showcase-action-button {
                    background: none;
                    border: none;
                    font-size: 0.8em;
                }
            }
            div.ridestyler-showcase-details-block-secondary {
                display: inline-block;
                color: $secondary-button-color;
                font-weight: normal;
                padding: 0 1em 0 0;
                font-size:.8rem;
                span {
                    text-decoration: underline;
                    padding-left: 4px;
                    cursor: pointer;
                }
            }
            .ridestyler-showcase-wheel-actions {
                display: block;
                padding: 0 0 10px;
            }
            .ridestyler-showcase-tire-actions {
                .ridestyler-showcase-link-button {
                    padding: 4% 0 0;
                }
            }
            div.ridestyler-showcase-details-block-tertiary {
                padding: 1rem 0 0 0;
                font-weight: 500;
                color: lighten($secondary-button-color, 20%);
                line-height: 1;

                span {
                    text-decoration: underline;
                    cursor:pointer;
                }
                button {
                  background:none;
                  border:none;
                  text-decoration: underline;
                }
            }
            h2 {
                position: relative;
                font-size: 1.4em; font-weight: 700;
                margin-bottom: 0;
                white-space: normal;
            }
        }
        .ridestyler-details-pricing {
           
            border-bottom: 2px solid $secondary-background-color;
            .ridestyler-starting-price {
                display: flex;
                flex-direction: row;
                //justify-content: space-between;
                align-items: flex-end;
                margin: 0 0 1rem 0;
            }
            .ridestyler-price-box {
                display: inline-block;
                margin-left:2em;
                &:first-child {
                    margin-left:0;
                }
                span {
                    font-weight: 700;
                    display: block;
                    font-size: 0.85em;
                }
                .ridestyler-price-currency {
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    top: 3px;
                    font-size: 1.1em;
                }
                .price {
                    font-weight: 700;
                    display: inline-block;
                    margin: 0;
                    font-size:1.2em;
                    line-height: 1;
                    &.smaller {
                        font-size:1em;
                    }
                    sup {
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        top: 2px;
                        font-size: .75em;
                    }
                }
               
            }
            .ridestyler-stock {
                font-weight: 700;
                    display: block;
                    font-size: 0.85em;
                    margin-left:1em;
                    line-height: 1.6em;
                 &.outofstock {
                    color:red;
                }
                &.lowstock {
                    color:#ffd700;
                }
            }
            .ridestyler-no-info {
                color: lighten($secondary-button-color, 20%);
                font-style: italic;
            }
            
        }
        .ridestyler-details-global-actions {
            width:100%;
            margin-top:1rem;

            .ridestyler-showcase-action-container {
                display: block;
                width: 100%;
                button {
                    width: 100%;
                    color: white;
                }
            }
        }

        .ridestyler-pacakge-price {
            .ridestyler-price-box {
                position: relative;
                display: inline-block;
                width: 100%;
                span {
                    display: block;
                }
                .price {
                    display: inline-block;
                }
                .ridestyler-showcase-badge-label {
                    position: absolute;
                    right: 0;
                    bottom: 35%;
                }
            }
        }
    }
    //portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-showcase-details-block {
            .ridestyler-details-info {
                padding: 0 0 1%;
                img {
                    height: 2.8em;
                }
                .ridestyler-wheel-info-name {
                    width: 90%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 1.6em;
                }
                .ridestyler-wheel-info-wrap {
                    padding: 0 0 2% 0;
                }
                .ridestyler-showcase-features-specs {
                    padding: 0;
                    font-size: 1.2em;
                }
                .ridestyler-showcase-details-block-tertiary {
                    padding: 1% 0 0 0;
                }
                .ridestyler-showcase-action-container {
                    display: block;
                    &.ridestyler-showcase-tire-actions {
                        padding: 0;
                    }
                    button {
                        font-size: 1.2em;
                        padding: 3% 0 2%;
                    }
                }
                .ridestyler-showcase-details-block-secondary {
                    padding: 3% 0 0 0;
                }
                h2 {
                    font-size: 1.1em;
                    padding: 0;
                }
            }
            .ridestyler-details-pricing {
                padding: 1% 0;
                .ridestyler-pacakge-price {
                    .ridestyler-price-box {
                        width: 100%;
                        justify-content: space-between;
                        padding: 0 0 1%;
                        span {
                            align-self: center;
                        }
                        .price {
                            align-self: center;
                            margin: 0 auto 0 0;
                        }
                        .ridestyler-showcase-badge-label {
                            position: relative;
                            bottom: 0;
                        }
                    }
                }
                .ridestyler-price-box {
                    width: 60%;
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    span {
                        align-self: baseline;
                    }
                    .ridestyler-price-currency {
                        font-size: 0.9em;
                        bottom: 5px;
                        align-self: center;
                        margin: 0 0 0 1rem;
                    }
                    .price {
                        bottom: 0;
                    }
                }
            }
            div.ridestyler-showcase-badge-label {
                font-size: 0.85em;
            }
        }
    }
    //end portrait
    //landscape breakpoints
    &.ridestyler-showcase-breakpoint-xs, &.ridestyler-showcase-breakpoint-sm {
        .ridestyler-showcase-details-block {
            .ridestyler-details-info {
                img {
                    top: 0;
                }
            }
            .ridestyler-price-box {
                span {
                    font-size: 0.85em;
                }
                .ridestyler-price-currency {
                    top: 0;
                    font-size: 0.2em;
                }
                .price {
                    font-size: 1.5em;
                    bottom: 2px;
                }
            }
            .ridestyler-showcase-badge-label {
                font-size: 0.8em;
            }
        }
    }
    &.ridestyler-showcase-breakpoint-md {

    }
    //end landscape breakpoints
    //Short breakpoints
    &.ridestyler-showcase-breakpoint-viewport-short {
        &.ridestyler-showcase-breakpoint-lg {

        }
        &.ridestyler-showcase-breakpoint-md {

        }
    }
}
</style>
