/** @typedef {import("../state").RideStylerShowcaseState} RideStylerShowcaseState */

/**
 * @typedef RefineProductsState
 * @prop {boolean} isVisible
 * @prop {object} data
 * @prop {function} whenFinished
 */

/** @type {RefineProductsState} */
const state = {
    isVisible: false,
    data: {},
    whenFinished: undefined,
};

const mutations = {
    SET_MODAL(state, payload){
        state.isVisible = payload.isVisible;
        state.data = payload.data;
        state.whenFinished = payload.whenFinished;
    },
}

/**
 * @type {import("vuex").ActionTree<any>}
 */
const actions = {
    refine(context, products){
        let payload = {data: {}},
            hasProductsToRefine = false;

        for(let product in products){
            if(products[product] && products[product].length > 1){ // The only case we want to refine is if there are multiple fitments available
                payload.data[product] = products[product];
                hasProductsToRefine = true;
            } else if(products[product].length == 1) {
                payload.data[product] = products[product];
                products[product] = products[product][0];
            }
        }

        if (!hasProductsToRefine) return Promise.resolve(products);

        return new Promise((resolve) => {
            context.commit('SET_MODAL', {
                isVisible: true,
                data: payload.data,
                whenFinished: resolve
            });
        });
    },
    hideModal(context){ 
        context.commit('SET_MODAL', {
            isVisible: false,
            data: undefined
        })
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
