import store from './store'

export default function (Vue) {
    Vue.prototype.$uiLibrary = {
        showComponent: function (component){
            store.commit('globaloverlay/showComponent', component);
        },
        showHTML: function (tpl, callback) {
            const payload = {'html': tpl, 'whenLoaded': callback};
            store.commit('globaloverlay/showHTML', payload);
        },
        showMessage: function (message){
            const payload = {'message': message};
            store.commit('globaloverlay/showMessage', payload);
        },
        showPrompt: function(message, callback, confirmText, cancel){
            if(cancel === undefined){
                cancel = true;
            }
            const payload = {'message': message, 'callback': callback, 'confirmText': confirmText, 'cancel': cancel};
            store.commit('globaloverlay/showPrompt', payload);
        },
        showLoader: function(){
            store.commit('globaloverlay/showLoader');
        },
        hideLoader: function(){
            store.commit('globaloverlay/hideLoader');
        },
        hideModal: function () {
            store.commit('globaloverlay/hideModal');
        },
    };
}

