<template>
    <div v-bind:class="containerClass">
        <img v-bind:class="imgClass" src="//static.ridestyler.com/images/loaders/loader_radial_chaser_back_on_white_32.gif" alt="" />
        <span v-text="title" v-if="title"></span>
    </div>
</template>

<script>
export default {
    computed: {
        containerClass() {
            const classes = [];

            if (this.title) classes.push('ridestyler-showcase-loading-indicator-block')

            return classes;
        },
        imgClass() {
            const classes = [];

            if (!this.title) classes.push('ridestyler-showcase-loading-indicator')
            if (this.inline) classes.push('ridestyler-showcase-loading-indicator-inline');

            return classes;
        }
    },
    props: {
        title: {
            required: false,
            type: String
        },
        inline: {
            required: false,
            type: Boolean
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    .ridestyler-showcase-loading-indicator-block {
        text-align: center;
        
        span {
            display: block;
        }
    }
    .ridestyler-showcase-loading-indicator {
        $size: 32px;
        $padding: 5px;

        padding: $padding;
        border-radius: 50%;
        background: white;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -$size /2;
        margin-top: -$size /2;
        box-sizing: border-box;
        width: 32px;
        height: 32px;

        &-inline {
            display: inline;
            position: static;
            margin: 0;
        }
    }
}
</style>
