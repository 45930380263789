<template>
    <Modal class="ridestyler-showcase-refine-products-modal" aria-label="Filters" v-bind:close-callback="onClose">
        <h2 class="ridestyler-showcase-refine-products-title ridestyler-showcase-modal-title">We need a little more information about your product. Please select from the options below:</h2>
        <div class="ridestyler-showcase-refine-products-product-wrapper" v-for="(product, productID) in products" v-bind:key="productID">
            <div class="ridestyler-showcase-refine-products-wheel-selections" v-if="productID === 'Wheel' && products['Wheel'].length > 1">
                <h3 class="ridestyler-showcase-refine-products-product-label">{{ productID }}</h3>
                <select class="ridestyler-showcase-refine-products-product-select" v-on:change="onChange('Wheel', wheelFitment)" v-model="wheelFitment">
                    <option disabled value="">Please select wheel fitment</option>
                    <option v-for="option in product" v-bind:value="option" v-bind:key="option.WheelFitmentID">Size: {{ option.DiameterMax }}" x {{ option.WidthMax }}" / Offset: {{ sizeOffset(option) }}mm / Centerbore: {{ option.CenterboreMM }}</option>
                </select>
            </div>
            <div class="ridestyler-showcase-refine-products-tire-selections" v-if="productID === 'Tire' && products['Tire'].length > 1">
                <h3 class="ridestyler-showcase-refine-products-product-label">{{ productID }}</h3>                
                <select class="ridestyler-showcase-refine-products-product-select" v-on:change="onChange('Tire', tireFitment)" v-model="tireFitment">
                    <option disabled value="">Please select tire fitment</option>
                    <option v-for="option in product" v-bind:value="option" v-bind:key="option.TireFitmentID">Size: {{ option.TireSize.Description }} / Service Desc: {{ option.TireSize.ServiceDescription }}</option>
                </select>
            </div>
        </div>
        <button type="button" name="button" class="ridestyler-showcase-refine-products-confirm ridestyler-showcase-button ridestyler-showcase-button-large" v-on:click="onConfirm" v-bind:disabled="isDisabled">Confirm</button>
    </Modal>
</template>

<script>
import Modal from './Modal';
import { mapState, mapActions } from 'vuex';

export default {
    data: function(){
        return {
            wheelFitment: '',
            tireFitment: '',
            returnVals: {},
            isDisabled: true
        }
    },
    mounted() {
        this.checkActiveProducts();
    },
    methods: {
        sizeOffset(option) {
            if( option.OffsetMax > 0 )
                return "+" +  option.OffsetMax;
            return option.OffsetMax;
        },
        onClose(){
            this.closeModal();
        },
        onChange(type, selectedOption) {
            this.returnVals[type] = selectedOption;
            this.checkActiveProducts();
        },
        onConfirm() {
            this.whenFinished(this.returnVals)
            this.hideModal();
        },
        checkActiveProducts(){
            let activeProducts = [];

            for(const product in this.products){
                if(Object.keys(this.products[product]).length > 1){
                    activeProducts.push(product);
                } else if(Object.keys(this.products[product]).length == 1){
                    activeProducts.push(product);
                    this.returnVals[product] = this.products[product][0];
                }
            }

            this.isDisabled = Object.keys(this.returnVals).length !== activeProducts.length;
        },
        hideModal(){
            this.$store.dispatch('refineproducts/hideModal');
        },
        ...mapActions({
            closeModal: 'refineproducts/hideModal'
        })
    },
    computed: {
        ...mapState({
            products: state => state.refineproducts.data,
            whenFinished: state => state.refineproducts.whenFinished
        }),
    },
    components: {
        Modal
    }
}
</script>

<style lang="scss">
    .ridestyler-showcase {
        &.ridestyler-showcase-page-visualize {
            .ridestyler-showcase-refine-products-modal {
                &.ridestyler-showcase-modal {
                    .ridestyler-showcase-refine-products-confirm {
                        margin: 10px 0px 0px;
                    }
                }
            }
        }
    }
</style>