<template>
    <div>
        <transition name="fade">
            <Modal class="ridestyler-showcase-filter-modal" v-if="filterModalVisible" v-bind:close-callback="closeModal" aria-label="Filters">
                <div class="ridestyler-showcase-filter-modal-filters">
                    <div class="ridestyler-showcase-filter-modal-filter" v-for="filter in selectBoxOptions" v-bind:key="filter.title">
                        <APISelectBox v-bind="filter" v-on:selection="selection => onSelection(filter, selection)" ref="selectBox" />
                    </div>
                </div>
                <div class="ridestyler-showcase-filter-modal-buttons">
                    <button type="button" class="ridestyler-showcase-button ridestyler-showcase-button-large" v-bind:disabled="isLoading || count == 0" v-text="buttonText" v-on:click="applyFilters()">
                    </button>
                </div>
            </Modal>
        </transition>
        <transition name="fade">
            <Modal class="ridestyler-showcase-filter-confirmation-modal" v-if="confirmationModalVisible" v-bind:close-callback="cancelConfirmation">
                <h1>Notice</h1>
                <span v-text="confirmationText"></span>
                <div class="ridestyler-showcase-modal-footer">
                    <button type="button" class="ridestyler-showcase-button ridestyler-showcase-button-large" v-on:click="applyFilters(true)">Okay</button>
                </div>
            </Modal>
        </transition>
    </div>
</template>

<script>

import APISelectBox from './APISelectBox';
import Vue from 'vue';
import RideStylerAPIRequest from '../RideStylerAPIRequest';
import { getFilters } from '../Filters';
import Modal from './Modal'
import { mapState, mapMutations } from 'vuex';
import Settings from '../Settings';

export default {
    created() {
        for (const filter of getFilters(this.productType).filters) {
            const value = filter.get(this.selectedFilters);

            if (typeof value !== 'undefined') {
                this.selections[filter.title] = [value];
            }
        }

        this.updateCount();
    },
    data() {
        return {
            isLoading: true,
            selections: {},
            count: 0,
            confirmationModalVisible: false
        };
    },
    computed: {
        buttonText() {
            const type = this.productType[0].toUpperCase() + this.productType.slice(1) + 's';

            return this.isLoading ? 'Loading\u2026' : `Show ${this.count} ${type}`
        },
        selectBoxOptions() {
            const filterType = getFilters(this.productType);

            return filterType.filters.map(filter => {
                return {
                    title: filter.title,
                    requestAction: filter.action,
                    requestData: this.baseFilters,
                    responseKey: filter.responseKey,
                    mapLabel: filter.mapLabel,
                    mapValue: filter.mapValue,
                    clearOption: filter.clearOption,
                    selectedValue: this.selections[filter.title] || filter.get(this.selectedFilters) || ''
                };
            });
        },
        selectedFilters() {
            switch (this.productType) {
                case "wheel": return this.$store.getters.wheelFilters;
                case "tire": return this.$store.getters.tireFilters;
            }

            return {};
        },
        baseFilters() {
            switch (this.productType) {
                case "wheel":
                    return this.$store.getters.computedWheelFilters;
                case "tire": {
                    let baseTireFilters = Object.assign({}, this.$store.getters.computedTireFilters);

                    const vehicleStyleType = this.$store.state.vehicleDescription.StyleType;

                    if (['Truck', 'SUV'].includes(vehicleStyleType)) {
                        baseTireFilters.Recommend = Object.assign({
                            OutsideDiameterVarianceMin: Settings.tireTruckMinusSizeVariation,
                            OutsideDiameterVarianceMax: Settings.tireTruckPlusSizeVariation
                        }, baseTireFilters.Recommend);
                    }

                    return baseTireFilters;
                }
            }

            return {};
        },
        hasPackageProduct() {
            switch (this.productType) {
                case "tire": return this.$store.getters.hasWheelModelSelected;
            }

            return false;
        },
        confirmationText() {
            const isTireFilterModal = this.productType === 'tire';
            const filterOutsideOEDiameterRange = 'Outside Diameter' in this.selections;

            if (isTireFilterModal && filterOutsideOEDiameterRange) {
                return 'The outside diameter you selected is different from the OE diameter of your vehicle. ' +
                       'Some modifications may be required.';
            }

            return undefined;
        },

        ...mapState({
            filterModalVisible: state => state.visualize.filterModalVisible,
            productType: 'selectedCustomizationTab',
            selectedWheelModel: 'selectedWheelModel',
        })
    },
    methods: {
        onSelection(filter, selection) {
            const value = selection;

            if (!value.length || value.length === 1 && !value[0]) Vue.delete(this.selections, filter.title);
            else {

              if( Object.prototype.hasOwnProperty.call(this.selections, filter.title))
              {
                 Vue.delete(this.selections, filter.title);
                 Vue.set(this.selections, filter.title, value);
              } else {
                Vue.set(this.selections, filter.title, value);
              }

            }

        },
        getFilters() {
            const filters = {};
            const selections = this.selections;

            const filterType = getFilters(this.productType);
            const filterDefinitions = filterType.filters;

            for (const filterTitle in selections) {
                const filter = filterDefinitions.find(filter => filter.title === filterTitle);
                let value = selections[filterTitle];

                if (value.length === 0) value = undefined;
                else value = value[0];

                if (!filter) continue;

                filter.set(filters, value);
            }

            return filters;
        },
        updateCount() {

            this.isLoading = true;

            const filterType = getFilters(this.productType);

            if (!filterType) return;

            if (this.activeRequest && typeof this.activeRequest.abort === 'function') this.activeRequest.abort();

            this.activeRequest = RideStylerAPIRequest(filterType.countAction, Object.assign({}, this.baseFilters, this.getFilters()), 'Count');

            this.activeRequest
                .then(count => {
                     this.count = count;
                     this.isLoading = false;
                }, () => {});
        },
        applyFilters(confirmed) {
            const requiresConfirmation = !!this.confirmationText;

            if (!requiresConfirmation || confirmed) {
                switch (this.productType) {
                    case "tire":
                        this.$store.dispatch('selectTireFilters', this.getFilters());
                        break;
                    case "wheel":
                        this.$store.dispatch('selectWheelFilters', this.getFilters());
                        break;
                }

                this.closeModal();
            } else {
                this.confirmationModalVisible = true;
            }
        },

        cancelConfirmation() {
            this.confirmationModalVisible = false;
        },

        clearPackageProduct() {
            this.selections = [];

            switch (this.productType) {
                case "tire":
                    this.$store.commit('setSelectedTireModel', undefined);
                    this.$store.commit('setSelectedWheelModel', undefined);
                    this.$store.commit('setSelectedCustomizationTab', 'wheel');
                    this.closeModal();
                    break;
            }
        },

        ...mapMutations({
            closeModal: 'visualize/hideFilterModal'
        })
    },
    watch: {
        selections() {
            this.updateCount();
        },
        baseFilters() {
            this.selections = [];
            this.updateCount();
        }
    },
    components: {
        APISelectBox,
        Modal
    }
}
</script>

<style lang="scss">
    .ridestyler-showcase {
        $padding: 0.5em;
        $buttonsHeight: 4em;

        .ridestyler-showcase-filter-modal {
            &.ridestyler-showcase-modal .ridestyler-showcase-modal-wrapper .ridestyler-showcase-modal-content .ridestyler-showcase-modal-close {
                top: 8px;
                right: 8px;
            }
            .ridestyler-showcase-select-box-title {
                padding: 0;
                position: relative;
                top: 0.5em;
                text-align: center;
            }
            .ridestyler-showcase-select-box-option input:checked + span {
                background-color: $secondary-button-color;
            }
        }

        .ridestyler-showcase-filter-modal-filters {
            position: absolute;
            top: $padding;
            left: $padding;
            right: $padding;
            bottom: $buttonsHeight + $padding;
            overflow: none;
        }

        .ridestyler-showcase-filter-modal-filter {
            display: inline-block;
            width: 33.333%;
            height: 94%;
            text-align: center;
            padding: 0.5em;
            box-sizing: border-box;
            vertical-align: top;

            .ridestyler-showcase-select-box {
                height: 100%;
            }
        }

        .ridestyler-showcase-filter-modal-buttons {
            position: absolute;
            left: $padding;
            right: $padding;
            bottom: $padding;
            text-align: center;
            padding: 0.5em;

            > {
                span, button, a {
                    vertical-align: middle;
                }
            }

            .ridestyler-showcase-button-large {
                width: 100%;
                display: block;
            }

            .ridestyler-showcase-label {
                font-size: 1.1em;
                margin: 0.4em 0;

                &-small {
                    font-size: 0.9em;
                }
            }
        }

        .ridestyler-showcase-filter-confirmation-modal {
            .ridestyler-showcase-modal-wrapper {
                .ridestyler-showcase-modal-content {
                    height: auto !important;
                    bottom: auto;
                    top: 20%;
                    box-sizing: border-box;
                }
            }

            .ridestyler-showcase-modal-footer {
                text-align: right;
            }
        }

        //lg
        @media screen and (min-width: 1201px){
            .ridestyler-showcase-filter-modal .ridestyler-showcase-modal-content {
                height: 59%;
            }
        }


        //md
        @media screen and (min-width: 993px) and (max-width: 1200px){
            .ridestyler-showcase-filter-modal .ridestyler-showcase-modal-content {
                height: 59%;
            }
        }

        //portrait all
        @media screen and (orientation: portrait){
            .ridestyler-showcase-filter-modal-filters {
                height: 80%;
            }
            .ridestyler-showcase-filter-modal-filter {
                width: 100%;
                height: 28%;
                margin-bottom: 30px;

                .ridestyler-showcase-select-box-options {
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                    position: relative;
                    top: 1.5em;
                }
            }
            .ridestyler-showcase-filter-modal-buttons {
                height: auto;
                text-align: center;
                .ridestyler-showcase-button-large {
                    float: none;
                    display: block;
                    margin: 8px auto;
                    width: 100%;
                }
                .ridestyler-showcase-label-small {
                    display: block;
                    font-size: 1.2em;
                }
                span {
                  font-size: 1.2em;
                }
                span:first-child {
                    display: block;
                    margin: 5px;
                }
            }
        }

        //portrait md
        @media screen and (min-width: 993px) and (max-width: 1200px) and (orientation: portrait){
            .ridestyler-showcase-filter-modal .ridestyler-showcase-modal-content {
                height: 86%;
                width: 90%;
            }
            .ridestyler-showcase-filter-modal-filters {
                height: 88%;
            }
            .ridestyler-showcase-filter-modal-filter {
                height: 29%;
            }
        }

        //portrait sm
        @media screen and (min-width: 668px) and (max-width: 992px) and (orientation: portrait){
            .ridestyler-showcase-filter-modal .ridestyler-showcase-modal-content {
                height: 80%;
                width: 90%;
            }
            .ridestyler-showcase-filter-modal-filters {
                height: 85%;
            }
            .ridestyler-showcase-filter-modal-filter {
                height: 28%;
            }
        }

        //portrait xs
        @media screen and (max-width: 667px) and (orientation: portrait){
            .ridestyler-showcase-filter-modal {
                .ridestyler-showcase-modal-content {
                    height: 75%;
                }
            }
            .ridestyler-showcase-filter-modal-filters {
                height: 75%;
            }
            .ridestyler-showcase-filter-modal-filter {
                height: 26%;
            }
        }

        //short sm
        @media screen and (min-width: 668px) and (max-width: 992px) and (orientation: landscape){
            .ridestyler-showcase-filter-modal {
                .ridestyler-showcase-modal-content {
                    height: 85%;
                    width: 83%;
                }
            }
            .ridestyler-showcase-filter-modal {
                .ridestyler-showcase-button-large {
                    height: 28px;
                }
            }
        }

        //short xs
        @media screen and (max-width: 667px) and (orientation: landscape){
            .ridestyler-showcase-filter-modal {
                .ridestyler-showcase-modal-content {
                    height: 85%;
                    width: 83%;
                }
            }
            .ridestyler-showcase-filter-modal {
                .ridestyler-showcase-button-large {
                    height: 28px;
                }
            }
        }
    }
</style>
