<template>
    <div class="ridestyler-vehicle-control-panel">
        <button type="button" @click="rotateVehicle" aria-label="Rotate Vehicle" tabindex="0" role="button">
          <SvgIcon name="rotate" focusable='false' :class="{ 'rotate-disabled' : !vehicleCanRotate }" />
        </button>

        <button type="button" @click="zoomEvent(!isZoomed)" aria-label="Zoom In" tabindex="0" role="button">
          <SvgIcon name="zoom-in" focusable="false" /></button>
    </div>
</template>

<script>
import SvgIcon from './SvgIcon';
import { mapState } from 'vuex';

export default {
    name: 'VehicleControlPanel',
    props: {
        isZoomed: Boolean,
    },
    methods: {
        showWheelDetails() {
            this.$store.commit('setProductDetailsModel', this.selectedWheelModel);
        },
        rotateVehicle() {
            if(this.vehicleCanRotate) this.$store.commit('rotateVehicle');
        },
        zoomEvent(isZoomed) {
            this.$emit('zoomEvent', isZoomed);
        }
    },
    computed: {
        vehicleCanRotate() {
            const wheelModel = this.$store.state.selectedWheelModel;
            
            var vCanRotate = this.$store.state.vehicleDescription.HasSideImage && this.$store.state.vehicleDescription.HasAngledImage;

            return vCanRotate && (wheelModel && wheelModel.HasAngleImage && wheelModel.HasSideImage || !wheelModel);
        },
        ...mapState([
            'isPortrait'
        ]),
    },
    components: {
        SvgIcon,
    }
}
</script>
<style lang="scss">
.ridestyler-showcase {
    .ridestyler-vehicle-control-panel {
        display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex;
        -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center;
        justify-content: flex-start; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;
        height: 68%; width: 2%;
        padding: 0 1% 0;
        align-self: center;

        .ridestyler-showcase-icon-zoom-in {
            width: 2em; height: 2em;
            margin: 5px 0 0 0;
            pointer-events: none;
        }

        .ridestyler-showcase-icon-rotate {
            pointer-events: none;
        }

        div, button {
            cursor: pointer;
            border:none;
            background:none;

            svg {
                width: 1.3em; height: 1.3em;
                fill: $secondary-button-color;
                &.rotate-disabled {
                    fill: lighten($secondary-button-color, 30%);
                    cursor: not-allowed;
                }
            }
        }
    }
    // Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-vehicle-control-panel {
            .ridestyler-showcase-icon-zoom-in {
                width: 2.5em; height: 2.5em;
                position: relative;
                margin: 0;
                right: 4px;
            }
            .ridestyler-showcase-icon-rotate {
                width: 1.5em; height: 1.5em;
            }
        }
        &.ridestyler-showcase-breakpoint-xs {
            .ridestyler-vehicle-control-panel {
                .ridestyler-showcase-icon-zoom-in {
                }
                .ridestyler-showcase-icon-rotate {
                }
            }
        }
        &.ridestyler-showcase-breakpoint-sm {
            .ridestyler-vehicle-control-panel {
                .ridestyler-showcase-icon-zoom-in {
                }
            }
        }
    }
    // End of portrait
    &.ridestyler-showcase-breakpoint-xs {
        .ridestyler-vehicle-control-panel {
            padding: 0.2em 0.7em;
            .ridestyler-showcase-icon-zoom-in {
            }
        }
    }
    &.ridestyler-showcase-breakpoint-sm {
        .ridestyler-vehicle-control-panel {
            .ridestyler-showcase-icon-zoom-in {
            }
        }
    }
}
</style>
