<template>
    <div class="ridestyler-vehicle-zoomer" v-if="isVisible" @click="closeZoomer">
        <transition name="fade">
            <Modal aria-labelledby="ridestyler-showcase-product-details-title" :closeCallback="closeZoomer">
                <StateVehicleRenderer :center=true  />
            </Modal>
        </transition>
    </div>
</template>

<script>
import Modal from './Modal';
import StateVehicleRenderer from "./StateVehicleRenderer";

export default {
    name: 'VehicleImageZoomer',
    data() {
        return {
        }
    },
    methods: {
        closeZoomer() {
            this.$emit('closeZoomer');
        }
    },
    computed: {
        vehicleConfig() {
            return this.$store.state.vehicleConfiguration;
        }
    },
    components: {
        Modal,
        StateVehicleRenderer
    },
    props: {
        isVisible: Boolean
    },
}
</script>
<style lang="scss">
.ridestyler-showcase {
    .ridestyler-vehicle-zoomer {
        .ridestyler-showcase-modal-content {
            width: 94%;
            max-width: 1920px;
            max-height: 80vh;
            //height: 65%;
            aspect-ratio: 16/9;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .ridestyler-showcase-viewport {
            width: 100%;
            height: 90%;
            bottom: 0;
            margin-bottom: 0;
        }
        .ridestyler-showcase-viewport-messages {
            display: none;
        }
    }
    // Portrait breakpoints
    &.ridestyler-showcase-breakpoint-portrait {
        .ridestyler-vehicle-zoomer {
            .ridestyler-showcase-viewport {
                width: 100%;
                height: auto;
                max-height: 100%;
            }
            .ridestyler-showcase-modal-content {
                //width: 89%; height: 21%;
            }
        }
    }
    &.ridestyler-showcase-breakpoint-lg, &.ridestyler-showcase-breakpoint-md {
        .ridestyler-vehicle-zoomer {
            .ridestyler-showcase-modal-content {
               // width: 94%;
               // max-width: 1920px;
               // height: 65%;
            }
        }
    }
}
</style>
