<template>
    <div class="ridestyler-showcase-product-details-container" v-if="productType !== 'none'">
        <transition name="fade">
            <Modal aria-labelledby="ridestyler-showcase-product-details-title" :closeCallback="closeDetailsModal">
                <WheelProductDetailsModal :wheelDetails="productDetailsModel" v-if="productType === 'wheel'" />
                <TireProductDetailsModal :tireDetails="productDetailsModel" v-if="productType === 'tire'" />
            </Modal>
        </transition>
    </div>
</template>

<script>

import { mapState } from 'vuex';
import WheelProductDetailsModal from './WheelProductDetailsModal';
import TireProductDetailsModal from './TireProductDetailsModal';
import Modal from './Modal';

export default {
    components: {
        WheelProductDetailsModal,
        TireProductDetailsModal,
        Modal
    },
    computed: {
        productType: function() {
            if (typeof this.productDetailsModel === 'undefined') return 'none';
            return this.productDetailsModel.type;
        },
        ...mapState([
            'productDetailsModel'
        ])
    },
    methods: {
        closeDetailsModal: function() {
            this.$store.commit('setProductDetailsModel', undefined);
        }
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {

    .ridestyler-showcase-product-details-container .ridestyler-showcase-modal-wrapper .ridestyler-showcase-modal-content .ridestyler-showcase-modal-close {
        top: 2em;
    }

    .ridestyler-showcase-product-details {
        .ridestyler-showcase-product-details-header {
            position: relative;
            height: auto;
            text-align: left;
            margin-bottom: 35px;

            h1 {
                margin-bottom: 0;
                display: inline-block;
                width: 50%;
            }

            .ridestyler-showcase-product-details-toggle-buttons {
                position: relative;
                right: 0;
                display: inline-block;
                width: 50%;
                vertical-align: bottom;

                button {
                    width: 50%;
                    font-size: 1.2em;
                    height: 44px;
                    padding: 0em;

                    &:first-child {
                    border-radius: 5px 0px 0px 5px;
                    }
                    &:last-child {
                    border-radius: 0px 5px 5px 0px;
                    }
                }

            }

            .third-title {
                font-size: 0.5em;
                color: #868686;
            }
        }
    }

    .ridestyler-showcase-product-details-body {
        width: 100%;
        position: relative;
        display: block;
    }

    .ridestyler-showcase-product-details-tab {
        margin: 19px 0px;
    }

    .ridestyler-showcase-product-image {
        position: relative;
        height: 100%;
        width: 42%;
        display: inline-block;
        vertical-align: top;
    }

    .ridestyler-showcase-product-details-full-table-container {
        border-radius: 5px;
        max-height: 60vh;
        overflow: auto;
    }

    .ridestyler-showcase-table tr th+th {
        border-left: 1px solid rgba(255, 255, 255, .125);
    }

    .ridestyler-showcase-product-details-summary-table-container {
        position: relative;
        right: 0;
        top: 0;
        height: 100%;
        width: 50%;
        text-align: left;
        vertical-align: top;
        border-radius: 5px;
        max-height: 425px;
        display: inline-block;
        margin-left: 8%;
    }

    //lg
    @media screen and (min-width: 1201px){
        .ridestyler-showcase-product-details-summary-table-container {
            width: 50%;
            height: 100%;
            max-height: 47vh;
        }
    }

    //md
    @media screen and (min-width: 993px) and (max-width: 1200px){
        .ridestyler-showcase-product-details-summary-table-container {
            width: 50%;
            height: 100%;
            max-height: 39vh;
        }
    }

    //sm
    @media screen and (min-width: 668px) and (max-width: 992px){
        .ridestyler-showcase-product-details-summary-table-container {
            width: 47%;
            height: 100%;
            max-height: 55vh;
        }
    }

    //xs
    @media screen and (max-width: 667px){
        .ridestyler-showcase-product-details {
            .ridestyler-showcase-product-details-summary-table-container {
                width: 45%;
                max-height: 59vh;
            }
            .ridestyler-showcase-product-details-header .ridestyler-showcase-product-details-toggle-buttons button {
                height: 35px;
            }
        }
    }

    //portrait all
    @media screen and (orientation: portrait){
        .ridestyler-showcase-product-details {
            .ridestyler-showcase-product-details-summary-table-container {
                width: 100%;
                height: 51%;
                margin-top: 15px;
                float: none;
                max-height: 26vh;
                margin-left: 0;
            }
            .ridestyler-showcase-product-image {
                height: 100%;
                width: 50%;
            }
            .ridestyler-showcase-product-details-header {
                margin-bottom: 0px;

                h1 {
                    margin-bottom: 15px;
                    display: inline-block;
                    width: auto;
                }
                .ridestyler-showcase-product-details-toggle-buttons {
                    display: block;
                    width: 100%;

                    button {
                        width: 50%;

                        &:first-child {
                            border-radius: 5px 0px 0px 5px;
                        }

                        &:last-child {
                            margin-left: 0px;
                            border-radius: 0px 5px 5px 0px;
                        }
                    }
                }
            }
        }
    }

    //portrait xs
    @media screen and (max-width: 667px) and (orientation: portrait){
        .ridestyler-showcase-product-details {
            .ridestyler-showcase-product-details-summary-table-container {
                width: 100%;
                max-height: 27vh
            }
        }
    }
}
</style>
