<template>
    <div class="ridestyler-showcase-page" ref='container' :aria-hidden='modalVisible'>
        <TopBar :title="topBarTitle" />
        <!-- <StepControl class="ridestyler-showcase-vehicle-select-steps" v-bind:steps="selectionSteps" v-bind:currentStep="currentStep" v-on:step-clicked="onStepClicked" /> -->

        <LoadingIndicator v-if="isFirstLoad" />

        <div class="ridestyler-showcase-vehicle-select-page" v-show="!isFirstLoad && currentStep === 'Vehicle'"
           id="ridestyler-showcase-vehicle-select-step-select-box-title" ref='backBtn' >
            <div class="ridestyler-showcase-vehicle-selections" v-if="hasSelections">
                <h2 id="ridestyler-showcase-vehicle-select-your-vehicle-header">Your Vehicle</h2>
                <output class="ridestyler-showcase-vehicle-selections-wrapper" role="status" aria-live="polite" aria-atomic="true">
                    <template v-for="selection in selections">
                        <a href="#" v-bind:key="selection.label" v-text="selection.label" v-on:click="onSelectionClicked(selection)" />
                    </template>
                </output>
            </div>

            <button  type="button" v-if="canGoBack && !userHasFinishedSelection" 
                v-on:click="goBack" class="ridestyler-showcase-vehicle-selection-back" :aria-label="'Back to ' + lastSelection.menuTitle" 
                :title="'Back to ' + lastSelection.menuTitle">
                <SvgIcon name="chevron-left" alt="" focusable="false" />
            </button>

            <SelectBox
                v-bind:key="currentMenu && currentMenu.Description"
                v-if="!userHasFinishedSelection"
                class="ridestyler-showcase-vehicle-selection-menu"
                v-bind:options="menuOptions"
                v-on:selection="onMenuSelection"
                v-bind:isLoading="isLoading"
                :aria-label="menuTitle"
                
                >

                <template slot="title">
                    <span id="ridestyler-showcase-vehicle-select-step-select-box-title" aria-live="assertive" v-text="menuTitle"></span>
                </template>

            </SelectBox>
        </div>
        <div class="ridestyler-showcase-vehicle-select-page" v-show="!isFirstLoad && currentStep === 'Paint'">
            <VehiclePaintCustomizer ref="paintCustomizer" v-if="currentStep === 'Paint' && vehicleConfiguration" v-bind:vehicleConfiguration="vehicleConfiguration" v-on:paint-color-selected="onPaintColorSelected" v-on:show-disclaimer="showModal" />
        </div>

    </div>
</template>

<script>
import TopBar from './TopBar';
import SelectBox from './SelectBox';
import LoadingIndicator from './LoadingIndicator';
import RideStylerAPIRequest from '../RideStylerAPIRequest';
import SvgIcon from './SvgIcon';
// import StepControl from './StepControl';
import VehiclePaintCustomizer from './VehiclePaintCustomizer';
import { formatTireOptionDetails } from '../Formatters';
import Settings from '../Settings';
import {mapState, mapMutations } from 'vuex';

const tireOptionKey = 'tire-option';

/**
 * @typedef VehicleSelectResponse
 * @prop {VehicleSelectMenu} Menu
 * @prop {VehicleSelectMenuOption} BestConfiguration
 * @prop {boolean} FinalTrims
 * @prop {VehicleSelectMenu} TrimOptions
 */

/**
 * @typedef VehicleSelectMenu
 * @prop {string} Title
 * @prop {string} Description
 * @prop {string} Key
 * @prop {string} Groups
 * @prop {VehicleSelectMenuOption[]} Options
 */

/**
 * @typedef VehicleSelectMenuOption
 * @prop {string} Label
 * @prop {string} Value
 * @prop {string} Group
 */

/**
 * @typedef Selection
 * @prop {string} key
 * @prop {string} label
 * @prop {string} value
 * @prop {string} menuTitle
 */

/** @typedef {'Vehicle'|'Paint'} SelectionStep */

export default {
    created() {
        /** @type {SelectionStep[]} */
        this.selectionSteps = [
            'Vehicle',
            'Paint'
        ];
        

    },
    mounted() {
        this.$el.querySelector("button").focus();
    },
    data () {
        return {
            isLoading: true,
            finalTrims: false,
            /** @type {string} */
            vehicleConfiguration: undefined,
            /** @type {VehicleSelectMenu} */
            currentMenu: undefined,
            /** @type {Selection[]} */
            selections: [],
            /** @type {SelectionStep} */
            currentStep: 'Vehicle',
            /** @type {string} */
            paintColor: undefined
        };
    },
    computed: {
        topBarTitle() {
            switch (this.currentStep) {
                case 'Vehicle': return this.translate("selectYourVehicle");
                case 'Paint': return this.translate("chooseYourPaintColor");
                default: return undefined;
            }
        },
        menuTitle() {
            if (!this.currentMenu) return undefined;

            return this.currentMenu.Description;
        },
        /** @returns {{label:string,value:string|number}[]} */
        menuOptions() {
            if (!this.currentMenu) return [];

            return this.currentMenu.Options.map(function (option) {
                return {
                    label: option.Label,
                    value: option.Value
                };
            });
        },
        canGoBack() {
            return this.hasSelections;
        },
        hasSelections() {
            return this.selections.length > 0;
        },
        lastSelection() {
            return this.hasSelections && this.selections[this.selections.length - 1];
        },
        isFirstLoad() {
            return this.isLoading && !this.hasSelections;
        },
        userHasSelectedTireOption() {
            return !!this.getSelection(tireOptionKey);
        },
        userHasFinishedSelection() {
            return this.finalTrims && this.userHasSelectedTireOption;
        },
        userFinished() {
            return this.userHasFinishedSelection && !!this.paintColor;
        },
        
        ...mapState({
            modalVisible: state => state.visualize.disclaimerModalVisible || state.visualize.filterModalVisible || state.visualize.paintModalVisible ,
            page: state => state.page
        })
    },
    watch: {
        selections: {
            handler() {
                this.loadNextStep();
            },
            immediate: true
        },
        userHasFinishedSelection(isFinished) {
            if (isFinished) this.currentStep = "Paint";
        },

        /**
         * @param {SelectionStep} newStep
         */
        currentStep(newStep) {
            if (newStep === 'Paint') {
                this.$nextTick(() => {
                    const productGallery = this.$refs.paintCustomizer.$el.querySelector('.ridestyler-showcase-product-gallery');

                    if (productGallery) setTimeout(() => productGallery.focus(), 0);
                });
            }
        }

        
    },
    methods: {
        goBack() {
            const selectionIndex = this.selections.indexOf(this.selections.splice(-1, 1));

            this.finalTrims = false;

            if (selectionIndex < 0) return;

            this.selections.splice(selectionIndex);
        },
        getSelection(key) {
            return this.selections.find(selection => selection.key === key);
        },
        loadNextStep() {
            this.isLoading = true;
            this.currentMenu = undefined;

            let promise = Promise.resolve();

            if (this.finalTrims) {
                // If we've found our vehicle then we need to let the user choose which tire option
                promise = RideStylerAPIRequest('vehicle/gettireoptiondetails', {
                    VehicleConfigurations: [
                        this.vehicleConfiguration
                    ]
                }, 'Details')
                    .then(
                        /** @param {ridestyler.Descriptions.VehicleTireOptionDescriptionModel[]} tireOptions */
                        tireOptions => {
                            const selectATireOption = 'Select a Tire Option';

                            this.currentMenu = {
                                Title: selectATireOption,
                                Description: selectATireOption,
                                Key: tireOptionKey,
                                Options: tireOptions.map(tireOption => {
                                    return {
                                        Label: formatTireOptionDetails(tireOption),
                                        Value: tireOption.TireOptionID
                                    };
                                })
                            };

                            this.loadedTireOptions = tireOptions;
                        }
                    );
            } else {
                const vehicleSelectRequest = {
                    Selection: this.selections.map(selection => selection.key + ':' + selection.value)
                };

                if (Settings.disableAngledVehicles) {
                    if (!vehicleSelectRequest.Filters) vehicleSelectRequest.Filters = {};

                    vehicleSelectRequest.Filters.HasSideImage = true;
                }

                // Load the next vehicle select step
                promise = RideStylerAPIRequest('vehicle/select', vehicleSelectRequest)
                    .then(
                        /** @param {VehicleSelectResponse} response */
                        response => {
                            this.finalTrims = response.FinalTrims;
                            this.currentMenu = response.Menu;
                            this.vehicleConfiguration = response.BestConfiguration ? response.BestConfiguration.Value : undefined;

                            if (this.finalTrims) return this.loadNextStep();

                            if (this.currentMenu.Options.length === 1) {
                                const option = this.currentMenu.Options[0];

                                this.selections.push({
                                    key: this.currentMenu.Key,
                                    menuTitle: this.currentMenu.Description,
                                    label: option.Label,
                                    value: option.Value
                                });
                                
                                return;
                            }
                        }
                    );
            }

            return promise.finally(() => {
                const menu = this.$el.querySelector('.ridestyler-showcase-vehicle-selection-menu');

                if (menu) {
                    setTimeout(() => menu.focus(), 0);
                }

                this.isLoading = false;
            });
        },
        onMenuSelection(values) {
            let value;

            if (Array.isArray(values)) {
                if (values.length === 0) value = undefined;
                else value = values[0];
            } else {
                value = values;
            }

            if (!value) return;

            const menuOption = this.menuOptions.find(option => option.value == value);

            if (!menuOption) return;

            this.selections.push({
                key: this.currentMenu.Key,
                menuTitle: this.currentMenu.Description,
                label: menuOption.label,
                value: menuOption.value
            });
            
            document.dispatchEvent(new CustomEvent("rs-vehicle-option-selection", { 
                detail: { step: this.currentMenu.Key, value:menuOption.label, rsID:menuOption.value  }} ));
        },
        onStepClicked(step) {
            this.currentStep = step;

            const paintStepIndex = this.selectionSteps.indexOf('Paint');
            const stepIndex = this.selectionSteps.indexOf(step);

            if (stepIndex < paintStepIndex) this.paintColor = undefined;
        },

        /**
         * @param {Selection} selection
         */
        onSelectionClicked(selection) {
            const selectionIndex = this.selections.indexOf(selection);

            this.finalTrims = false;

            if (selectionIndex < 0) return;

            this.selections.splice(selectionIndex);
        },

        onPaintColorSelected(color) {
            this.paintColor = color;
        },

        showModal(){
            this.onConfirm();
            this.$emit('disclaimer', {
              getSelection: this.getSelection,
              vehicleConfiguration: this.vehicleConfiguration,
              paintColor: this.paintColor,
              loadedTireOptions: this.loadedTireOptions,
              tireOptionKey});

             document.dispatchEvent(new CustomEvent("rs-vehicle-selected", { detail: {   
                vehicleConfiguration: this.vehicleConfiguration,
                paintColor: this.paintColor,
                tireOption: this.getSelection(tireOptionKey).value
                
            }}));  
        },

        ...mapMutations({
            onConfirm: 'visualize/showDisclaimerModal'
        })
    },
    components: {
        TopBar,
        SelectBox,
        LoadingIndicator,
        SvgIcon,
        // StepControl,
        VehiclePaintCustomizer,
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    &.ridestyler-showcase-page-select {
        .ridestyler-showcase-top-bar-middle {
            text-align: left;
        }
        .ridestyler-showcase-top-bar h1 {
            font-size: 1em;
            padding-left: 0.7em;
        }
        .ridestyler-showcase-renderer {
            width: 95%;
            margin: 0 auto;
        }

        .ridestyler-showcase-background {
            bottom: -$showcase-background-height;
        }

        .ridestyler-showcase-vehicle-selection-menu, .ridestyler-showcase-vehicle-selections {
            position: absolute;
            width: 40%;

            transition: left 250ms ease-in-out;
        }

        .ridestyler-showcase-vehicle-selection-menu {
            font-size: 1.2em;
            left: 30%; bottom: 10%; top: 2em;

            &:focus {
                background: #f7f7f7;
            }

            .ridestyler-showcase-select-box-title {
                text-align: center;
            }
        }

        .ridestyler-showcase-vehicle-selection-back {
            stroke-width: 4px;
            stroke: white;

            position: absolute;
            left: 50%;
            top: 3.2em;
            cursor: pointer;

            @include focusable;

            border: none;
            background: none;

            z-index: 3;

            .ridestyler-showcase-icon {
                width: 1em;
                height: 1em;
            }
        }

        .ridestyler-showcase-vehicle-selections {
            font-size: 1.2em;
            top: 2em; left: 10%;
            box-sizing: border-box;
            padding-right: 10px;
            text-align: center;

            h2 {
                font-size: 1.5em;
                padding: 0;
                margin: 0;
            }

            a {
                font-size: 1.2em;
                padding: 9px;
                @include var(color, primary-color);
                @include focusable;
                display: block;
                text-decoration: none;
            }
        }

        .ridestyler-showcase-vehicle-selections-wrapper {
            border-radius: 1em;
            padding: 0.5em;
            word-wrap: break-word;
        }

        .ridestyler-showcase-vehicle-selections:only-child {
            left: 30%;
            margin-left: 10px;
        }

        .ridestyler-showcase-vehicle-selections ~ .ridestyler-showcase-vehicle-selection-menu {
            left: 50%;
        }

        .ridestyler-showcase-vehicle-select-steps {
            text-align: center;
            margin: 1.75em 0;
        }

        .ridestyler-showcase-vehicle-select-page {
            position: relative;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: auto;
            height: 93.6%;

            .ridestyler-showcase-product-gallery {
                color: #666;
            }

            .ridestyler-showcase-product-gallery-next, .ridestyler-showcase-product-gallery-prev {
                top: 35%;
            }
        }

        .ridestyler-showcase-icon {
            fill: inherit;
        }

        &.ridestyler-showcase-breakpoint-portrait {
            &.ridestyler-showcase-breakpoint-xs {
                .ridestyler-showcase-paint-swatch {
                    width: 4em;
                    height: 4em;
                }
                .ridestyler-showcase-product-gallery-product {
                    width: 20%;
                }
            }
            &.ridestyler-showcase-breakpoint-sm {
                .ridestyler-showcase-paint-swatch {
                    width: 5em;
                    height: 5em;
                }
            }
            &.ridestyler-showcase-breakpoint-md {
                .ridestyler-showcase-paint-swatch {
                    width: 8em;
                    height: 8em;
                }
            }
            .ridestyler-showcase-vehicle-paint-customizer {
                text-align: center;
                justify-content: center;
                .ridestyler-showcase-viewport {
                    left: 0;
                    max-height: none;
                    margin: 0;
                    width: 100%; height: auto;
                }
                .ridestyler-showcase-vehicle-select-confirm-wrapper {
                    margin: 3em 0;
                }
                .ridestyler-showcase-product-gallery {
                    height: 20%;
                    margin: 0;
                }
                .ridestyler-showcase-product-gallery-product {
                    height: auto;
                    .ridestyler-showcase-product-gallery-product-label {
                        width: 100%;
                        margin: 0 auto 0.6em;
                    }
                    .ridestyler-showcase-product-gallery-product-select {
                        width: 100%;
                    }
                }
            }
            .ridestyler-showcase-vehicle-selections {
                text-align: center;
                padding-right: 0;
                position: static;
                margin: 2em auto 0;
                &:only-child {
                    margin-left: auto;
                }
            }
            .ridestyler-showcase-vehicle-selection-menu {
                position: relative;
                width: auto;
                margin: 0 20px;
                left: 0;
            }

            .ridestyler-showcase-select-box-options {
                top: 0.5em;
                position: relative;
                width: 100%;
            }
            .ridestyler-showcase-vehicle-selection-back {
                left: 10px;
                top: 3em;

                .ridestyler-showcase-icon {
                    width: 1.5em;
                    height: 1.5em;
                }
            }
        }
    }
}
</style>
