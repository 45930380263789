import store from "./store/index";

/** 
 * @typedef FilterType
 * @prop {string} countAction
 * @prop {Filter[]} filters
 */

/**
 * @typedef {((o:object)=>string)|string} FilterMapping
 */

/**
 * @typedef Filter
 * @prop {string} title
 * @prop {string} action
 * @prop {string} responseKey
 * @prop {FilterMapping} mapLabel
 * @prop {FilterMapping} mapValue
 * @prop {string} clearOption
 * @prop {(filters)=>any} get
 * @prop {(filters, value)=>void} set
 */

/**
 * @typedef {import("./store/state").ProductType} ProductType
 */

 

const fitmentFilterGroupKey = "ridestyler-showcase-filters";
/** @param {ridestyler.Requests.FitmentFilterModel} fitmentFilter */
function applyFitmentFilter(filters, fitmentFilter) {
    const fitmentFilters = filters.FitmentFilters || [];
    let existingFitmentFilterGroup;

    for (const appliedFitmentFilter of fitmentFilters) {
        if (appliedFitmentFilter.GroupKey === fitmentFilterGroupKey) {
            existingFitmentFilterGroup = appliedFitmentFilter;
            break;
        }
    }

    if (!existingFitmentFilterGroup) {
        fitmentFilter.GroupKey = fitmentFilterGroupKey;
        fitmentFilters.push(fitmentFilter);
        
        // We've modified the array so update the array in the filters
        filters.FitmentFilters = fitmentFilters;
    } else {
        Object.assign(existingFitmentFilterGroup, fitmentFilter);
        existingFitmentFilterGroup.GroupKey = fitmentFilterGroupKey;
    }
}
function retrieveFitmentFilter(filters, key) {
    const fitmentFilters = filters.FitmentFilters || [];

    for (const appliedFitmentFilter of fitmentFilters) {
        if (appliedFitmentFilter.GroupKey !== fitmentFilterGroupKey) continue;

        return appliedFitmentFilter[key];
    }
}


/** @type { {[key:string]: FilterType }} */
const filters = {
    wheel: {
        countAction: 'Wheel/CountModels',
        filters: [
            {
                title: 'Brands',
                action: 'Wheel/GetBrands',
                responseKey: 'Brands',
                mapLabel: 'WheelBrandName',
                mapValue: 'WheelBrandID',
                clearOption: 'All Brands',

                /** @param {ridestyler.Requests.WheelFilterModel} filters */
                get(filters) {
                    return filters.WheelBrand;
                },
                /** @param {ridestyler.Requests.WheelFilterModel} filters */
                set(filters, value) {
                    filters.WheelBrand = value;
                }
            },
            {
                title: 'Finishes',
                action: 'Wheel/GetFinishes',
                responseKey: 'Finishes',
                mapLabel: 'WheelFinishCategoryName',
                mapValue: 'WheelFinishCategoryID',
                clearOption: 'All Finishes',

                /** @param {ridestyler.Requests.WheelFilterModel} filters */
                get(filters) {
                    return filters.WheelFinishCategory;
                },
                /** @param {ridestyler.Requests.WheelFilterModel} filters */
                set(filters, value) {
                    filters.WheelFinishCategory = value;
                }
            },
            {
                title: 'Sizes',
                action: 'Wheel/GetDiameters',
                responseKey: 'Diameters',
                mapLabel: diameter => diameter + '\u2033',
                mapValue: diameter => diameter,
                clearOption: 'All Sizes',

                /** @param {ridestyler.Requests.WheelFilterModel} filters */
                get(filters) {
                    return retrieveFitmentFilter(filters, 'Diameter');
                },
                /** @param {ridestyler.Requests.WheelFilterModel} filters */
                set(filters, value) {
                    if (!filters.FitmentFilters) filters.FitmentFilters = [];

                    applyFitmentFilter(filters, {
                        Diameter: value
                    });
                }
            }
        ]
    },
    tire: {
        countAction: 'Tire/CountModels',
        filters: [
            {
                title: 'Brands',
                action: 'Tire/GetBrands',
                responseKey: 'Brands',
                mapLabel: 'TireBrandName',
                mapValue: 'TireBrandID',
                clearOption: 'All Brands',

                /** @param {ridestyler.Requests.TireFilterModel} filters */
                get(filters) {
                    return filters.TireBrand;
                },
                /** @param {ridestyler.Requests.TireFilterModel} filters */
                set(filters, value) {
                    filters.TireBrand = value;
                }
            },
            {
                title: 'Features',
                action: 'Tire/GetModelAttributes',
                responseKey: 'Attributes',
                mapLabel: 'TireModelAttributeName',
                mapValue: 'TireModelAttributeID',
                clearOption: 'Any Feature',

                /** @param {ridestyler.Requests.TireFilterModel} filters */
                get(filters) {
                    return filters.RequiredTireModelAttribute;
                },
                /** @param {ridestyler.Requests.TireFilterModel} filters */
                set(filters, value) {
                    filters.RequiredTireModelAttribute = value;
                }
            },
            {
                title: 'Speed Ratings',
                action: 'Tire/GetSpeedRatings',
                responseKey: 'SpeedRatings',
                mapLabel: 'TireSpeedRatingCode',
                mapValue: 'TireSpeedRatingID',
                clearOption: 'Any Speed Rating',

                /** @param {ridestyler.Requests.TireFilterModel} filters */
                get(filters) {
                    return filters.SpeedRating;
                },
                /** @param {ridestyler.Requests.TireFilterModel} filters */
                set(filters, value) {
                    filters.SpeedRating = value;
                }
            }
        ]
    }
};

/**
 * @param {ProductType} type 
 * @returns {FilterType}
 */
export function getFilters(type) {
    if (type in filters === false) return undefined;

    let filter = filters[type];
    
    if (type === 'tire') {
        const vehicleStyleType = store.state.vehicleDescription.StyleType;

        if (['Truck', 'SUV'].includes(vehicleStyleType)) {
            filter = Object.assign({}, filter);
            
            filter.filters = filter.filters.filter(f => f.title !== 'Speed Ratings').concat({
                title: 'Outside Diameter',
                action: 'Tire/GetOutsideDiameters',
                responseKey: 'Diameters',
                mapLabel: diameter => diameter + '\u2033',
                mapValue: diameter => diameter,
                clearOption: 'OE',

                /** @param {ridestyler.Requests.TireFilterModel} filters */
                get(filters) {
                    if (!filters.Recommend) return undefined;

                    return filters.Recommend.OutsideDiameterTargetWhole;
                },
                /** @param {ridestyler.Requests.TireFilterModel} filters */
                set(filters, value) {
                    if (!filters.Recommend) filters.Recommend = {};

                    filters.Recommend.OutsideDiameterTargetWhole = value;
                }
            });
        }
    }

    return filter;
}

/**
 * 
 * @param {ProductType} type 
 */
export function getActiveFilterCount(type) {
    let count = 0;

    const filters = store.getters[type + 'Filters'];
    const filterType = getFilters(type);

    if (!filterType) return count;

    const filterDefinitions = filterType.filters;

    if (!filterDefinitions || filterDefinitions.length === 0) return 0;

    for (let i=0; i< filterDefinitions.length; i++) {
        const filterDefinition = filterDefinitions[i];
        const filterValue = filterDefinition.get(filters);

        if (filterValue) count += 1;
    }

    if (type === 'tire' && store.state.selectedWheelModel) count += 1;

    return count;
}