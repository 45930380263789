<template>
    <div class="ridestyler-showcase-product-details">
        <div class="ridestyler-showcase-product-details-header">
            <h1 id="ridestyler-showcase-product-details-title">
                <div class="secondary-title">{{wheelDetails.WheelBrandName}}</div>
                {{wheelDetails.WheelModelName}}
                <div class="third-title">{{wheelDetails.WheelModelFinishDescription}}</div>
                
            </h1>

            <ToggleButtonList v-bind:selectedIndex.sync="selectedTab" class="ridestyler-showcase-product-details-toggle-buttons" :options="[ translate('featuresAndSpecsSummary'), translate('featuresAndSpecsSpecifications') ]" />
        </div>
        <div class="ridestyler-showcase-product-details-body">
            <div class="ridestyler-showcase-product-details-tab" v-if="selectedTab === 0">
                <ProductImage :product="wheelDetails"  />
                <div class="ridestyler-showcase-product-details-summary-table-container ridestyler-showcase-scrollable-y">
                    <TableView :rows="summaryRows"></TableView>
                </div>
            </div>
            <div class="ridestyler-showcase-product-details-tab" v-if="selectedTab === 1">
                <div class="ridestyler-showcase-product-details-full-table-container ridestyler-showcase-scrollable-y">
                    <TableView :rows="specificationRows"></TableView>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatWheelSize, formatOffset, formatPrice } from '../Formatters';

import ToggleButtonList from './ToggleButtonList';
import TableView from './TableView';
import ProductImage from './ProductImage';
import settings from '../Settings';

export default {
    props: [ "wheelDetails" ],
    beforeMount: function() {
        var self = this;

        var modelFilters = {
            WheelModel: this.wheelDetails.WheelModelID,
            IncludePricing: settings.showPricing
        };

        var combinedFilters =
            Object.assign({},
                this.wheelFilters,
                modelFilters);

        var b = ridestyler.ajax.batch();
        b.done(function(r) {
            var lookup = {};
            var partialFitmentList = r['fitments-partial'].Fitments;
            var fullFitmentList = r['fitments-full'].Fitments;

            partialFitmentList.map(function(f) { lookup[f.WheelFitmentID] = true; });

            fullFitmentList.map(function(f) {
                var isFitmentAvailable = (lookup[f.WheelFitmentID] === true);

                f._rowSettings = {
                    disabled: !isFitmentAvailable
                };
            });

            fullFitmentList.sort(function(x, y) {
                if (!x._rowSettings.disabled && y._rowSettings.disabled) return -1;
                else if (x._rowSettings.disabled && !y._rowSettings.disabled) return 1;

                if (x.DiameterMin < y.DiameterMin) return -1;
                else if (x.DiameterMin > y.DiameterMin) return 1;

                if (x.WidthMin < y.WidthMin) return -1;
                else if (x.WidthMin > y.WidthMin) return 1;

                if (x.OffsetMin < y.OffsetMin) return -1;
                else if (x.OffsetMin > y.OffsetMin) return 1;

                if (x.BoltPatternDescription < y.BoltPatternDescription) return -1;
                else if (x.BoltPatternDescription > y.BoltPatternDescription) return 1;

                return 0;
            });

            self.wheelFitmentDetails = fullFitmentList;
        });

        b.send('fitments-full', {
            action: 'Wheel/GetFitmentDescriptions',
            data: modelFilters
        });

        b.send('fitments-partial', {
            action: 'Wheel/GetFitments',
            data: combinedFilters
        });

        b.execute();
    },
    data: function () {
        return {
            selectedTab: 0,
            wheelFitmentDetails: []

        };
    },
    components: {
        ToggleButtonList,
        TableView,
        ProductImage
    },
    computed: {
        specificationRows() {
            let _this = this;
            return this.wheelFitmentDetails.map(function (f) {
                let rtn = {
                    '_rowSettings': f._rowSettings,
                    'Size': formatWheelSize([f.DiameterMin, f.DiameterMax], [f.WidthMin, f.WidthMax]),
                    'Offset': formatOffset(f.OffsetMin, f.OffsetMax),
                    'Bolt Pattern': f.BoltPatternDescription,
                    'Centerbore': f.CenterboreMM,
                    'Construction': '',
                    'Part Number': f.PartNumber,
                                
                };

                if( settings.showPricing ) {
                    rtn[_this.translate('priceEach')] =  f.Price != null && settings.showPricing ? formatPrice(f.Price) : _this.translate("wheelPriceNull");
                }

                if( settings.showInventory) {
                    if( !f.QuantityAvailable && f.QuantityAvailable != 0)
                        rtn["Stock"] = _this.translate("wheelStockNullShort");
                    else if( f.QuantityAvailable < settings.noWheelStockWarning)
                        rtn["Stock"] = _this.translate("wheelStockOut");
                    else if( f.QuantityAvailable < settings.lowWheelStockWarning)
                        rtn["Stock"] = _this.translate("wheelStockLow");
                    else
                        rtn["Stock"] = _this.translate("wheelStockIn");
                }
                if( settings.showWheelInventoryValues && settings.showInventory)
                {
                    if( f.QuantityAvailable || f.QuantityAvailable == 0)
                        rtn["Stock"] = f.QuantityAvailable;
                    else
                         rtn["Stock"] = _this.translate("wheelStockNullShort");
                }
                    

                return rtn;
            });
        },
        summaryRows() {
            var fitmentLookup = {};
            this.wheelFitmentDetails.forEach(function (f) {
                // Skip rows that are disabled
                if (f._rowSettings.disabled) return;

                const key = formatWheelSize([f.DiameterMin, f.DiameterMax], [f.WidthMin, f.WidthMax]);
                let qty = -Infinity;
                if( f.QuantityAvailable || f.QuantityAvailable == 0 )
                    qty = f.QuantityAvailable;
                
                if (key in fitmentLookup === false){
                    if( f.Price != null )
                        fitmentLookup[key] = { min: f.Price, max: f.Price, qty: qty};
                    else 
                        //fitmentLookup[key] = { min: Infinity, max: -Infinity };
                        fitmentLookup[key] = { min: -Infinity, max: Infinity, qty: qty };
                } else {
                    let min = fitmentLookup[key].min;
                    let max = fitmentLookup[key].max;
                    if( f.Price != null) {
                        if( f.Price > max )
                            max = f.Price;
                        if( f.Price < min )
                            min = f.Price;
                    }

                    fitmentLookup[key] = { min: min, max: max, qty:qty };
                }
                   
            });

            var rows = [];
            for(var s in fitmentLookup) {
               
                let price = this.translate("wheelPriceNull");
                let qty = undefined;


                if( fitmentLookup[s].min != -Infinity && fitmentLookup[s].min != Infinity) {
                    
                    if( fitmentLookup[s].min == fitmentLookup[s].max )
                        price = formatPrice( fitmentLookup[s].min );
                    else
                        price = formatPrice( fitmentLookup[s].min) +' to '+ formatPrice( fitmentLookup[s].max) ;
                }
                
                if( settings.showWheelInventoryValues && settings.showInventory ) {
                    
                    qty = fitmentLookup[s].qty;
                    if( qty == -Infinity)
                        qty = this.translate("wheelStockNullShort");

                    
                }
                else if( settings.showInventory) {
                    
                    if( fitmentLookup[s].qty == -Infinity)
                        qty = this.translate("wheelStockNullShort");
                    else if( fitmentLookup[s].qty < settings.noWheelStockWarning)
                        qty = this.translate("wheelStockOut");
                    else if( fitmentLookup[s].qty < settings.lowWheelStockWarning)
                        qty = this.translate("wheelStockLow");
                    else
                        qty = this.translate("wheelStockIn");

                   
                } 

                let v = {Size: s};

                if(settings.showPricing) {
                    v[this.translate('priceEach')] = price;
                }
                if( qty != undefined ) {
                    v["Stock"] = qty;
                }

                rows.push(v);
            }

            return rows;


        },

        ...mapGetters([
            'wheelFilters'
        ])
    }
}
</script>

<style lang="scss">

</style>
