<template>
    <div class="ridestyler-showcase-action-container" v-if="hasActions" :class="actionClasses">
        <ActionComponent v-for="action in filteredActions" v-bind:action="action" v-bind:key="action.label" :buttonStyle="styleType" />
    </div>
</template>

<script>
import ActionComponent from './ActionComponent';

export default {
    components: {
        ActionComponent,
    },
    computed: {
        hasActions(){
            
            return this.filteredActions.length > 0;
        },
        filteredActions() {
            let actions = this.$showcasePlugins.actions,
                filteredActions = [];

            for(let action in actions){
                if(actions[action].type === this.actionType && actions[action].isVisible){
                    filteredActions.push(actions[action]);
                }
            }
            
            return filteredActions;
        },
        actionClasses() {
            let classes = [];

            if(this.actionType == 'wheel') classes.push('ridestyler-showcase-wheel-actions');
            if(this.actionType == 'tire') classes.push('ridestyler-showcase-tire-actions');

            return classes;
        }
    },
    props: {
        actionType: String,
        styleType: String,
    }
}
</script>

<style lang="scss">
.ridestyler-showcase {
    &.ridestyler-showcase-page-select {
        .ridestyler-showcase-action-container {
            margin-left: 15px !important;
        }
    }
}
</style>
